import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import QuoteCard from 'components/QuoteCard'

const PayhawkQuoteCard = () => {
    return (
        <QuoteCard
            text={
                'Through speaking with our customers we learned that many wanted help understanding the CO2 emissions related to their company spend. Working with Lune, we’ve brought that to life quickly and easily.'
            }
            profileName={'Hristo Borisov'}
            profileTitle={'Co-Founder & CEO at Payhawk'}
            profileAvatar={
                <StaticImage
                    src="../../../../images/avatars/hristo-birosov.png"
                    alt={`Hristo Borisov`}
                />
            }
            backgroundImageStaticQuery={useStaticQuery(graphql`
                query {
                    desktop: file(relativePath: { eq: "customerQuoteCards/payhawk.png" }) {
                        childImageSharp {
                            fluid(quality: 90, maxWidth: 500) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            `)}
        />
    )
}

export default PayhawkQuoteCard
