import TwoColumnLayout, { ColumnWrapOrder } from '../components/TwoColumnLayout'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Line from '../components/Line'
import CTAButton from '../components/CTAButton'
import { navigate } from 'gatsby'
import { LINKS } from '../constants'
import * as React from 'react'
import StaticImageWrapper from '../components/StaticImageWrapper'
import { StaticImage } from 'gatsby-plugin-image'

const emptyLine = (
    <>
        <br />
        <br />
    </>
)

const HeroHeadingAbout = () => (
    <TwoColumnLayout columnWrapOrderOnMobile={ColumnWrapOrder.REVERSE}>
        <div>
            <Box
                sx={{
                    mb: {
                        xs: 3,
                        sm: 4,
                        md: 4,
                        lg: 4,
                        xl: 4,
                    },
                }}
            >
                <Typography variant={`h3`}>Why? </Typography>
            </Box>
            <Typography variant={`body1`}>
                Yes, we are in a climate emergency, but it’s not all bad. {emptyLine}
                Consumers demand climate-friendly products and businesses are waking up to this.
                {emptyLine}
                Going forward, the companies that make real climate impact a part of their customer
                experience will stand out, grow profitably and beat their competition. {emptyLine}
                Over the last decade, every company became a digital company; similarly, over the
                next decade, every company will become a climate company. {emptyLine}
                Powered by Lune.
            </Typography>
            <Line />
            <CTAButton
                text={`See Open Roles`}
                onClick={() => navigate(LINKS.jobs, { replace: false })}
            />
        </div>
        <StaticImageWrapper
            placeholder={'https://assets.lune.co/lune-co/placeholders/about-why.svg'}
        >
            <StaticImage
                placeholder={'dominantColor'}
                src={`../images/About.png`}
                alt={`About`}
                layout="fullWidth"
            />
        </StaticImageWrapper>
    </TwoColumnLayout>
)

export default HeroHeadingAbout
