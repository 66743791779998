import React from 'react'
import Typography from '@mui/material/Typography'
import useIsMobile from '../hooks/useIsMobile'

const H3 = (props: { children: React.ReactNode }) => {
    const isMobile = useIsMobile()

    const { children } = props

    return (
        <Typography color={'Grey900'} variant={isMobile ? 'h5' : 'h4'} component="div" {...props}>
            {children}
        </Typography>
    )
}

export default H3
