import { Box } from '@mui/material'
import StandardLayout from 'components/StandardLayout'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { Text } from 'lune-ui-lib'
import { useTheme } from '@mui/material/styles'

export const BlogImage = ({
    slice,
}: {
    slice: {
        primary: {
            size: string
            attribution_caption: string
            attribution_url: string
            image: {
                alt: string
                gatsbyImageData: any
            }
        }
    }
}) => {
    const theme = useTheme()

    const { image, size, attribution_caption, attribution_url } = slice.primary
    const my = {
        xs: 8,
        sm: 15,
        md: 15,
        lg: 15,
        xl: 15,
    }

    const sx = {
        mb: my,
        mt: size === 'title' ? 0 : my,
        ...(size === 'column' && { maxWidth: '720px' }),
    }

    const StyleWrapper = ({
        respectPageMargins,
        children,
    }: {
        respectPageMargins: boolean
        children: React.ReactChild
    }) => {
        if (respectPageMargins) {
            return (
                <StandardLayout
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {children}
                </StandardLayout>
            )
        }
        return <>{children}</>
    }

    return (
        <StyleWrapper respectPageMargins={size !== 'full-bleed'}>
            <Box sx={sx}>
                <GatsbyImage
                    image={image.gatsbyImageData}
                    alt={image.alt || 'image'}
                    style={{
                        width: '100%',
                        objectFit: 'none',
                    }}
                />
                {attribution_caption && (
                    <Text
                        sx={{
                            position: `relative`,
                            top: `${theme.spacing(2)}`,
                            color: `${(theme.palette as any).Grey600}`,
                            left: `${size === 'full-bleed' ? theme.spacing(3) : 0}`,
                        }}
                        variant={`caption`}
                    >
                        {attribution_url ? (
                            <a
                                style={{
                                    color: `${(theme.palette as any).Grey600}`,
                                    textDecoration: `none`,
                                }}
                                href={attribution_url}
                            >
                                {attribution_caption}
                            </a>
                        ) : (
                            attribution_caption
                        )}
                    </Text>
                )}
            </Box>
        </StyleWrapper>
    )
}
