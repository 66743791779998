import React from 'react'
import TwoColumnLayout, { ColumnWrapOrder } from 'components/TwoColumnLayout'
import Line from 'components/Line'
import SectionBody1 from 'components/SectionBody1'
import Shadow from 'components/Shadow'
import H3 from 'components/H3'
import SectionLink from 'components/SectionLink'
import ForSustainabilityPages from '../components/ForSustainabilityPages'
import StaticImageWrapper from '../components/StaticImageWrapper'
import { StaticImage } from 'gatsby-plugin-image'

const ShareImpactSection = () => {
    return (
        <TwoColumnLayout leftCol={5} rightCol={6} columnWrapOrderOnMobile={ColumnWrapOrder.NORMAL}>
            <Shadow>
                <StaticImageWrapper
                    placeholder={
                        'https://assets.lune.co/lune-co/placeholders/product-sustainabilitypages-page.svg'
                    }
                >
                    <StaticImage
                        placeholder={'dominantColor'}
                        quality={100}
                        src={'../images/Certificate.png'}
                        alt={'ShareImpact'}
                        layout="fullWidth"
                    />
                </StaticImageWrapper>
            </Shadow>
            <div>
                <ForSustainabilityPages
                    sx={{
                        mb: {
                            xs: 4,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                    }}
                />
                <H3>Easily share your climate impact with customers</H3>
                <SectionBody1>
                    Engage customers in meaningful ways by using a sustainability page to share your
                    impact summary and carbon certificates supported by your chosen projects.
                </SectionBody1>

                <Line />

                <SectionLink
                    text={'See example Sustainability page'}
                    to={'https://sustainability.lune.co/lune-example-impact'}
                    newPage
                />
            </div>
        </TwoColumnLayout>
    )
}

export default ShareImpactSection
