import { styled } from '@mui/material/styles'
import React from 'react'
import MobileNav from './MobileNav'
import DesktopNav from './DesktopNav'
import { RenderOnDesktop, RenderOnSmallScreen } from 'components/DeviceUtils'
import { useTheme } from '@mui/material/styles'

const NavBarContainer = styled('div')({
    height: '72px',
})

export const desktopNavItemsMxSpacing = {
    xs: 0,
    sm: 0,
    md: 0,
    lg: 0,
    xl: 0,
}

const NavBar = ({
    backgroundColor,
    onBackgroundChange,
}: {
    backgroundColor?: string
    onBackgroundChange?: (color: string) => void
}) => {
    const { palette } = useTheme()

    return (
        <NavBarContainer>
            <RenderOnSmallScreen>
                <MobileNav />
            </RenderOnSmallScreen>
            <RenderOnDesktop>
                <DesktopNav
                    backgroundColor={backgroundColor ?? palette.White}
                    onBackgroundChange={onBackgroundChange}
                />
            </RenderOnDesktop>
        </NavBarContainer>
    )
}

export default NavBar
