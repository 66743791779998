import React, { memo } from 'react'
import Box from '@mui/material/Box'
import { styled, useTheme } from '@mui/material/styles'

const GreyGradient = (props: {
    hide?: boolean
    radial?: boolean
    children?: React.ReactNode
    sx?: any
}) => {
    const { hide, children } = props
    if (hide ?? false) {
        return <>{children}</>
    }

    const theme = useTheme()

    const styles = {
        background: props.radial
            ? `radial-gradient(ellipse 400px 40px at center bottom, ${theme.palette.Grey200} 0, ${theme.palette.White} 100%)`
            : `linear-gradient(180deg, ${theme.palette.White} 0%, ${theme.palette.Grey100} 100%)`,
    }
    const StyledBox = styled(Box)(styles)

    return <StyledBox {...props}>{children}</StyledBox>
}

export default memo(GreyGradient, (prev, next) => {
    return prev.hide === next.hide
})
