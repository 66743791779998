import React, { FC, ReactNode } from 'react'
import { Box } from '@mui/system'

const VerticalCarouselMobile: FC<{ cards: ReactNode[] }> = ({ cards }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 3,
            }}
        >
            {cards.map((card, index) => {
                return (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {card}
                    </Box>
                )
            })}
        </Box>
    )
}

export default VerticalCarouselMobile
