import * as React from 'react'
import Divider from '@mui/material/Divider'

const Line = () => {
    return (
        <Divider
            sx={{
                width: `80px`,
                backgroundColor: 'Grey900',
                my: {
                    xs: 3,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    xl: 4,
                },
            }}
        />
    )
}

export default Line
