import * as React from 'react'
import useIsMobile from 'hooks/useIsMobile'
import Box from '@mui/material/Box'
import TwoColumnLayout, { ColumnWrapOrder } from 'components/TwoColumnLayout'
import GreyGradient from 'components/GreyGradient'
import H3 from 'components/H3'
import Typography from '@mui/material/Typography'
import Profile from '../components/Profile'
import Stack from '@mui/material/Stack'
import { formatDate, truncateStringWithoutCutWords } from '../utils'
import { GatsbyImage } from 'gatsby-plugin-image'
import StandardLayout from '../components/StandardLayout'
import { navigate } from 'gatsby'
import TagStrip from 'components/TagStrip'
import { LuneTheme } from 'lune-ui-lib'

interface BlogSummaryProps {
    mainImage: any
    date: string | Date
    title: string
    content?: string
    brief?: string
    selectedSlug?: string
    author: {
        author_full_name: string
        author_title: string
        author_avatar: { gatsbyImageData: any }
    }
    slug: string
    tags: {
        name: string
        color: string
        slug: string
        index: number
    }[]
}

const BlogSummary = ({
    mainImage,
    date,
    title,
    content,
    brief,
    author,
    slug,
    tags,
    selectedSlug,
}: BlogSummaryProps) => {
    const isMobile = useIsMobile()
    const { palette } = LuneTheme

    return (
        <GreyGradient hide={!isMobile}>
            <StandardLayout>
                <TwoColumnLayout
                    columnWrapOrderOnMobile={ColumnWrapOrder.NORMAL}
                    leftCol={5}
                    rightCol={6}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            height: {
                                xs: '480px',
                                sm: '556px',
                                md: '556px',
                                lg: '556px',
                                xl: '556px',
                            },
                            cursor: 'pointer',
                        }}
                        onClick={() => navigate(`/blog/${slug}/`, { replace: false })}
                    >
                        <GatsbyImage
                            image={mainImage.gatsbyImageData}
                            alt={title ?? ''}
                            style={{
                                width: '100%',
                                objectFit: 'none',
                                borderRadius: '48px',
                            }}
                            backgroundColor={`${palette.Grey100}`}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: {
                                xs: '480px',
                                sm: '556px',
                                md: '556px',
                                lg: '556px',
                                xl: '556px',
                            },
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            navigate(`/blog/${slug}/`)
                        }}
                    >
                        <Stack
                            spacing={{
                                xs: 3,
                                sm: 4,
                                md: 4,
                                lg: 4,
                                xl: 4,
                            }}
                        >
                            <Profile
                                name={author.author_full_name}
                                title={author.author_title}
                                avatar={
                                    <GatsbyImage
                                        image={author.author_avatar.gatsbyImageData}
                                        alt={author.author_full_name}
                                        backgroundColor={`${palette.Grey100}`}
                                    />
                                }
                            />

                            <H3 component="span">{title}</H3>
                            <Typography color={'Grey900'} variant={'body1'} component="span">
                                {brief ? brief : truncateStringWithoutCutWords(content, 180)}
                            </Typography>

                            <TagStrip
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'left',
                                }}
                                tags={tags}
                                selectedTagSlug={selectedSlug}
                                isStaticBackground
                            />

                            <Typography color={'Grey900'} variant={'body1'} component="span">
                                {formatDate(new Date(date))}
                            </Typography>
                        </Stack>
                    </Box>
                </TwoColumnLayout>
            </StandardLayout>
        </GreyGradient>
    )
}

export default BlogSummary
