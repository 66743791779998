import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import AppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import Logo from 'components/Logo'
import StandardLayout from '../../components/StandardLayout'
import NavItem from './NavItem'
import LoginBtn from './LoginButton'
import CTAButton from '../../components/CTAButton'
import { navigate } from 'gatsby'
import { FC, useState } from 'react'
import Typography from '@mui/material/Typography'
import NavItemWrapper from './NavItemWrapper'
import { SxProps } from '@mui/material'
import UseCasesMenu from './UseCasesMenu'
import HideRevealToggleButton from '../../components/HideRevealToggleButton'
import CompanyMenu from './CompanyMenu'

const MENU_ITEM_HEIGHT = '64px'

const MobileMenuItem: FC<{ sx?: SxProps; removeBorder?: boolean; subMenu?: React.ReactNode }> = ({
    children,
    removeBorder,
    sx,
    subMenu,
}) => {
    const [expanded, setExpanded] = useState<boolean>(false)
    return (
        <Box
            onClick={() => {
                if (subMenu) {
                    setExpanded(!expanded)
                }
            }}
            sx={{
                width: '100%',
                borderBottom: removeBorder ? 'none' : '1px solid',
                borderColor: `Grey300`,
                transition: 'all .3s cubic-bezier(0.4, 0, 1, 1)',
                maxHeight: expanded ? '500px' : MENU_ITEM_HEIGHT,
                overflow: 'hidden',
                pb: expanded ? 1 : 0,
                flex: '1 0 auto',
                ...(sx && { ...sx }),
            }}
        >
            <Box
                sx={{
                    height: MENU_ITEM_HEIGHT,
                    width: '100%',
                    alignItems: 'stretch',
                    display: 'flex',
                    pl: 1,
                    pr: 1,
                    a: {
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        display: 'flex',
                    },
                }}
            >
                <Box sx={{ width: '100%', alignItems: 'center', display: 'flex' }}>{children}</Box>
                {!!subMenu && (
                    <Box
                        sx={{
                            padding: '0 16px',
                            alignItems: 'center',
                            display: 'flex',
                        }}
                    >
                        <HideRevealToggleButton isOpen={expanded} />
                    </Box>
                )}
            </Box>
            {!!subMenu && <> {subMenu}</>}
        </Box>
    )
}

export default function PersistentDrawerLeft() {
    const [open, setOpen] = React.useState(false)

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                component={'div'}
                color={`White`}
                sx={{
                    boxShadow: 'none',
                }}
                height={`72px`}
            >
                <StandardLayout>
                    <Box
                        display={`flex`}
                        justifyContent={`space-between`}
                        alignItems={`center`}
                        height={`72px`}
                    >
                        <Box height={`32px`} width={`104px`}>
                            <Logo />
                        </Box>
                        {open ? (
                            <IconButton onClick={handleDrawerClose} sx={{ mr: -1 }}>
                                <CloseIcon sx={{ color: `Grey900` }} />
                            </IconButton>
                        ) : (
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                sx={{ mr: -1 }}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                    </Box>
                </StandardLayout>
            </AppBar>
            <Drawer
                sx={{
                    width: `100%`,
                    flexShrink: 0,
                    '&.MuiDrawer-paper': {
                        width: `100%`,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="top"
                open={open}
                PaperProps={{
                    sx: {
                        marginTop: '64px',
                        maxHeight: 'calc(100% - 64px)',
                        boxShadow:
                            '0px 2px 4px -4px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px -10px rgba(0, 0, 0, 0.12)',
                    },
                }}
                SlideProps={{
                    timeout: 0,
                }}
            >
                <MobileMenuItem>
                    <NavItem to={`/product`} text={`Product`} />
                </MobileMenuItem>
                <MobileMenuItem subMenu={<UseCasesMenu />}>
                    <NavItemWrapper>
                        <Typography
                            variant="button"
                            color={`Grey900`}
                            textAlign={`left`}
                            whiteSpace={`nowrap`}
                            lineHeight={0}
                        >
                            Use Cases
                        </Typography>
                    </NavItemWrapper>
                </MobileMenuItem>
                <MobileMenuItem>
                    <NavItem to={`/projects`} text={`Projects`} />
                </MobileMenuItem>
                <MobileMenuItem>
                    <NavItem to={`/customers`} text={`Customers`} />
                </MobileMenuItem>
                <MobileMenuItem removeBorder subMenu={<CompanyMenu />}>
                    <NavItemWrapper>
                        <Typography
                            variant="button"
                            color={`Grey900`}
                            textAlign={`left`}
                            whiteSpace={`nowrap`}
                            lineHeight={0}
                        >
                            Company
                        </Typography>
                    </NavItemWrapper>
                </MobileMenuItem>
                <MobileMenuItem removeBorder>
                    <Box key={8} sx={{ padding: '0 8px', width: '100%' }}>
                        <CTAButton
                            text={`Request access`}
                            onClick={() => {
                                gtag('event', 'request_access', {})
                                navigate('https://dashboard.lune.co/signup', { replace: false })
                            }}
                        />
                    </Box>
                </MobileMenuItem>
                <MobileMenuItem removeBorder>
                    <Box
                        key={9}
                        sx={{
                            width: '100%',
                            justifyContent: 'center',
                            a: {
                                justifyContent: 'center',
                            },
                        }}
                    >
                        <LoginBtn />
                    </Box>
                </MobileMenuItem>
            </Drawer>
        </Box>
    )
}
