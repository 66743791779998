import * as React from 'react'
import { SxProps } from '@mui/material'
import Container from '@mui/material/Container'

const XlContainer = ({ children, sx }: { children: React.ReactNode; sx?: SxProps }) => {
    return (
        <Container maxWidth="xl" sx={{ ...sx, paddingLeft: '0px', paddingRight: '0px' }}>
            {children}
        </Container>
    )
}

export default XlContainer
