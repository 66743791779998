import React, { useMemo } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { RenderOnDesktop, RenderOnMobile } from 'components/DeviceUtils'
import ChainioQuoteTile from 'views/Customers/Sections/Logistics/QuoteTiles/ChainioQuoteTile'
import LogixboardQuoteTile from 'views/Customers/Sections/Logistics/QuoteTiles/LogixboardQuoteTile'
import SevenBridgesQuoteTile from 'views/Customers/Sections/Logistics/QuoteTiles/SevenBridgesQuoteTile'
import LogisticsAnimationMobile from 'views/Customers/Sections/Logistics/LogisticsAnimationMobile'
import { LogoChip } from 'views/Customers/Components/LogoChip'

const bigText = {
    fontSize: '160px',
    fontWeight: '300',
    lineHeight: '192px',
    color: 'Grey200',
}

const animationDurations = {
    row1: '28s',
    row2: '34s',
    row3: '32s',
}

const CarouselShadow = ({ rotate }: { rotate?: boolean }) => {
    return useMemo(() => {
        return (
            <Box
                sx={{
                    position: 'absolute',
                    bottom: '0',
                    top: '0',
                    left: rotate ? 'auto' : '0',
                    right: rotate ? '0' : 'auto',
                    width: '100px',
                    transform: rotate ? 'rotate(180deg)' : 'none',
                    zIndex: 99,
                    background: 'linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
                }}
            />
        )
    }, [rotate])
}

const Row = ({ children }: { children: React.ReactNode }) => {
    return (
        <Box
            sx={{
                position: 'relative',
                height: '220px',
                width: '100%',
                overflow: 'hidden',
                margin: 'auto',
            }}
        >
            {children}
        </Box>
    )
}

const AnimatedChip = ({
    id,
    duration,
    delay,
    chipColour,
    logoSrc,
    top,
}: {
    id: string
    duration: string
    delay: string
    chipColour: string
    logoSrc: string
    top: number
}) => {
    return (
        <Box
            sx={{
                position: 'absolute',
                left: '100%',
                top,
                zIndex: 1,
                [`@keyframes ${id}`]: {
                    '0%': {
                        transform: `translate3d(0px, 0, 0)`,
                    },
                    '100%': {
                        transform: `translate3d(-2120px, 0, 0)`,
                    },
                },
                animation: `${id} ${duration} linear infinite`,
                animationDelay: delay,
                willChange: 'transform',
                backfaceVisibility: 'hidden',
                perspective: 1000,
            }}
        >
            <LogoChip color={chipColour} logoSrc={logoSrc} />
        </Box>
    )
}

const LogisticsAnimation = () => {
    const sharedRowStyle = {
        willChange: 'transform',
        backfaceVisibility: 'hidden',
        perspective: 1000,
        display: 'flex',
        width: 'max-content',
        justifyContent: 'flex-start',
        '.animated-item': {
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'flex-start',
            gap: 5,
        },
    }

    return (
        <Box
            sx={{
                py: 10,
                position: 'relative',
            }}
        >
            <RenderOnDesktop>
                <CarouselShadow />
                <img
                    src={
                        'https://assets.lune.co/lune-co/customers-page-photos/other/grid-desktop.png'
                    }
                    height="656px"
                    width="100%"
                    style={{
                        position: 'absolute',
                        marginTop: '64px',
                    }}
                />
                <Stack spacing={8}>
                    <Row>
                        <AnimatedChip
                            id={'row-1'}
                            duration={'30s'}
                            top={100}
                            delay={'1s'}
                            chipColour={'#DDF5EE'}
                            logoSrc={
                                'https://assets.lune.co/lune-co/client-logos/logixboard-colour.png'
                            }
                        />
                        <AnimatedChip
                            id={'row-1'}
                            duration={'34s'}
                            top={100}
                            delay={'16s'}
                            chipColour={'#FFE4D1'}
                            logoSrc={
                                'https://assets.lune.co/lune-co/client-logos/beacon-colour.png'
                            }
                        />
                        <Box
                            sx={{
                                '@keyframes scroll-row1': {
                                    '0%': {
                                        transform: 'translate3d(0px, 0, 0)',
                                    },
                                    '100%': {
                                        transform: 'translate3d(-1130px, 0, 0)',
                                    },
                                },
                                animation: `scroll-row1 ${animationDurations.row1} linear infinite 1s`,
                                ...sharedRowStyle,
                            }}
                        >
                            {Array.from({ length: 3 }).map((i) => {
                                return (
                                    <Box
                                        className={'animated-item'}
                                        key={`row-1-${i}`}
                                        sx={{ width: '1130px' }}
                                    >
                                        <Typography sx={bigText}>Logistics</Typography>
                                        <LogixboardQuoteTile />
                                    </Box>
                                )
                            })}
                        </Box>
                    </Row>
                    <Row>
                        <AnimatedChip
                            id={'row-2'}
                            duration={'30s'}
                            top={10}
                            delay={'12s'}
                            chipColour={'#DDF5EE'}
                            logoSrc={
                                'https://assets.lune.co/lune-co/client-logos/budbee-colour.png'
                            }
                        />
                        <AnimatedChip
                            id={'row-2'}
                            duration={'33s'}
                            top={150}
                            delay={'24s'}
                            chipColour={'#D3F1FF'}
                            logoSrc={'https://assets.lune.co/lune-co/client-logos/shiptheory.png'}
                        />
                        <AnimatedChip
                            id={'row-2'}
                            duration={'34s'}
                            top={10}
                            delay={'34s'}
                            chipColour={'#DDF5EE'}
                            logoSrc={'https://assets.lune.co/lune-co/client-logos/agx-colour.png'}
                        />
                        <Box
                            sx={{
                                '@keyframes scroll-row2': {
                                    '0%': {
                                        transform: 'translate3d(0px, 0, 0)',
                                    },
                                    '100%': {
                                        transform: 'translate3d(-1755px, 0, 0)',
                                    },
                                },
                                animation: `scroll-row2 ${animationDurations.row2} linear infinite 1s`,
                                ...sharedRowStyle,
                            }}
                        >
                            {Array.from({ length: 3 }).map((i) => {
                                return (
                                    <Box
                                        key={`row-2-${i}`}
                                        className={'animated-item'}
                                        sx={{ width: '1755px' }}
                                    >
                                        <Typography sx={bigText}>Freight forwarding</Typography>
                                        <ChainioQuoteTile />
                                    </Box>
                                )
                            })}
                        </Box>
                    </Row>
                    <Row>
                        <AnimatedChip
                            id={'row-3'}
                            duration={'32s'}
                            top={8}
                            delay={'6s'}
                            chipColour={'#D4E2FF'}
                            logoSrc={
                                'https://assets.lune.co/lune-co/client-logos/gocomet-colour.png'
                            }
                        />
                        <AnimatedChip
                            id={'row-3'}
                            duration={'35s'}
                            top={24}
                            delay={'20s'}
                            chipColour={'#B8DBFF'}
                            logoSrc={'https://assets.lune.co/lune-co/client-logos/chainio.png'}
                        />
                        <Box
                            sx={{
                                '@keyframes scroll-row3': {
                                    '0%': {
                                        transform: 'translate3d(0px, 0, 0)',
                                    },
                                    '100%': {
                                        transform: 'translate3d(-1130px, 0, 0)',
                                    },
                                },
                                animation: `scroll-row3 ${animationDurations.row3} linear infinite 1s`,
                                ...sharedRowStyle,
                            }}
                        >
                            {Array.from({ length: 3 }).map((i) => {
                                return (
                                    <Box
                                        key={`row-3-${i}`}
                                        className={'animated-item'}
                                        sx={{ width: '1130px' }}
                                    >
                                        <Typography sx={bigText}>Shippers</Typography>
                                        <SevenBridgesQuoteTile />
                                    </Box>
                                )
                            })}
                        </Box>
                    </Row>
                </Stack>
                <CarouselShadow rotate />
            </RenderOnDesktop>
            <RenderOnMobile>
                <LogisticsAnimationMobile />
            </RenderOnMobile>
        </Box>
    )
}

export default LogisticsAnimation
