import React from 'react'
import { Box, SxProps } from '@mui/material'

const StaticImageWrapper = ({
    children,
    placeholder,
    hidePlaceholder,
    genericPlaceholder,
    sx,
}: {
    children: React.ReactNode
    placeholder?: string
    hidePlaceholder?: boolean
    genericPlaceholder?: boolean
    sx?: SxProps
}) => {
    return (
        <Box
            sx={{
                ...sx,
                height: '100%',
                width: '100%',
                '[data-gatsby-image-wrapper]': {
                    transform: 'translateZ(0)',
                },
                '[data-placeholder-image]': {
                    backgroundColor: 'transparent !important',
                    position: 'initial !important',
                },
                '[data-placeholder-image]:before': {
                    content: '""',
                    backgroundImage: genericPlaceholder
                        ? `url("https://assets.lune.co/lune-co/placeholders/testimonial.svg")`
                        : `url("${placeholder}")`,
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    height: '100%',
                    width: '100%',
                    display: hidePlaceholder ? 'none' : 'block',
                    borderRadius: '40px',
                    overflow: 'hidden',
                    backgroundPosition: 'top left',
                    backgroundSize: 'contain',
                },
            }}
        >
            {children}
        </Box>
    )
}

export default StaticImageWrapper
