import React from 'react'
import Paper from '@mui/material/Paper'

const Shadow = ({ children, sx }: { children: React.ReactNode; sx?: any }) => {
    const sxWrapper = {
        borderRadius: '48px',
        boxShadow: '-24px 24px 0px rgba(0, 0, 0, 0.05)',
        ...(sx ? sx : {}),
    }
    return (
        <Paper elevation={24} sx={sxWrapper}>
            {children}
        </Paper>
    )
}

export default Shadow
