import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React from 'react'
import NavItemWrapper from './NavItemWrapper'

const LoginBtn = () => (
    <Box
        sx={{
            mx: {
                xs: 0,
                sm: 2.5,
                md: 2.5,
                lg: 2.5,
                xl: 2.5,
            },
        }}
    >
        <a
            style={{
                textDecoration: `none`,
            }}
            href={`https://dashboard.lune.co/`}
        >
            <NavItemWrapper>
                <Typography variant={`button`} color={`Grey900`} whiteSpace={`nowrap`}>
                    Log In
                </Typography>
            </NavItemWrapper>
        </a>
    </Box>
)

export default LoginBtn
