import * as React from 'react'
import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import BackgroundImageWithPlaceholder, {
    IGatsbyFluidImage,
} from 'components/BackgroundImageWithPlaceholder'
import useIsMobile from 'hooks/useIsMobile'

const QuoteTile = ({
    backgroundImageStaticQuery,
    text,
    logo,
}: {
    backgroundImageStaticQuery: IGatsbyFluidImage
    text: string
    logo: JSX.Element
}) => {
    const isMobile = useIsMobile()

    return (
        <BackgroundImageWithPlaceholder
            placeholder={'https://assets.lune.co/lune-co/placeholders/testimonial.svg'}
            backgroundImageStaticQuery={backgroundImageStaticQuery}
            style={{
                ...(isMobile ? {} : { width: 420 }),
                height: 220,
                borderRadius: `16px`,
                overflow: 'hidden',
                padding: isMobile ? '24px 16px 32px 114px' : '32px 32px 32px 160px',
            }}
        >
            <Stack direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                <Box>{logo}</Box>
                <Typography color={'Grey900'} variant={'testimonialMobile'} component="span">
                    {text}
                </Typography>
            </Stack>
        </BackgroundImageWithPlaceholder>
    )
}

export default QuoteTile
