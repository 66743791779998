import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import TollOutlinedIcon from '@mui/icons-material/TollOutlined'
import { Chip } from 'lune-ui-lib'
import TabTwoColumnLayout from 'components/TabTwoColumnLayout'
import useIsMobile from 'hooks/useIsMobile'
import IdeoQuoteCard from 'views/Customers/Sections/BuyCarbonCredits/IdeoQuoteCard'
import BudbeeQuoteCard from 'views/Customers/Sections/BuyCarbonCredits/BudbeeQuoteCard'
import MoominQuoteCard from './MoominQuoteCard'
import VestreQuoteCard from './VestreQuoteCard'

const BuyCreditsChip = ({ color }: { color: string }) => {
    return (
        <Chip
            sx={{ maxWidth: 'max-content', background: color }}
            icon={<TollOutlinedIcon sx={{ width: '20px', height: '20px' }} />}
            label={'Buy Credits'}
        />
    )
}

const BuyCarbonCreditsTabSection = () => {
    const isMobile = useIsMobile()
    return (
        <TabTwoColumnLayout
            withImageTabs={true}
            labels={[
                <img
                    key={0}
                    alt={'Visa logo'}
                    height={24}
                    src={'https://assets.lune.co/lune-co/tabs-logos/ideo.png'}
                />,
                <img
                    key={1}
                    alt={'Algbra logo'}
                    height={24}
                    src={'https://assets.lune.co/lune-co/tabs-logos/budbee.png'}
                />,
                <img
                    key={2}
                    alt={'Moomin logo'}
                    height={24}
                    src={'https://assets.lune.co/lune-co/tabs-logos/moomin.png'}
                />,
                <img
                    key={3}
                    alt={'Vestre logo'}
                    height={24}
                    src={'https://assets.lune.co/lune-co/tabs-logos/vestre.png'}
                />,
            ]}
            left={[
                <Box key={0}>
                    <Stack spacing={5}>
                        <Typography variant={isMobile ? 'body1' : 'testimonialMobile'}>
                            IDEO could see that transparency was critically lacking in the carbon
                            markets, so they focused their search on finding high impact projects.
                        </Typography>
                        <Typography variant={isMobile ? 'body1' : 'testimonialMobile'}>
                            This year, IDEO renewed their annual carbon offsetting with Lune. As
                            part of their portfolio, they committed over a third of their budget
                            into permanent carbon removal with UNDO Enhanced Rock Weathering.
                        </Typography>
                        <Stack spacing={1}>
                            <BuyCreditsChip color={'#FFD6B8'} />
                        </Stack>
                    </Stack>
                </Box>,
                <Box key={1}>
                    <Stack spacing={5}>
                        <Typography variant={isMobile ? 'body1' : 'testimonialMobile'}>
                            Budbee wanted to move away from traditional, opaque consultancies where
                            they lacked confidence in the true impact (and actual cost) of the
                            projects they were supporting.
                        </Typography>
                        <Typography variant={isMobile ? 'body1' : 'testimonialMobile'}>
                            They switched to Lune after discovering each project came with high
                            levels of transparency and flexibility, especially ongoing news and
                            updates shared on project profiles in the Lune dashboard.
                        </Typography>
                        <Stack spacing={1}>
                            <BuyCreditsChip color={'#C9D8F9'} />
                        </Stack>
                    </Stack>
                </Box>,
                <Box key={2}>
                    <Stack spacing={5}>
                        <Typography variant={isMobile ? 'body1' : 'testimonialMobile'}>
                            Moomin recognised that their emissions reduction efforts are a long
                            journey and they wanted to do more already today.
                        </Typography>
                        <Typography variant={isMobile ? 'body1' : 'testimonialMobile'}>
                            To maximise their impact, they took a contribution-led approach at set
                            an annual budget of €100,000 to fund a carbon project portfolio aligned
                            with the Oxford Offsetting Principles.
                        </Typography>
                        <Stack spacing={1}>
                            <BuyCreditsChip color={'#E4F4F4'} />
                        </Stack>
                    </Stack>
                </Box>,
                <Box key={3}>
                    <Stack spacing={5}>
                        <Typography variant={isMobile ? 'body1' : 'testimonialMobile'}>
                            After working with carbon accounting platform Variable on their overall
                            Net Zero strategy, Vestre wanted to neutralise unavoidable emissions.
                        </Typography>
                        <Typography variant={isMobile ? 'body1' : 'testimonialMobile'}>
                            They chose specifically to purchase only permanent carbon removal –
                            carbon credits from projects which are removing existing emissions from
                            the atmosphere and guaranteeing they will be safely stored for hundreds
                            or thousands of years.
                        </Typography>
                        <Stack spacing={1}>
                            <BuyCreditsChip color={'#BCDDEE'} />
                        </Stack>
                    </Stack>
                </Box>,
            ]}
            right={[
                <Box key={0}>
                    <IdeoQuoteCard />
                </Box>,
                <Box key={1}>
                    <BudbeeQuoteCard />
                </Box>,
                <Box key={2}>
                    <MoominQuoteCard />
                </Box>,
                <Box key={3}>
                    <VestreQuoteCard />
                </Box>,
            ]}
        />
    )
}

export default BuyCarbonCreditsTabSection
