import React from 'react'
import TwoColumnLayout, { ColumnWrapOrder } from 'components/TwoColumnLayout'
import Line from 'components/Line'
import SectionLink from 'components/SectionLink'
import Shadow from 'components/Shadow'
import H3 from 'components/H3'
import SectionBody1 from 'components/SectionBody1'
import StaticImageWrapper from '../components/StaticImageWrapper'
import { StaticImage } from 'gatsby-plugin-image'

const StandoutSection = () => {
    return (
        <TwoColumnLayout leftCol={5} rightCol={6} columnWrapOrderOnMobile={ColumnWrapOrder.NORMAL}>
            <Shadow>
                <StaticImageWrapper
                    placeholder={
                        'https://assets.lune.co/lune-co/placeholders/payments-projects-payment.svg'
                    }
                >
                    <StaticImage
                        placeholder={'dominantColor'}
                        quality={100}
                        src={'../images/PaymentsEmissions.png'}
                        alt={'Standout'}
                        layout="fullWidth"
                    />
                </StaticImageWrapper>
            </Shadow>

            <>
                <H3>Build stronger relationships by helping merchants take climate action</H3>
                <SectionBody1>
                    Use a green checkout experience to convert the 70% of consumers looking for a
                    green payment method while enabling merchants to automatically contribute to
                    climate action with every transaction.
                </SectionBody1>

                <Line />

                <SectionLink text={'Explore our projects'} to={'/projects'} />
            </>
        </TwoColumnLayout>
    )
}

export default StandoutSection
