import React from 'react'
import { Chip } from 'lune-ui-lib'
import { SxProps } from '@mui/material'
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined'

const ForYourTechTeam = ({ sx }: { sx?: SxProps }) => {
    return (
        <Chip
            sx={{ ...sx, maxWidth: 'max-content' }}
            label={'For your tech team'}
            icon={<CodeOutlinedIcon />}
        />
    )
}

export default ForYourTechTeam
