import { Box, Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import useIsMobile from 'hooks/useIsMobile'
import { Input, Button } from 'lune-ui-lib'
import React, { useState, useEffect } from 'react'
import StandardLayout from './StandardLayout'
import { StaticImage } from 'gatsby-plugin-image'
import { useDebounce } from 'use-debounce'
import { useNewsletter } from 'hooks/useNewsletter'
import Bugsnag from '@bugsnag/js'
import StaticImageWrapper from './StaticImageWrapper'
import { HUBSPOT_FORM_IDS } from '../constants'

const spacing = {
    xs: 3,
    sm: 3,
    md: 6,
    lg: 8,
    xl: 10,
}

export const SubscribeSection = ({ hubspotFormId }: { hubspotFormId: string }) => {
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { isValidEmail, toHubspot } = useNewsletter(hubspotFormId, [
        'Access our full project evaluation process',
    ])

    const [name, setName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [company, setCompany] = useState<string>('')
    const [nameError, setNameError] = useState<boolean>(false)
    const [emailError, setEmailError] = useState<boolean>(false)
    const [companyError, setCompanyError] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>()
    const [debouncedEmail] = useDebounce(email, 1000)

    useEffect(() => {
        if (isValidEmail(email) || email === '') {
            setEmailError(false)
        } else {
            setEmailError(true)
        }
    }, [debouncedEmail])

    useEffect(() => {
        setNameError(false)
    }, [name])

    const onSubmit = async () => {
        const validName = name.length
        const validEmail = isValidEmail(email)
        const validCompany = company.length

        if (!validName || !validEmail || !validCompany) {
            !validName && setNameError(true)
            !validEmail && setEmailError(true)
            !validCompany && setCompanyError(true)
            return
        }

        const data = await toHubspot({
            name,
            email,
            company,
            legalConsent: 'gatedContent',
        })
        if (data.status === 'error') {
            Bugsnag.notify(new Error(`Subscription error: ${hubspotFormId}`))
            setSuccess(false)
        } else {
            setSuccess(true)
            setName('')
            setEmail('')
            setCompany('')

            switch (hubspotFormId) {
                case HUBSPOT_FORM_IDS.blog:
                    gtag('event', 'newsletter_signup', {})
                    break
                case HUBSPOT_FORM_IDS.projects:
                    gtag('event', 'download_guide', {})
                    break
            }
        }
    }

    return (
        <StandardLayout>
            <Stack
                direction={isMobile ? 'column' : 'row'}
                display="flex"
                sx={{
                    borderRadius: '32px',
                    backgroundColor: '#F8F4EF',
                    overflow: 'hidden',
                    ...(!isMobile && { maxHeight: '640px' }),
                    ...(success && { display: 'flex', alignItems: 'center' }),
                }}
            >
                <Stack
                    spacing={spacing}
                    sx={{
                        flex: 1,
                        padding: spacing,
                        height: '100%',
                        ...(isMobile && { paddingTop: '40px !important' }),
                    }}
                >
                    {success ? (
                        <>
                            <Typography
                                variant={useMediaQuery(theme.breakpoints.down('lg')) ? 'h5' : 'h4'}
                            >
                                Thank you, you’ll get the guide in your inbox shortly.
                            </Typography>
                            <Typography variant="body1">
                                In the mean time,{' '}
                                <Link
                                    variant="body1"
                                    color={'Grey900'}
                                    href="https://dashboard.lune.co/signup"
                                    onClick={() => gtag('event', 'request_access', {})}
                                    sx={{
                                        display: 'inline-block',
                                    }}
                                >
                                    request access to Lune
                                </Link>{' '}
                                to make your first carbon purchase and explore the product further.
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography
                                variant={useMediaQuery(theme.breakpoints.down('lg')) ? 'h5' : 'h4'}
                            >
                                Access our full project evaluation process
                            </Typography>
                            <Stack spacing={1}>
                                <>
                                    <Input
                                        sx={{
                                            height: '48px',
                                            backgroundColor: nameError
                                                ? theme.palette.Red50
                                                : theme.palette.White,
                                        }}
                                        name="name"
                                        placeholder="Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </>
                                <Input
                                    sx={{
                                        height: '48px',
                                        backgroundColor: emailError
                                            ? theme.palette.Red50
                                            : theme.palette.White,
                                    }}
                                    name="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <Input
                                    sx={{
                                        height: '48px',
                                        backgroundColor: companyError
                                            ? theme.palette.Red50
                                            : theme.palette.White,
                                    }}
                                    name="company"
                                    placeholder="Company"
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                />
                            </Stack>
                            <Button
                                sx={{
                                    width: '100%',
                                    textTransform: 'none',
                                }}
                                type="submit"
                                onClick={onSubmit}
                                leftIcon={
                                    <ArrowCircleRightIcon
                                        sx={{
                                            width: '20px',
                                            height: '20px',
                                            backgroundColor: `Grey900`,
                                            color: `White`,
                                        }}
                                    />
                                }
                            >
                                Download guide
                            </Button>
                        </>
                    )}
                </Stack>
                <Box
                    sx={{
                        flex: 1,
                        marginTop: spacing,
                        marginRight: spacing,
                        ...(isMobile && { marginLeft: '24px' }),
                    }}
                >
                    <StaticImageWrapper placeholder="https://assets.lune.co/lune-co/placeholders/projects-quality-over-quantity.svg">
                        {isMobile ? (
                            <StaticImage
                                placeholder="dominantColor"
                                alt="Offsetting guide"
                                src="../images/ProjectEvaluationSmall.png"
                            />
                        ) : (
                            <StaticImage
                                placeholder="dominantColor"
                                alt="Offsetting guide"
                                src="../images/ProjectEvaluationLarge.png"
                            />
                        )}
                    </StaticImageWrapper>
                </Box>
            </Stack>
        </StandardLayout>
    )
}
