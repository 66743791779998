import React, { useState } from 'react'
import TwoColumnLayout, { ColumnWrapOrder } from './TwoColumnLayout'
import { Tabs } from 'lune-ui-lib'
import { Stack, Box } from '@mui/material'

const TabTwoColumnLayout = ({
    labels,
    left,
    right,
    chips,
    withImageTabs,
}: {
    labels: string[] | JSX.Element[]
    left: JSX.Element[]
    right: JSX.Element[]
    chips?: JSX.Element[]
    withImageTabs?: boolean
}) => {
    if (left.length !== right.length) {
        throw new Error('Left and right must be the same length')
    }
    if (labels.length !== left.length) {
        throw new Error('Labels must be the same length as left')
    }
    if (chips && labels.length !== chips.length) {
        throw new Error('Chips must be the same length as labels')
    }

    const [selectedIndex, setSelectedIndex] = useState<number>(0)

    return (
        <TwoColumnLayout columnWrapOrderOnMobile={ColumnWrapOrder.REVERSE}>
            <>
                <Stack
                    spacing={{
                        xs: 3,
                        sm: 1.5,
                        md: 1.5,
                        lg: 1.5,
                        xl: 1.5,
                    }}
                    direction={{
                        xs: 'column',
                        sm: 'row',
                        md: 'row',
                        lg: 'row',
                        xl: 'row',
                    }}
                    mb={{
                        xs: 3,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    }}
                >
                    {chips?.[selectedIndex]}
                    <Box sx={{ overflow: 'hidden' }}>
                        <Tabs
                            onChange={setSelectedIndex}
                            sx={{
                                ...(withImageTabs && {
                                    '.MuiTab-root': {
                                        marginRight: '16px !important',
                                    },
                                    '.MuiTab-root:not(.Mui-selected) img': {
                                        opacity: 0.4,
                                    },
                                }),
                                '.MuiTabs-indicator': {
                                    bottom: '8px !important',
                                },
                            }}
                        >
                            {labels.map((label, i) => (
                                <Tabs.Tab key={i} label={label} />
                            ))}
                        </Tabs>
                    </Box>
                </Stack>

                {React.Children.map(left, (child, i) => (i === selectedIndex ? child : null))}
            </>
            <>{React.Children.map(right, (child, i) => (i === selectedIndex ? child : null))}</>
        </TwoColumnLayout>
    )
}

export default TabTwoColumnLayout
