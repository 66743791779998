import TwoColumnLayout, { ColumnWrapOrder } from 'components/TwoColumnLayout'
import SectionBody1 from 'components/SectionBody1'
import Box from '@mui/material/Box'
import Line from 'components/Line'
import React from 'react'
import SectionLink from 'components/SectionLink'
import H3 from 'components/H3'
import OverflowImage from 'components/OverflowImage'
import StaticImageWrapper from '../components/StaticImageWrapper'
import { StaticImage } from 'gatsby-plugin-image'

const TakeBackOffsettingSection = () => {
    return (
        <TwoColumnLayout columnWrapOrderOnMobile={ColumnWrapOrder.REVERSE}>
            <Box>
                <H3>Take back control of your carbon credit purchases</H3>
                <SectionBody1>
                    <b>Forget the expensive consultancies.</b> Find the perfect projects and
                    purchase in 2-3 clicks. Build a custom portfolio or choose a pre-built portfolio
                    such as the Oxford Offsetting Principles.
                </SectionBody1>

                <Line />

                <SectionLink
                    text={'Request access'}
                    to={'https://dashboard.lune.co/signup'}
                    onClick={() => gtag('event', 'request_access', {})}
                    newPage
                />
            </Box>
            <OverflowImage shadow>
                <Box
                    sx={{
                        width: 'unset',
                        aspectRatio: '1880 / 1600',
                        height: '100%',
                    }}
                >
                    <StaticImageWrapper
                        placeholder={
                            'https://assets.lune.co/lune-co/placeholders/directpurchase-dashboard-dashboard.svg'
                        }
                    >
                        <StaticImage
                            placeholder={'dominantColor'}
                            src={'../images/BuyOffsetsNew.png'}
                            alt={'Buy offsets'}
                            layout="fixed"
                            objectPosition={'100% 0%'}
                            objectFit={'contain'}
                            quality={100}
                            style={{ width: 'unset', aspectRatio: '1880 / 1600', height: '100%' }}
                        />
                    </StaticImageWrapper>
                </Box>
            </OverflowImage>
        </TwoColumnLayout>
    )
}

export default TakeBackOffsettingSection
