import { Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import useIsMobile from 'hooks/useIsMobile'
import * as React from 'react'
import TagStrip from 'components/TagStrip'

function getPopularArticles(): {
    prismicPopularArticles: {
        data: {
            articles: {
                article: {
                    document: {
                        data: {
                            brief: string
                            main_image: {
                                alt: string
                                gatsbyImageData: any
                            }
                            slug: string
                            title: {
                                text: string
                            }
                            tags: {
                                tag: {
                                    document: {
                                        data: {
                                            slug: string
                                            color: string
                                            name: {
                                                text: string
                                            }
                                            index: number
                                        }
                                    }
                                }
                            }[]
                        }
                    }
                }
            }[]
        }
    }
} {
    return useStaticQuery(graphql`
        query {
            prismicPopularArticles {
                data {
                    articles {
                        article {
                            document {
                                ... on PrismicBlogpostSliced {
                                    data {
                                        brief
                                        slug
                                        main_image {
                                            alt
                                            gatsbyImageData
                                        }
                                        title {
                                            text
                                        }
                                        tags {
                                            tag {
                                                document {
                                                    ... on PrismicTag {
                                                        data {
                                                            slug
                                                            color
                                                            name {
                                                                text
                                                            }
                                                            index
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)
}

export const PopularArticles = () => {
    const isMobile = useIsMobile()
    const prismicArticles = getPopularArticles()
    const articles = prismicArticles.prismicPopularArticles.data.articles

    return (
        <Stack
            sx={{
                px: {
                    sm: 6,
                    md: 15,
                    lg: 15,
                    xl: 15,
                },
            }}
        >
            <Typography
                variant={isMobile ? 'h5' : 'h4'}
                sx={{
                    px: isMobile ? 3 : 0,
                }}
            >
                Popular articles
            </Typography>
            <Stack direction={isMobile ? 'column' : 'row'} spacing={isMobile ? 0 : 3}>
                {articles.map((item, index) => {
                    const article = item.article.document.data
                    return (
                        <Stack
                            key={index}
                            direction="column"
                            spacing={3}
                            sx={{
                                background: isMobile
                                    ? 'linear-gradient(180deg, #FFFFFF 0%, #F5F5F5 100%)'
                                    : 'transparent',
                                padding: isMobile ? 3 : 0,
                                cursor: 'pointer',
                                flex: '1',
                            }}
                            onClick={() => {
                                navigate(`/blog/${article.slug}/`, { replace: false })
                            }}
                        >
                            <Box
                                sx={{
                                    mt: isMobile ? 3 : 8,
                                    mb: 3,
                                }}
                            >
                                <GatsbyImage
                                    image={article.main_image.gatsbyImageData}
                                    alt={article.main_image.alt ?? ''}
                                    style={{
                                        height: '240px',
                                        width: '100%',
                                        objectFit: 'none',
                                        borderRadius: '32px',
                                    }}
                                />
                            </Box>
                            <Typography variant="h5">{article.title.text}</Typography>
                            <Typography variant="body2">{article.brief}</Typography>
                            <TagStrip
                                tags={article.tags.map(
                                    ({
                                        tag: {
                                            document: {
                                                data: {
                                                    color,
                                                    slug,
                                                    name: { text },
                                                    index,
                                                },
                                            },
                                        },
                                    }) => ({
                                        color,
                                        slug,
                                        name: text,
                                        index,
                                    }),
                                )}
                                sx={{
                                    justifyContent: 'flex-start',
                                }}
                            />
                        </Stack>
                    )
                })}
            </Stack>
        </Stack>
    )
}
