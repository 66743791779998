import * as React from 'react'
import Box from '@mui/material/Box'
import TabTwoColumnLayout from '../components/TabTwoColumnLayout'
import Line from 'components/Line'
import SectionLink from 'components/SectionLink'
import H3 from 'components/H3'
import SectionBody1 from 'components/SectionBody1'
import ForAPI from 'components/ForAPI'
import OverflowImage, { ShortPlaceholder } from 'components/OverflowImage'
import StaticImageWrapper from '../components/StaticImageWrapper'
import { StaticImage } from 'gatsby-plugin-image'

const ProductAPIUseCasesSection = () => {
    return (
        <TabTwoColumnLayout
            labels={['Carbon offsetting', 'Emission calculations']}
            chips={[<ForAPI key={0} />, <ForAPI key={1} />]}
            left={[
                <Box key={0}>
                    <H3>Make every product impactful with automatic climate contributions</H3>
                    <SectionBody1>
                        Connect your customers to high impact carbon projects and technologies by
                        integrating climate contributions directly into your customer experience.
                    </SectionBody1>

                    <Line />

                    <SectionLink text={'View API docs'} to={'https://docs.lune.co'} newPage />
                </Box>,
                <Box key={1}>
                    <H3>Empower customers with emissions transparency</H3>
                    <SectionBody1>
                        Let your customers make greener decisions by helping them understand the
                        Scope 3 emissions from their products, services, and supply chains.
                    </SectionBody1>

                    <Line />

                    <SectionLink text={'View API docs'} to={'https://docs.lune.co'} newPage />
                </Box>,
            ]}
            right={[
                <OverflowImage
                    key={0}
                    placeholder={<ShortPlaceholder />}
                    containerSx={{
                        filter: `drop-shadow(-24px 24px 0px rgba(0,100,100,0.05))`,
                    }}
                >
                    <StaticImageWrapper
                        placeholder={
                            'https://assets.lune.co/lune-co/placeholders/product-api-carbonoffsetting.svg'
                        }
                    >
                        <StaticImage
                            placeholder={'dominantColor'}
                            src={'../images/API-Offsetting.png'}
                            alt={'Offsetting'}
                            layout="fixed"
                            objectPosition={'100% 0%'}
                            objectFit={'contain'}
                            quality={100}
                            style={{ width: 'unset', aspectRatio: '1842 / 1134', height: '100%' }}
                        />
                    </StaticImageWrapper>
                </OverflowImage>,
                <OverflowImage
                    key={1}
                    placeholder={<ShortPlaceholder />}
                    containerSx={{
                        filter: `drop-shadow(-24px 24px 0px rgba(0,100,100,0.05))`,
                    }}
                >
                    <StaticImageWrapper
                        placeholder={
                            'https://assets.lune.co/lune-co/placeholders/product-api-emissionscalulations.svg'
                        }
                    >
                        <StaticImage
                            placeholder={'dominantColor'}
                            src={'../images/API-EmissionsCalcs.png'}
                            alt={'Emission calculations'}
                            layout="fixed"
                            objectPosition={'100% 0%'}
                            objectFit={'contain'}
                            quality={100}
                            style={{ width: 'unset', aspectRatio: '1842 / 1134', height: '100%' }}
                        />
                    </StaticImageWrapper>
                </OverflowImage>,
            ]}
        />
    )
}

export default ProductAPIUseCasesSection
