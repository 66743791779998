import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import QuoteCard from 'components/QuoteCard'

const VisaQuoteCard = () => {
    return (
        <QuoteCard
            text={
                'Sustainability is a growing focus in the payment ecosystem, and we are building our capabilities at Visa to meet that demand. Through our partnership with Lune, we aim to make it easier for our clients to take meaningful climate action, by embedding emission calculations and carbon offsetting into their product offering.'
            }
            profileName={'Jeni Mundy'}
            profileTitle={'Global SVP Merchant Sales & Acquiring at Visa'}
            profileAvatar={
                <StaticImage
                    src="../../../../images/avatars/visa-jeni-mundy.png"
                    alt={`Jeni Mundy`}
                />
            }
            backgroundImageStaticQuery={useStaticQuery(graphql`
                query {
                    desktop: file(relativePath: { eq: "customerQuoteCards/visa.png" }) {
                        childImageSharp {
                            fluid(quality: 90, maxWidth: 500) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            `)}
        />
    )
}

export default VisaQuoteCard
