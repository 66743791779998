import StandardLayout from 'components/StandardLayout'
import StyledRichText from 'components/StyledRichText'
import ReactMarkdown from 'react-markdown'
import * as React from 'react'

export const Markdown = ({
    slice,
}: {
    slice: {
        primary: {
            content: {
                richText: any
            }
        }
    }
}) => {
    return (
        <StandardLayout
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <StyledRichText
                sx={{
                    maxWidth: '720px',
                }}
            >
                <ReactMarkdown>{slice.primary.content.richText[0].text}</ReactMarkdown>
            </StyledRichText>
        </StandardLayout>
    )
}

export default Markdown
