import { Chip, LuneTheme } from 'lune-ui-lib'
import { Avatar, Box } from '@mui/material'
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import React from 'react'

const ProjectSpecs = ({
    registryName,
    registryLink,
    offsetType,
    carbonPermanence,
}: {
    registryName: string
    registryLink: string
    offsetType: string
    carbonPermanence: string
}) => {
    const { spacing } = LuneTheme

    const registryExt = [
        { name: 'Verra', extension: 'verra' },
        { name: 'Puro.earth', extension: 'puro-earth' },
        { name: 'American Carbon Registry', extension: 'american-carbon-registry' },
        { name: 'Gold Standard', extension: 'gold-standard' },
        { name: 'Woodland Carbon Code', extension: 'woodland-carbon-code' },
        { name: 'Climate Action Reserve', extension: 'climate-action-reserve' },
    ].find((r) => r.name === registryName)?.extension

    return (
        <Box
            sx={{
                display: 'flex',
                gap: spacing(0.5),
                flexWrap: 'wrap',
                alignItems: 'center',
            }}
        >
            {registryName ? (
                <Chip
                    sx={{ textDecoration: 'underline' }}
                    href={registryLink}
                    icon={
                        <Avatar
                            alt={registryName}
                            src={`https://assets.lune.co/registries/${registryExt}-icon.png`}
                            sx={{ width: '24px', height: '24px' }}
                        />
                    }
                    label={registryName}
                />
            ) : (
                <Chip label="Not certified" />
            )}
            {offsetType && (
                <Chip
                    icon={
                        offsetType === 'emissions_reduction' ? (
                            <CloudOffOutlinedIcon sx={{ width: '20px', height: '20px' }} />
                        ) : (
                            <CloudDownloadOutlinedIcon sx={{ width: '20px', height: '20px' }} />
                        )
                    }
                    label={
                        offsetType === 'emissions_reduction'
                            ? 'Emissions Reduction'
                            : 'Carbon Removal'
                    }
                />
            )}
            <Chip
                icon={<LockOutlinedIcon sx={{ width: '20px', height: '20px' }} />}
                label={
                    carbonPermanence
                        ? carbonPermanence === 'long_term'
                            ? 'Long-term carbon storage'
                            : 'Short-term carbon storage'
                        : 'No carbon storage'
                }
            />
        </Box>
    )
}

export default ProjectSpecs
