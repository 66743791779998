exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-buy-carbon-credits-tsx": () => import("./../../../src/pages/buy-carbon-credits.tsx" /* webpackChunkName: "component---src-pages-buy-carbon-credits-tsx" */),
  "component---src-pages-customers-tsx": () => import("./../../../src/pages/customers.tsx" /* webpackChunkName: "component---src-pages-customers-tsx" */),
  "component---src-pages-fintech-and-banking-tsx": () => import("./../../../src/pages/fintech-and-banking.tsx" /* webpackChunkName: "component---src-pages-fintech-and-banking-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-logistics-tsx": () => import("./../../../src/pages/logistics.tsx" /* webpackChunkName: "component---src-pages-logistics-tsx" */),
  "component---src-pages-payments-tsx": () => import("./../../../src/pages/payments.tsx" /* webpackChunkName: "component---src-pages-payments-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-procurement-tsx": () => import("./../../../src/pages/procurement.tsx" /* webpackChunkName: "component---src-pages-procurement-tsx" */),
  "component---src-pages-product-tsx": () => import("./../../../src/pages/product.tsx" /* webpackChunkName: "component---src-pages-product-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-views-blog-index-page-tsx": () => import("./../../../src/views/BlogIndexPage.tsx" /* webpackChunkName: "component---src-views-blog-index-page-tsx" */),
  "component---src-views-prismic-blog-page-with-slices-tsx": () => import("./../../../src/views/PrismicBlogPageWithSlices.tsx" /* webpackChunkName: "component---src-views-prismic-blog-page-with-slices-tsx" */),
  "component---src-views-prismic-landing-page-tsx": () => import("./../../../src/views/PrismicLandingPage.tsx" /* webpackChunkName: "component---src-views-prismic-landing-page-tsx" */)
}

