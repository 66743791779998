import React from 'react'
import { Chip } from 'lune-ui-lib'
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import { SxProps } from '@mui/material'

const ForYourBusiness = ({ sx }: { sx?: SxProps }) => {
    return (
        <Chip
            sx={{ ...sx, maxWidth: 'max-content' }}
            label={'For your business'}
            icon={<BusinessOutlinedIcon />}
        />
    )
}

export default ForYourBusiness
