import * as React from 'react'
import Box from '@mui/material/Box'
import { FC } from 'react'
import { CtaTile } from 'lune-ui-lib'
import { navigate } from 'gatsby'
import { RenderOnDesktop, RenderOnSmallScreen } from 'components/DeviceUtils'

import About from 'images/menuTileImages/About.png'
import Blog from 'images/menuTileImages/Blog.png'

const menuItems = [
    {
        url: '/about',
        label: 'About Us',
        img: <img src={About} alt={`About us`} />,
    },
    {
        url: '/blog',
        label: 'Blog',
        img: <img src={Blog} alt={`Blog`} />,
    },
]

import { Stack } from '@mui/system'

const CompanyMenu: FC = () => {
    return (
        <>
            <RenderOnSmallScreen>
                {menuItems.map((menuItem) => (
                    <Box
                        key={`mobile-menu-${menuItem.label}`}
                        sx={{
                            margin: '8px 16px',
                        }}
                    >
                        <CtaTile
                            mini
                            showArrow={false}
                            image={menuItem.img}
                            title={menuItem.label}
                            href={menuItem.url}
                        />
                    </Box>
                ))}
            </RenderOnSmallScreen>
            <RenderOnDesktop>
                <Stack sx={{ padding: '24px 32px', width: '304px' }} spacing={1}>
                    {menuItems.map((menuItem) => (
                        <CtaTile
                            key={`menu-${menuItem.label}`}
                            mini
                            sxProps={{
                                pr: 1,
                            }}
                            showArrow={false}
                            image={menuItem.img}
                            title={menuItem.label}
                            href={menuItem.url}
                        />
                    ))}
                </Stack>
            </RenderOnDesktop>
        </>
    )
}

export default CompanyMenu
