import QuoteCard from 'components/QuoteCard'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

const FortoQuoteCard = () => {
    return (
        <QuoteCard
            text={
                'We partnered with Lune because they give us the highest levels of trust and transparency in the carbon projects that we – and our customers – contribute to.'
            }
            profileName={'Nina Göntgen-Voss'}
            profileTitle={'Director of Sustainability at Forto'}
            profileAvatar={
                <StaticImage
                    src="../../../../images/avatars/forto-nina-gontgen-voss.png"
                    alt={`Nina Göntgen-Voss`}
                />
            }
            backgroundImageStaticQuery={useStaticQuery(graphql`
                query {
                    desktop: file(relativePath: { eq: "customerQuoteCards/forto2.png" }) {
                        childImageSharp {
                            fluid(quality: 90, maxWidth: 500) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            `)}
        />
    )
}

export default FortoQuoteCard
