import * as React from 'react'
import Box from '@mui/material/Box'
import { SxProps } from '@mui/material'

const StandardLayout = ({ sx, children, ...rest }: { sx?: SxProps; children: React.ReactNode }) => {
    return (
        <Box
            {...rest}
            px={{
                xs: 3,
                sm: 6,
                md: 15,
                lg: 15,
                xl: 15,
            }}
            sx={{
                position: 'relative',
                height: `100%`,
                ...(sx ? sx : {}),
            }}
        >
            {children}
        </Box>
    )
}

export default StandardLayout
