import Grid from '@mui/material/Grid'
import * as React from 'react'
import useIsMobile from '../hooks/useIsMobile'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

type MuiValues = number | { xs: number; sm: number; md: number; lg: number; xl: number }

const StatementsWithBigIcons = ({
    statements,
    smallIcons,
    gridSizes,
}: {
    statements: {
        iconNode: React.ReactNode
        title: React.ReactNode
        subTitle?: string
    }[]
    smallIcons?: boolean
    gridSizes?: MuiValues
}) => {
    const isMobile = useIsMobile()

    const gridItemProps = {
        ...(gridSizes
            ? gridSizes
            : {
                  xs: 4,
                  sm: 3,
                  md: 3,
                  lg: 3,
                  xl: 3,
              }),
        display: `flex`,
        justifyContent: isMobile ? `flex-start` : `center`,
        flexDirection: isMobile ? `row` : `column`,
        alignItems: `center`,
        item: true,
        component: `div`,
        height: `100%`,
    }

    const iconSizeDesktop = smallIcons ? 64 : 120
    const iconSizeMobile = smallIcons ? 43 : 72

    const imageContainer = {
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
        width: {
            xs: iconSizeMobile,
            sm: iconSizeDesktop,
            md: iconSizeDesktop,
            lg: iconSizeDesktop,
            xl: iconSizeDesktop,
        },
        height: {
            xs: iconSizeMobile,
            sm: iconSizeDesktop,
            md: iconSizeDesktop,
            lg: iconSizeDesktop,
            xl: iconSizeDesktop,
        },
        mb: {
            xs: 0,
            sm: 3,
            md: 3,
            lg: 3,
            xl: 3,
        },
        mr: {
            xs: 3,
            sm: 0,
            md: 0,
            lg: 0,
            xl: 0,
        },
        mt: 0,
        minWidth: {
            xs: iconSizeMobile,
            sm: iconSizeDesktop,
            md: iconSizeDesktop,
            lg: iconSizeDesktop,
            xl: iconSizeDesktop,
        },
    }

    const TypographyProps = {
        color: 'Grey900',
        variant: isMobile ? `body1` : `h5`,
        component: 'div',
        textAlign: isMobile ? 'left' : 'center',
        maxHeight: isMobile ? 'none' : 145,
    }

    const SubTitleTypographyProps = {
        variant: isMobile ? 'body2' : 'body1',
        component: 'div',
        marginTop: 1,
        textAlign: isMobile ? 'left' : 'center',
        maxHeight: isMobile ? 'none' : 145,
    }

    return (
        <Grid
            container
            spacing={{
                xs: 5,
                sm: 3,
                md: 3,
                lg: 3,
                xl: 3,
            }}
            columns={{ xs: 4, sm: 12, md: 12, lg: 12, xl: 12 }}
            justifyContent="space-evenly"
            alignItems="top"
            height="100%"
            style={{
                marginTop: '0px',
            }}
        >
            {statements?.map((s, index) => (
                <Grid key={`statement${index}`} {...gridItemProps}>
                    <Box sx={imageContainer}>{s.iconNode}</Box>
                    <div>
                        <Typography {...TypographyProps}>{s.title}</Typography>
                        {s.subTitle && (
                            <Typography {...SubTitleTypographyProps}>{s.subTitle}</Typography>
                        )}
                    </div>
                </Grid>
            ))}
        </Grid>
    )
}

export default StatementsWithBigIcons
