import { WindowLocation } from '@reach/router'
import RichTextPage from '../views/RichTextPage'
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import useSiteMetadata from '../hooks/useSiteMetadata'

const TermsAndConditionsPage = ({ location }: { location?: WindowLocation }) => {
    const data = useStaticQuery(graphql`
        {
            prismicTermsConditions {
                data {
                    title {
                        text
                    }
                    content {
                        html
                    }
                    last_updated
                }
            }
        }
    `)
    const defaultMeta = useSiteMetadata()
    return (
        <RichTextPage
            title={data.prismicTermsConditions.data.title.text}
            content={{
                html: data.prismicTermsConditions.data.content.html,
            }}
            meta={{
                description: `Lune Climate Ltd's standard terms and conditions.`,
                image: defaultMeta.image,
            }}
            location={location!}
        />
    )
}

export default TermsAndConditionsPage
