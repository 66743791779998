import QuoteCard from 'components/QuoteCard'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

const SalesforceQuoteCard = () => {
    return (
        <QuoteCard
            text={
                'Lune’s focus on sourcing high-impact carbon credits, combined with the automation their software provides, makes them an ideal partner for Net Zero Marketplace. Now we can bring companies, customers, and climate action together at scale.'
            }
            profileName={'Nina Schoen'}
            profileTitle={'Director of Product Management at Salesforce'}
            profileAvatar={
                <StaticImage
                    src="../../../../images/avatars/salesforce-nina-schoen.png"
                    alt={`Nina Schoen`}
                />
            }
            backgroundImageStaticQuery={useStaticQuery(graphql`
                query {
                    desktop: file(relativePath: { eq: "customerQuoteCards/salesforce.jpg" }) {
                        childImageSharp {
                            fluid(quality: 90, maxWidth: 500) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            `)}
        />
    )
}

export default SalesforceQuoteCard
