import * as React from 'react'
import Typography from '@mui/material/Typography'
import useIsMobile from '../hooks/useIsMobile'
import { StaticImage } from 'gatsby-plugin-image'
import Profile from '../components/Profile'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import BackgroundImageWithPlaceholder, { IGatsbyFluidImage } from './BackgroundImageWithPlaceholder'

const Testimonial = ({
    backgroundImageStaticQuery,
    text,
    profileName,
    profileTitle,
    profileAvatar,
    profileLogo,
    textColor,
    whiteQuote,
}: {
    backgroundImageStaticQuery: IGatsbyFluidImage
    text: string
    profileName: string
    profileTitle: string
    profileAvatar: React.ReactNode
    profileLogo?: string
    textColor?: string
    whiteQuote?: boolean
}) => {
    const color = textColor ?? `Grey900`
    const isMobile = useIsMobile()

    return (
        <BackgroundImageWithPlaceholder
            placeholder={'https://assets.lune.co/lune-co/placeholders/testimonial.svg'}
            backgroundImageStaticQuery={backgroundImageStaticQuery}
            style={{
                height: isMobile ? `620px` : `800px`,
                borderRadius: `48px`,
                overflow: 'hidden',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    height: '100%',
                }}
            >
                <Grid
                    container
                    justifyContent="center"
                    alignItems="stretch"
                    columns={{ xs: 4, sm: 12, md: 12, lg: 12, xl: 12 }}
                    mx={{ xs: 3, sm: 0, md: 0, lg: 0, xl: 0 }}
                >
                    <Grid item xs={0} sm={1} md={1} lg={1} xl={1}></Grid>
                    <Grid item xs={4} sm={10} md={10} lg={10} xl={10}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                height: '100%',
                            }}
                        >
                            <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="stretch"
                                my={{
                                    xs: 9,
                                    sm: 4,
                                    md: 4,
                                    lg: 4,
                                    xl: 4,
                                }}
                                spacing={{
                                    xs: 3,
                                    sm: 4,
                                    md: 4,
                                    lg: 4,
                                    xl: 4,
                                }}
                            >
                                <div>
                                    {isMobile && whiteQuote && (
                                        <StaticImage
                                            src={'../images/QuotesWhite.png'}
                                            alt={'quotes'}
                                            width={48}
                                        />
                                    )}
                                    {!isMobile && whiteQuote && (
                                        <StaticImage
                                            src={'../images/QuotesWhite.png'}
                                            alt={'quotes'}
                                            width={64}
                                        />
                                    )}
                                    {isMobile && !whiteQuote && (
                                        <StaticImage
                                            src={'../images/QuotesGrey900.png'}
                                            alt={'quotes'}
                                            width={48}
                                        />
                                    )}
                                    {!isMobile && !whiteQuote && (
                                        <StaticImage
                                            src={'../images/QuotesGrey900.png'}
                                            alt={'quotes'}
                                            width={64}
                                        />
                                    )}
                                </div>
                                <Typography
                                    color={color}
                                    variant={isMobile ? 'testimonialMobile' : 'testimonial'}
                                    component="span"
                                >
                                    {text}
                                </Typography>
                                <Profile
                                    name={profileName}
                                    title={profileTitle}
                                    avatar={profileAvatar}
                                    textColor={color}
                                    logo={profileLogo}
                                />
                            </Stack>
                        </div>
                    </Grid>
                    <Grid item xs={0} sm={1} md={1} lg={1} xl={1}></Grid>
                </Grid>
            </div>
        </BackgroundImageWithPlaceholder>
    )
}

export default Testimonial
