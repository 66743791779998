import React from 'react'
import Box from '@mui/material/Box'
import { SxProps } from '@mui/material'
import Typography from '@mui/material/Typography'
import useIsMobile from 'hooks/useIsMobile'
import Button from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'

const TagStrip = ({
    selectedTagSlug,
    tags,
    isStaticBackground,
    sx,
}: {
    selectedTagSlug?: string
    tags: {
        name: string
        color: string
        slug: string
        index: number
    }[]
    isStaticBackground?: boolean
    sx?: SxProps
}) => {
    const theme = useTheme()
    const isMobile = useIsMobile()

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: isMobile ? `left` : 'center',
                flexWrap: 'wrap',
                gap: 0.5,
                ...sx,
            }}
        >
            {tags
                ?.sort((a, b) => a.index - b.index)
                .map((t) => {
                    const isActive = selectedTagSlug === t.slug
                    return (
                        <Button
                            disableRipple
                            sx={{
                                padding: 1,
                                background:
                                    (selectedTagSlug && isActive) || !selectedTagSlug
                                        ? t.color
                                        : `${(theme.palette as any).Grey100}`,
                                ...(isStaticBackground && {
                                    background: t.color,
                                }),
                                borderRadius: `4px`,
                                whiteSpace: 'nowrap',
                                color: `Grey900`,
                                '&:hover': {
                                    background: selectedTagSlug ? t.color : `rgba(0,0,0,0.1);`,
                                },
                            }}
                            key={t.slug}
                            onClick={(e) => e.stopPropagation()}
                            href={isActive ? `/blog` : `/blog/tags/${t.slug}`}
                        >
                            <Typography variant={'button'}>{t.name}</Typography>
                        </Button>
                    )
                })}
        </Box>
    )
}

export default TagStrip
