import QuoteCard from 'components/QuoteCard'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

const GoCardlessQuoteCard = () => {
    return (
        <QuoteCard
            text={`Lune's platform is the most user friendly one when it comes to how easy it is to purchase carbon removal and how easy it made sustainability reporting on our end.`}
            profileName={'Ben Knight'}
            profileTitle={'Head of Environmental Sustainability at GoCardless'}
            profileAvatar={
                <StaticImage
                    src="../../../../images/avatars/goCardless-ben-knight.png"
                    alt={`Ben Knight`}
                />
            }
            backgroundImageStaticQuery={useStaticQuery(graphql`
                query {
                    desktop: file(relativePath: { eq: "customerQuoteCards/goCardless.png" }) {
                        childImageSharp {
                            fluid(quality: 90, maxWidth: 500) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            `)}
        />
    )
}

export default GoCardlessQuoteCard
