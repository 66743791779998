import React from 'react'
import Box from '@mui/material/Box'
import useIsMobile from '../hooks/useIsMobile'

const GradientCircleIcon = ({ Background, Icon }: { Background: React.ReactNode; Icon: any }) => {
    const isMobile = useIsMobile()
    return (
        <Box
            height={isMobile ? '72px' : '120px'}
            width={isMobile ? '72px' : '120px'}
            sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                }}
                component={`span`}
            >
                {Background}
            </Box>
            {Icon && (
                <Icon
                    color={`Gray900`}
                    sx={{
                        position: 'relative',
                        height: isMobile ? `28px` : `42px`,
                        width: isMobile ? `28px` : `42px`,
                    }}
                />
            )}
        </Box>
    )
}

export default GradientCircleIcon
