import * as React from 'react'
import { Box } from '@mui/system'
import { useEffect, useRef, useState } from 'react'
import { LuneTheme } from 'lune-ui-lib'

export interface HeroItemProps {
    id: number
    image: string
    mobileImage: string
    translucent: string
    background: string
    label: string
    darkMode?: boolean
}

export const heroItems: HeroItemProps[] = [
    {
        id: 1,
        image: 'https://assets.lune.co/lune-co/home-page-hero/payments-emissions.png',
        mobileImage: 'https://assets.lune.co/lune-co/home-page-hero/payments-emissions-mobile.png',
        translucent:
            'https://assets.lune.co/lune-co/home-page-hero/payments-emissions-translucent.png',
        background: `url('https://assets.lune.co/lune-co/home-page-hero/light-green.png')`,
        label: 'pay',
    },
    {
        id: 2,
        image: 'https://assets.lune.co/lune-co/home-page-hero/buy-offsets.png',
        mobileImage: 'https://assets.lune.co/lune-co/home-page-hero/buy-offsets-mobile.png',
        translucent: 'https://assets.lune.co/lune-co/home-page-hero/buy-offsets-translucent.png',
        background: `url('https://assets.lune.co/lune-co/home-page-hero/buy-offsets-background.png')`,
        label: 'buyOffsets',
    },
    {
        id: 3,
        image: 'https://assets.lune.co/lune-co/home-page-hero/fintech.png',
        mobileImage: 'https://assets.lune.co/lune-co/home-page-hero/fintech-mobile.png',
        translucent: 'https://assets.lune.co/lune-co/home-page-hero/fintech-translucent.png',
        background: `url('https://assets.lune.co/lune-co/home-page-hero/light-blue.png')`,
        label: 'fintech',
    },
    {
        id: 4,
        image: 'https://assets.lune.co/lune-co/home-page-hero/logistics.png',
        mobileImage: 'https://assets.lune.co/lune-co/home-page-hero/logistics-mobile.png',
        translucent: 'https://assets.lune.co/lune-co/home-page-hero/logistics-translucent.png',
        background: `url('https://assets.lune.co/lune-co/home-page-hero/dark-blue.png')`,
        label: 'logistics',
        darkMode: true,
    },
    {
        id: 5,
        image: 'https://assets.lune.co/lune-co/home-page-hero/certificate-mobile.png',
        mobileImage: 'https://assets.lune.co/lune-co/home-page-hero/certificate-mobile.png',
        translucent: 'https://assets.lune.co/lune-co/home-page-hero/certificate-translucent.png',
        background: `url('https://assets.lune.co/lune-co/home-page-hero/certificate-background.png')`,
        label: 'certificate',
    },
    {
        id: 6,
        image: 'https://assets.lune.co/lune-co/home-page-hero/api.png',
        mobileImage: 'https://assets.lune.co/lune-co/home-page-hero/api-mobile.png',
        translucent: 'https://assets.lune.co/lune-co/home-page-hero/api-translucent.png',
        background: `url('https://assets.lune.co/lune-co/home-page-hero/dark-grey.png')`,
        label: 'api',
        darkMode: true,
    },
]

const useImageLoaded = () => {
    const [loadedImage, setLoadedImage] = useState<boolean>(false)
    const imageRef = useRef<HTMLImageElement>(null)

    const onLoad = () => {
        setLoadedImage(true)
    }

    useEffect(() => {
        if (imageRef.current && imageRef.current.complete) {
            onLoad()
        }
    })

    return { imageRef, loadedImage, onLoad }
}

const HeroImgWrapper = ({
    item,
    setHoveredItem,
    hoveredItem,
}: {
    item: HeroItemProps
    setHoveredItem: (item: HeroItemProps | undefined) => void
    hoveredItem: HeroItemProps | undefined
}) => {
    const { palette } = LuneTheme
    const { imageRef, loadedImage, onLoad } = useImageLoaded()

    const [isActive, setIsActive] = useState<boolean>(hoveredItem?.id === item.id)
    const negativeMarginsByIds: Record<number, string> = {
        4: '-46px',
        5: '-148px',
        6: '-75px',
    }
    const imagePlaceholderHeightById: Record<number, string> = {
        1: '72%',
        2: '58%',
        3: '77%',
        4: '52%',
        5: '100%',
        6: '53%',
    }

    return (
        <Box
            sx={{
                width: '100%',
                mb: item.id < 4 ? '24px' : negativeMarginsByIds[item.id],
                position: 'relative',
                transition: 'transform .3s ease-in-out',

                ...(!loadedImage && {
                    paddingBottom: imagePlaceholderHeightById[item.id],
                    borderRadius: '20px',
                    backgroundColor: palette.Grey200,
                }),

                img: {
                    width: '100%',
                    transition: 'opacity .3s ease-in-out',
                    display: 'block',

                    ...(!loadedImage && {
                        opacity: '0 !important',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }),

                    '&.image-main': {
                        opacity: 1,
                    },
                    '&.image-translucent': {
                        opacity: loadedImage ? 0 : 1,
                        zIndex: -1,
                    },
                },
                '.image-translucent': {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    opacity: 0,
                },
                '&.item-passive': {
                    transform: 'scale(0.9)',
                    '.image-main': {
                        opacity: 0,
                    },
                    '.image-translucent': {
                        opacity: 1,
                    },
                },
                '&.item-active': {
                    transform: 'scale(1.1)',
                },
            }}
            className={`
            ${isActive ? 'item-active' : ''} 
            ${hoveredItem && !isActive ? 'item-passive' : ''}
            `}
            onMouseEnter={() => {
                setIsActive(true)
                setHoveredItem(item)
            }}
            onMouseLeave={() => {
                setIsActive(false)
                setHoveredItem(undefined)
            }}
        >
            <img
                ref={imageRef}
                onLoad={onLoad}
                draggable="false"
                className={'image-main'}
                src={item.image}
                alt={item.label}
            />
            <img
                draggable="false"
                className={'image-translucent'}
                src={item.translucent}
                alt={item.label}
            />
        </Box>
    )
}

const HeroItems = ({ onItemHover }: { onItemHover: (item: HeroItemProps | undefined) => void }) => {
    const [hovered, setHovered] = useState<HeroItemProps>()
    return (
        <Box
            sx={{
                maxWidth: '100%',
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
                gap: '24px',
                margin: 'auto',
            }}
        >
            {[...new Array(3)].map((_c, index) => {
                return (
                    <Box
                        key={`home-heading-item-${index}`}
                        sx={{
                            width: '100%',
                        }}
                    >
                        <HeroImgWrapper
                            key={`home-heading-item-${index}-image`}
                            item={heroItems[index]}
                            hoveredItem={hovered}
                            setHoveredItem={(item) => {
                                setHovered(item)
                                onItemHover(item)
                            }}
                        />
                        <HeroImgWrapper
                            key={`home-heading-item-${index + 3}-image`}
                            item={heroItems[index + 3]}
                            hoveredItem={hovered}
                            setHoveredItem={(item) => {
                                setHovered(item)
                                onItemHover(item)
                            }}
                        />
                    </Box>
                )
            })}
        </Box>
    )
}

export default HeroItems
