import RichTextPage from '../views/RichTextPage'
import { WindowLocation } from '@reach/router'
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import useSiteMetadata from '../hooks/useSiteMetadata'

const PrivacyPolicyPage = ({ location }: { location?: WindowLocation }) => {
    const data = useStaticQuery(graphql`
        {
            prismicPrivacyPolicy {
                data {
                    content {
                        html
                    }
                }
            }
        }
    `)
    const defaultMeta = useSiteMetadata()
    return (
        <RichTextPage
            title={`Privacy Policy`}
            content={{
                html: data.prismicPrivacyPolicy.data.content.html,
            }}
            meta={{
                description: `Lune Climate Ltd's privacy policy.`,
                image: defaultMeta.image,
            }}
            location={location!}
        />
    )
}

export default PrivacyPolicyPage
