import * as React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Profile from '../components/Profile'
import { formatDate } from '../utils'
import useIsMobile from 'hooks/useIsMobile'
import { GatsbyImage } from 'gatsby-plugin-image'
import { RenderOnDesktop, RenderOnMobile } from './DeviceUtils'
import TagStrip from 'components/TagStrip'

interface BlogHeaderProps {
    title: string
    subtitle?: string | null
    date: string | Date
    author: {
        name: string
        title: string
        avatar: { gatsbyImageData: any }
    }
    tags: {
        color: string
        name: string
        slug: string
        index: number
    }[]
}

export const BlogHeader = ({ title, subtitle, date, author, tags }: BlogHeaderProps) => {
    const isMobile = useIsMobile()

    const titleSx = {
        wordBreak: `break-word`,
        whiteSpace: `pre-wrap`,
        wordWrap: `break-word`,
        textAlign: {
            xs: 'left',
            sm: 'center',
            md: 'center',
            lg: 'center',
            xl: 'center',
        },
    }

    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems={isMobile ? 'flex-start' : 'center'}
            spacing={{
                xs: 3,
                sm: 4,
                md: 4,
                lg: 4,
                xl: 4,
            }}
        >
            <Profile
                name={author.name}
                title={author.title}
                avatar={<GatsbyImage image={author.avatar.gatsbyImageData} alt={author.name} />}
            />

            <RenderOnMobile>
                <Typography variant={`h4`} component="div" sx={titleSx}>
                    {title}
                </Typography>
            </RenderOnMobile>

            <RenderOnDesktop>
                <Typography variant={`h2`} component="div" sx={titleSx}>
                    {title}
                </Typography>
            </RenderOnDesktop>

            {subtitle && (
                <Typography
                    color={'Grey900'}
                    variant={'body1'}
                    component="span"
                    textAlign={isMobile ? 'left' : 'center'}
                >
                    {subtitle}
                </Typography>
            )}

            <TagStrip tags={tags} />

            <Typography color={'Grey900'} variant={'body1'} component="span">
                {formatDate(new Date(date))}
            </Typography>
        </Stack>
    )
}
