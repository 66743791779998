import * as React from 'react'
import Box from '@mui/material/Box'
import StandardLayout from '../components/StandardLayout'
import GreyGradient from 'components/GreyGradient'
import XlContainer from '../components/XlContainer'
import ContactForm from 'views/ContactForm'
import Footer from 'views/Footer'
import useLogBreakpoints from 'hooks/useLogBreakpoints'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import StatementsWithBigIcons from '../views/StatementsWithBigIcons'
import { StaticImage } from 'gatsby-plugin-image'
import useIsMobile from '../hooks/useIsMobile'
import HeroHeadingAbout from '../views/HeroHeadingAbout'
import CTAButton from '../components/CTAButton'
import { navigate } from 'gatsby'
import PerksAndRecreation from '../views/PerksAndRecreation'
import CrookedGradientBackgroundSectionAbout from '../views/CrookedGradientBackgroundSectionAbout'
import { LINKS } from '../constants'
import Page, { PageBody } from '../components/Page'
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined'
import GradientCircleIcon from '../components/GradientCircleIcon'
import SatelliteAltOutlinedIcon from '@mui/icons-material/SatelliteAltOutlined'
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined'
import TryOutlinedIcon from '@mui/icons-material/TryOutlined'

const stackSpacing = {
    xs: 10,
    sm: 15,
    md: 15,
    lg: 15,
    xl: 15,
}

const About = ({ location }: { location?: string }) => {
    useLogBreakpoints()
    const isMobile = useIsMobile()
    return (
        <Page location={location} title="Lune - About">
            <PageBody>
                <Stack spacing={stackSpacing}>
                    <Stack spacing={stackSpacing}>
                        <GreyGradient>
                            <XlContainer>
                                <Stack
                                    spacing={stackSpacing}
                                    sx={{
                                        mt: {
                                            xs: 9,
                                            sm: 15,
                                            md: 15,
                                            lg: 15,
                                            xl: 15,
                                        },
                                    }}
                                >
                                    <StandardLayout>
                                        <Typography
                                            variant={`h2`}
                                            sx={{
                                                textAlign: {
                                                    xs: `left`,
                                                    sm: `center`,
                                                    md: `center`,
                                                    lg: `center`,
                                                    xl: `center`,
                                                },
                                            }}
                                        >
                                            Our mission:{<br />} Make every product and service
                                            climate positive by default.
                                        </Typography>
                                    </StandardLayout>

                                    <StandardLayout>
                                        <HeroHeadingAbout />
                                    </StandardLayout>
                                    {/*Div below is only used for spacing on Desktop res*/}
                                    {!isMobile && <div />}
                                </Stack>
                            </XlContainer>
                        </GreyGradient>
                    </Stack>
                    <Box sx={{ width: '100%' }}>
                        <XlContainer>
                            <GreyGradient hide={!isMobile}>
                                {isMobile && <Box sx={{ height: `80px` }} />}
                                <StandardLayout>
                                    <Box
                                        sx={{
                                            display: `flex`,
                                            justifyContent: {
                                                xs: `left`,
                                                sm: `center`,
                                                md: `center`,
                                                lg: `center`,
                                                xl: `center`,
                                            },
                                            mb: {
                                                xs: 5,
                                                sm: 10,
                                                md: 10,
                                                lg: 10,
                                                xl: 10,
                                            },
                                        }}
                                    >
                                        <Typography variant={`h3`}>Our Values</Typography>
                                    </Box>

                                    <StatementsWithBigIcons
                                        statements={[
                                            {
                                                iconNode: (
                                                    <GradientCircleIcon
                                                        Background={
                                                            <StaticImage
                                                                src={
                                                                    '../images/gradientCircles/about/1.png'
                                                                }
                                                                alt={`Gradient`}
                                                                layout="fullWidth"
                                                            />
                                                        }
                                                        Icon={VolunteerActivismOutlinedIcon}
                                                    />
                                                ),
                                                title: `Force For Good`,
                                                subTitle: `Raise the bar for our people, our customers, our planet`,
                                            },
                                            {
                                                iconNode: (
                                                    <GradientCircleIcon
                                                        Background={
                                                            <StaticImage
                                                                src={
                                                                    '../images/gradientCircles/about/2.png'
                                                                }
                                                                alt={`Gradient`}
                                                                layout="fullWidth"
                                                            />
                                                        }
                                                        Icon={SatelliteAltOutlinedIcon}
                                                    />
                                                ),
                                                title: `Lead with Innovation`,
                                                subTitle: `Challenge the status quo`,
                                            },
                                            {
                                                iconNode: (
                                                    <GradientCircleIcon
                                                        Background={
                                                            <StaticImage
                                                                src={
                                                                    '../images/gradientCircles/about/3.png'
                                                                }
                                                                alt={`Gradient`}
                                                                layout="fullWidth"
                                                            />
                                                        }
                                                        Icon={TryOutlinedIcon}
                                                    />
                                                ),
                                                title: `Open and Honest`,
                                                subTitle: `Speak our minds with care, communicate openly and candidly`,
                                            },
                                            {
                                                iconNode: (
                                                    <GradientCircleIcon
                                                        Background={
                                                            <StaticImage
                                                                src={
                                                                    '../images/gradientCircles/about/4.png'
                                                                }
                                                                alt={`Gradient`}
                                                                layout="fullWidth"
                                                            />
                                                        }
                                                        Icon={ExploreOutlinedIcon}
                                                    />
                                                ),
                                                title: `Guided by Curiosity`,
                                                subTitle: `Think beyond boundaries, allow yourself the freedom to learn & grow`,
                                            },
                                        ]}
                                    />
                                </StandardLayout>
                                {isMobile && <Box sx={{ height: `80px` }} />}
                            </GreyGradient>
                        </XlContainer>

                        <CrookedGradientBackgroundSectionAbout />

                        <XlContainer>
                            <Stack spacing={stackSpacing}>
                                <StandardLayout>
                                    <Stack
                                        spacing={{
                                            xs: 0,
                                            sm: 15,
                                            md: 15,
                                            lg: 15,
                                            xl: 15,
                                        }}
                                    >
                                        <Typography
                                            variant="h3"
                                            sx={{
                                                textAlign: {
                                                    xs: `left`,
                                                    sm: `center`,
                                                    md: `center`,
                                                    lg: `center`,
                                                    xl: `center`,
                                                },
                                            }}
                                        >
                                            Perks & Recreation at Lune
                                        </Typography>
                                        <div>
                                            <PerksAndRecreation />
                                        </div>
                                    </Stack>
                                </StandardLayout>

                                <StandardLayout>
                                    <Box
                                        sx={{
                                            display: `flex`,
                                            justifyContent: `center`,
                                        }}
                                    >
                                        <CTAButton
                                            text={`See Open Roles`}
                                            onClick={() => navigate(LINKS.jobs, { replace: false })}
                                        />
                                    </Box>
                                </StandardLayout>

                                <StandardLayout>
                                    <ContactForm hubspotFormName={'about'} />
                                </StandardLayout>

                                <Footer />
                            </Stack>
                        </XlContainer>
                    </Box>
                </Stack>
            </PageBody>
        </Page>
    )
}

export default About
