import TwoColumnLayout, { ColumnWrapOrder } from 'components/TwoColumnLayout'
import SectionBody1 from 'components/SectionBody1'
import Box from '@mui/material/Box'
import Line from 'components/Line'
import React from 'react'
import SectionLink from 'components/SectionLink'
import H3 from 'components/H3'
import OverflowImage from 'components/OverflowImage'
import QualityImageWithPlaceholder from '../components/QualityImgWithPlaceholder'

const BuyCreditsQualityAndQuantitySection = () => {
    return (
        <TwoColumnLayout columnWrapOrderOnMobile={ColumnWrapOrder.REVERSE}>
            <Box>
                <H3>Quality over quantity above all else</H3>
                <SectionBody1>
                    <b>Steer clear of greenwashing risk</b> with up to 90% of projects filtered out.
                    Lune’s in-house analysis combined with leading external evaluation ensures
                    trust, transparency, and tangible climate benefits.
                </SectionBody1>

                <Line />

                <SectionLink
                    text={'Access our Quality Assessment Guide'}
                    to={'https://share-eu1.hsforms.com/1mXQPdoXfRQuLcy7O0yqn6wez9mr'}
                    newPage
                />
            </Box>
            <OverflowImage>
                <QualityImageWithPlaceholder />
            </OverflowImage>
        </TwoColumnLayout>
    )
}

export default BuyCreditsQualityAndQuantitySection
