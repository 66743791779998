import React from 'react'
import TwoColumnLayout, { ColumnWrapOrder } from 'components/TwoColumnLayout'
import H3 from 'components/H3'
import SectionBody1 from 'components/SectionBody1'
import Box from '@mui/material/Box'
import RetailAndPaymentsAnimation from '../components/RetailAndPaymentsAnimation'
import Line from 'components/Line'
import SectionLink from 'components/SectionLink'

const InstagramSection = () => {
    return (
        <TwoColumnLayout columnWrapOrderOnMobile={ColumnWrapOrder.REVERSE}>
            <>
                <H3>Launch smoothly and confidently</H3>
                <SectionBody1>
                    Launch in a matter of days - keeping your roadmap on track and adding creating
                    additional value for your customers. Nail the messaging using Lune’s best
                    practice guides and frameworks.
                </SectionBody1>

                <Line />

                <SectionLink
                    text={'Get started'}
                    to={'https://dashboard.lune.co/signup'}
                    newPage
                    onClick={() => gtag('event', 'request_access', {})}
                />
            </>
            <Box display="flex" justifyContent="flex-end">
                <RetailAndPaymentsAnimation />
            </Box>
        </TwoColumnLayout>
    )
}

export default InstagramSection
