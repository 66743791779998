import React from 'react'
import { Box, Grid, ImageList, ImageListItem } from '@mui/material'
import { RenderOnDesktop, RenderOnMobile } from '../components/DeviceUtils'

const desktopLogos = [
    [
        {
            name: 'Visa',
            src: 'https://assets.lune.co/lune-co/client-logos/Visa.png',
        },
        {
            name: 'Salesforce',
            src: 'https://assets.lune.co/lune-co/client-logos/Salesforce.png',
        },
        {
            name: 'IDEO',
            src: 'https://assets.lune.co/lune-co/client-logos/IDEO.png',
        },
        {
            name: 'Forto',
            src: 'https://assets.lune.co/lune-co/client-logos/Forto.png',
        },
        {
            name: 'GoCardless',
            src: 'https://assets.lune.co/lune-co/client-logos/Gocardless.png',
        },
        {
            name: 'Truelayer',
            src: 'https://assets.lune.co/lune-co/client-logos/Truelayer.png',
        },
    ],
    [
        {
            name: 'Ovako',
            src: 'https://assets.lune.co/lune-co/client-logos/Ovako.png',
        },
        {
            name: 'Moomin',
            src: 'https://assets.lune.co/lune-co/client-logos/Moomin.png',
        },
        {
            name: 'Payhawk',
            src: 'https://assets.lune.co/lune-co/client-logos/Payhawk.png',
        },
        {
            name: 'Budbee',
            src: 'https://assets.lune.co/lune-co/client-logos/Budbee.png',
        },
        {
            name: 'Logixboard',
            src: 'https://assets.lune.co/lune-co/client-logos/Logixboard.png',
        },
    ],
    [
        {
            name: 'Crown Agents Bank',
            src: 'https://assets.lune.co/lune-co/client-logos/Crown-Agents-Bank.png',
        },
        {
            name: 'Schüttflix',
            src: 'https://assets.lune.co/lune-co/client-logos/Schuttflix.png',
        },
        {
            name: 'Enfuce',
            src: 'https://assets.lune.co/lune-co/client-logos/enfuce.png',
        },
        {
            name: 'GoComet',
            src: 'https://assets.lune.co/lune-co/client-logos/GoComet.png',
        },
    ],
]

const mobileLogos = [
    {
        name: 'Visa',
        src: 'https://assets.lune.co/lune-co/client-logos/Visa.png',
    },
    {
        name: 'Salesforce',
        src: 'https://assets.lune.co/lune-co/client-logos/Salesforce.png',
    },
    {
        name: 'Forto',
        src: 'https://assets.lune.co/lune-co/client-logos/Forto.png',
    },
    {
        name: 'Ovako',
        src: 'https://assets.lune.co/lune-co/client-logos/Ovako.png',
    },
    {
        name: 'Payhawk',
        src: 'https://assets.lune.co/lune-co/client-logos/Payhawk.png',
    },
    {
        name: 'Logixboard',
        src: 'https://assets.lune.co/lune-co/client-logos/Logixboard.png',
    },
    {
        name: 'IDEO',
        src: 'https://assets.lune.co/lune-co/client-logos/IDEO.png',
    },
    {
        name: 'GoCardless',
        src: 'https://assets.lune.co/lune-co/client-logos/Gocardless.png',
    },
]

const DesktopLogosSection = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                columnGap: {
                    xs: '16px',
                    sm: '32px',
                    md: '32px',
                    lg: '32px',
                    xl: '32px',
                },
                rowGap: {
                    xs: '20px',
                    sm: '24px',
                    md: '24px',
                    lg: '24px',
                    xl: '24px',
                },
                width: '100%',
            }}
        >
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{
                    width: '100%',
                    height: '42px',
                }}
                spacing={1}
            >
                {desktopLogos[0].map(({ name, src }, j) => (
                    <Grid
                        item
                        key={j}
                        xs={2}
                        sx={{ height: { xs: '50px' /* 42px + 8 spacing */ } }}
                    >
                        <img
                            src={src}
                            height="42"
                            style={{ objectFit: 'contain', width: '100%' }}
                            alt={name}
                        />
                    </Grid>
                ))}
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{
                    width: '100%',
                    height: '42px',
                    width: '80%',
                }}
                spacing={1}
            >
                {desktopLogos[1].map(({ name, src }, j) => (
                    <Grid
                        item
                        key={j}
                        xs={2}
                        sx={{ height: { xs: '50px' /* 42px + 8 spacing */ } }}
                    >
                        <img
                            src={src}
                            height="42"
                            style={{ objectFit: 'contain', width: '100%' }}
                            alt={name}
                        />
                    </Grid>
                ))}
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{
                    width: '100%',
                    height: '42px',
                    width: '60%',
                }}
                spacing={1}
            >
                {desktopLogos[2].map(({ name, src }, j) => (
                    <Grid
                        item
                        key={j}
                        xs={2}
                        sx={{ height: { xs: '50px' /* 42px + 8 spacing */ } }}
                    >
                        <img
                            src={src}
                            height="42"
                            style={{ objectFit: 'contain', width: '100%' }}
                            alt={name}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

const MobileLogosSection = () => {
    return (
        <Grid
            container
            rowSpacing={3}
            columnSpacing={0}
            justifyContent="center"
            alignItems="center"
            columns={12}
        >
            {mobileLogos.map(({ name, src }, j) => (
                <Grid
                    item
                    key={j}
                    xs={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <img src={src} alt={name} height="42" />
                </Grid>
            ))}
        </Grid>
    )
}

const LogosSection = () => {
    return (
        <>
            <RenderOnDesktop>
                <DesktopLogosSection />
            </RenderOnDesktop>
            <RenderOnMobile>
                <MobileLogosSection />
            </RenderOnMobile>
        </>
    )
}

export default LogosSection
