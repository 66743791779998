import React from 'react'
import { Chip } from 'lune-ui-lib'
import { SxProps } from '@mui/material'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'

const ForYourCustomers = ({ sx }: { sx?: SxProps }) => {
    return (
        <Chip
            sx={{ ...sx, maxWidth: 'max-content' }}
            label={'For your customers'}
            icon={<GroupOutlinedIcon />}
        />
    )
}

export default ForYourCustomers
