import * as React from 'react'
import Link from './Link'
import { StaticImage } from 'gatsby-plugin-image'

const Logo = () => {
    return (
        <Link to="/">
            <StaticImage
                loading={'eager'}
                placeholder={'none'}
                layout={`fixed`}
                width={104}
                height={32}
                alt={`Lune Logo`}
                src={'../images/Logo4x.png'}
            />
        </Link>
    )
}

export default Logo
