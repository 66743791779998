import * as React from 'react'
import StandardLayout from 'components/StandardLayout'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import ListItemText from '@mui/material/ListItemText'
import Link from 'components/Link'
import Logo from 'components/Logo'
import { LINKS } from '../constants'

const Footer = () => {
    return (
        <StandardLayout>
            <Box mb={10}>
                <div>
                    <Grid
                        container
                        spacing={{
                            xs: 1,
                            sm: 1,
                            md: 2,
                            lg: 3,
                            xl: 3,
                        }}
                        columns={{ xs: 4, sm: 12, md: 12, lg: 12, xl: 12 }}
                        justifyContent="center"
                        alignItems="flex-start"
                        height="100%"
                        style={{
                            marginTop: '0px',
                        }}
                    >
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <Stack
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={1}
                                pt={{
                                    xs: 0,
                                    sm: 3,
                                    md: 3,
                                    lg: 3,
                                    xl: 3,
                                }}
                            >
                                <Box mb={3}>
                                    <Logo />
                                </Box>
                                <Link to="/terms/" color={'Grey700'} variant="caption">
                                    Terms &amp; Conditions
                                </Link>
                                <Link to="/privacy/" color={'Grey700'} variant="caption">
                                    Privacy Policy
                                </Link>
                                <Typography color={'Grey700'} variant="caption" component="span">
                                    &copy; {new Date().getFullYear()} Lune Climate Ltd. All rights
                                    reserved.
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={4} sm={8} md={8} lg={8} xl={8}>
                            <Grid
                                container
                                spacing={{
                                    xs: 4,
                                    sm: 3,
                                    md: 3,
                                    lg: 3,
                                    xl: 3,
                                }}
                                columns={{ xs: 4, sm: 8, md: 8, lg: 8, xl: 8 }}
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                height="100%"
                                style={{
                                    marginTop: '0px',
                                }}
                            >
                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                    <Box
                                        mb={{
                                            xs: 3,
                                            sm: 3,
                                            md: 3,
                                            lg: 3,
                                            xl: 3,
                                        }}
                                    >
                                        <Typography color={'Grey900'} variant="h6" component="span">
                                            Product
                                        </Typography>
                                    </Box>
                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="flex-start"
                                        spacing={1}
                                    >
                                        <ListItemText
                                            primary={
                                                <Link
                                                    to="/product/"
                                                    color={'Grey700'}
                                                    variant="body2"
                                                >
                                                    Product
                                                </Link>
                                            }
                                        />
                                        <ListItemText
                                            primary={
                                                <Link
                                                    to="/projects/"
                                                    color={'Grey700'}
                                                    variant="body2"
                                                >
                                                    Projects
                                                </Link>
                                            }
                                        />
                                        <ListItemText
                                            primary={
                                                <Link
                                                    to="https://docs.lune.co"
                                                    color={'Grey700'}
                                                    variant="body2"
                                                    newPage
                                                >
                                                    API docs
                                                </Link>
                                            }
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                    <Box
                                        mb={{
                                            xs: 3,
                                            sm: 3,
                                            md: 3,
                                            lg: 3,
                                            xl: 3,
                                        }}
                                    >
                                        <Typography color={'Grey900'} variant="h6" component="span">
                                            Use cases
                                        </Typography>
                                    </Box>
                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="flex-start"
                                        spacing={1}
                                    >
                                        <ListItemText
                                            primary={
                                                <Link
                                                    to="/buy-carbon-credits"
                                                    color={'Grey700'}
                                                    variant="body2"
                                                >
                                                    Buy carbon credits
                                                </Link>
                                            }
                                        />
                                        <ListItemText
                                            primary={
                                                <Link
                                                    to="/payments"
                                                    color={'Grey700'}
                                                    variant="body2"
                                                >
                                                    Payments
                                                </Link>
                                            }
                                        />
                                        <ListItemText
                                            primary={
                                                <Link
                                                    to="/fintech-and-banking"
                                                    color={'Grey700'}
                                                    variant="body2"
                                                >
                                                    Fintech & Banking
                                                </Link>
                                            }
                                        />
                                        <ListItemText
                                            primary={
                                                <Link
                                                    to="/logistics"
                                                    color={'Grey700'}
                                                    variant="body2"
                                                >
                                                    Logistics
                                                </Link>
                                            }
                                        />
                                        <ListItemText
                                            primary={
                                                <Link
                                                    to="/procurement"
                                                    color={'Grey700'}
                                                    variant="body2"
                                                >
                                                    Procurement
                                                </Link>
                                            }
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                    <Box
                                        mb={{
                                            xs: 3,
                                            sm: 3,
                                            md: 3,
                                            lg: 3,
                                            xl: 3,
                                        }}
                                    >
                                        <Typography color={'Grey900'} variant="h6" component="span">
                                            Company
                                        </Typography>
                                    </Box>
                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="flex-start"
                                        spacing={1}
                                    >
                                        <ListItemText
                                            primary={
                                                <Link
                                                    to="/about/"
                                                    color={'Grey700'}
                                                    variant="body2"
                                                >
                                                    About
                                                </Link>
                                            }
                                        />
                                        <ListItemText
                                            primary={
                                                <Link to="/blog/" color={'Grey700'} variant="body2">
                                                    Blog
                                                </Link>
                                            }
                                        />
                                        <ListItemText
                                            primary={
                                                <Link
                                                    to={LINKS.jobs}
                                                    color={'Grey700'}
                                                    variant="body2"
                                                    newPage
                                                >
                                                    Join us 🚀
                                                </Link>
                                            }
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                    <Box
                                        mb={{
                                            xs: 3,
                                            sm: 3,
                                            md: 3,
                                            lg: 3,
                                            xl: 3,
                                        }}
                                    >
                                        <Typography color={'Grey900'} variant="h6" component="span">
                                            Social
                                        </Typography>
                                    </Box>
                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="flex-start"
                                        spacing={1}
                                    >
                                        <ListItemText
                                            primary={
                                                <Link
                                                    to="https://twitter.com/luneclimate"
                                                    color={'Grey700'}
                                                    variant="body2"
                                                    newPage
                                                >
                                                    Twitter
                                                </Link>
                                            }
                                        />
                                        <ListItemText
                                            primary={
                                                <Link
                                                    to="https://www.linkedin.com/company/lune-climate"
                                                    color={'Grey700'}
                                                    variant="body2"
                                                    newPage
                                                >
                                                    Linkedin
                                                </Link>
                                            }
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Box>
        </StandardLayout>
    )
}

export default Footer
