import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'
import { Placeholder } from './OverflowImage'
import useIsMobile from 'hooks/useIsMobile'
import AnimatedMap, { AnimatedMapMarker } from './Maps/AnimatedMap'

const projects: AnimatedMapMarker[] = [
    // Inactive
    {
        title: 'Running Tide',
        lat: 64.373401,
        lng: -22.033189,
        subtitle: 'Ocean Carbon Removal',
        iconSrc:
            'https://assets.lune.co/projects/project-images-v1/running-tide/running-tide-logo.png',
    },
    {
        title: 'Ackron Mixed',
        lat: 58.542446,
        lng: -3.882732,
        subtitle: 'Reforesting the United Kingdom',
        iconSrc:
            'https://assets.lune.co/projects/project-images-v1/ackron-mixed/ackron-mixed-logo.png',
    },
    {
        title: 'Tewo',
        lat: 60.46953,
        lng: 11.044431,
        subtitle: 'Net-Negative Materials',
        iconSrc: 'https://assets.lune.co/projects/project-images-v1/tewo/tewo-logo.png',
    },
    {
        title: 'Ekovilla',
        lat: 65.119856,
        lng: 25.744181,
        subtitle: 'Net-Negative Materials',
        iconSrc: 'https://assets.lune.co/projects/project-images-v1/ekovilla/ekovilla-logo.png',
    },
    {
        title: 'Delta Blue Carbon',
        lat: 24.568783,
        lng: 67.325728,
        subtitle: 'Restoring Coastal Wetlands',
        iconSrc:
            'https://assets.lune.co/projects/project-images-v1/delta-blue-carbon/delta-blue-carbon-logo.png',
    },
    {
        title: 'Keo Seima',
        lat: 12.25746,
        lng: 106.733347,
        subtitle: 'Conserving forests in Asia',
        iconSrc: 'https://assets.lune.co/projects/project-images-v1/keo-seima/keo-seima-logo.png',
    },
    {
        title: 'Southern Cardamom',
        lat: 11.395283,
        lng: 103.607466,
        subtitle: 'Conserving forests in Asia',
        iconSrc:
            'https://assets.lune.co/projects/project-images-v1/southern-cardamom/southern-cardamom-logo.png',
    },
    {
        title: '1PointFive',
        lat: 32.168375,
        lng: -102.38083,
        subtitle: 'Direct Air Capture',
        iconSrc: 'https://assets.lune.co/projects/project-images-v1/1pointfive/1pointfive-logo.png',
    },
    {
        title: 'Phlogiston N₂O Reductions',
        lat: 30.594516,
        lng: -87.255742,
        subtitle: 'Abating Industrial Emissions',
        iconSrc:
            'https://assets.lune.co/projects/project-images-v1/phlogiston-n2o/phlogiston-n2o-logo.png',
    },
    {
        title: 'CarbonCure',
        lat: 44.716231,
        lng: -63.582313,
        subtitle: 'Concrete Mineralisation',
        iconSrc:
            'https://assets.lune.co/projects/project-images-v1/carbon-cure/carbon-cure-logo.png',
    },
    {
        title: 'Envira Amazonia',
        lat: -8.918098,
        lng: -70.196959,
        subtitle: 'Protecting forests in Latin America',
        iconSrc:
            'https://assets.lune.co/projects/project-images-v1/envira-amazonia/envira-amazonia-logo.png',
    },
    {
        title: 'Nordgau Carbon',
        lat: 49.508406,
        lng: 12.093561,
        subtitle: 'Biochar',
        iconSrc:
            'https://assets.lune.co/projects/project-images-v1/nordgau-carbon/nordgau-carbon-logo.png',
    },

    {
        title: 'Alto Mayo',
        lat: -5.87798,
        lng: -77.612352,
        subtitle: 'Protecting forests in Latin America',
        iconSrc: 'https://assets.lune.co/projects/project-images-v1/alto-mayo/alto-mayo-logo.png',
    },
    {
        title: 'Concosta',
        lat: 4.703078,
        lng: -77.222003,
        subtitle: 'Protecting forests in Latin America',
        iconSrc: 'https://assets.lune.co/projects/project-images-v1/concosta/concosta-logo.png',
    },
    {
        title: 'Kootznoowoo',
        lat: 55.207407,
        lng: -132.193756,
        subtitle: 'Forestry in North America',
        iconSrc:
            'https://assets.lune.co/projects/project-images-v1/kootznoowoo/kootznoowoo-anew-logo.png',
    },
    {
        title: 'Living Carbon: Idle Bottomland Reforestation',
        lat: 32.033208,
        lng: -82.157175,
        subtitle: 'Biotech-Enhanced Reforestation',
        iconSrc:
            'https://assets.lune.co/projects/project-images-v1/living-carbon/living-carbon-logo.png',
    },
    {
        title: 'Wakefield Biochar',
        lat: 30.831766,
        lng: -83.252187,
        subtitle: 'Biochar',
        iconSrc:
            'https://assets.lune.co/projects/project-images-v1/wakefield-biochar/wakefield-biochar-logo-text.png',
    },

    // Active
    {
        subtitle: 'Enhanced Rock Weathering',
        iconSrc: 'https://assets.lune.co/projects/project-images-v1/undo/undo-logo.png',
        lat: 56.533453,
        lng: -5.977094,
        title: 'UNDO',
        active: true,
    },
    {
        subtitle: 'European Reforestation',
        iconSrc: 'https://assets.lune.co/projects/ecotree.png',
        lat: 48.098309,
        lng: -3.505842,
        title: 'EcoTree',
        active: true,
    },
    {
        subtitle: 'Conserving forests in Asia',
        iconSrc: 'https://assets.lune.co/projects/project-images-v1/rimba-raya/rimba-raya-logo.png',
        lat: -3.182846,
        lng: 112.2951,
        title: 'Rimba Raya',
        active: true,
    },
    {
        subtitle: 'Bio-Oil Sequestration',
        iconSrc:
            'https://assets.lune.co/projects/project-images-v1/charm-industrial/charm-industrial-logo.png',
        lat: 37.671713,
        lng: -96.981268,
        title: 'Charm Industrial',
        active: true,
    },
    {
        subtitle: 'Protecting the Brazilian Amazon',
        iconSrc:
            'https://assets.lune.co/projects/project-images-v1/resex-rio-preto-jacunda/jacunda-logo.png',
        lat: -8.861572,
        lng: -62.292714,
        title: 'RESEX Rio Preto Jacundá',
        active: true,
    },
]

const MapOverflow = () => {
    const [overflow, setOverflow] = useState<number>(0)
    const [startAnimation, setStartAnimation] = useState<boolean>(false)
    const layoutRef = useRef<HTMLDivElement>(null)
    const isMobile = useIsMobile()

    const positionSx = {
        position: 'absolute',
        height: '100%',
        width: '100%',
        zIndex: 100,
        top: '0px',
        right: '0px',
    }

    useEffect(() => {
        function onWindowScroll() {
            if (layoutRef?.current && !startAnimation) {
                const rect = layoutRef.current.getBoundingClientRect()
                setStartAnimation(rect.top > 0 && rect.bottom <= window.innerHeight + 200)
            }
        }

        window.addEventListener('scroll', onWindowScroll)
        onWindowScroll()
        return () => window.removeEventListener('scroll', onWindowScroll)
    }, [])

    useEffect(() => {
        function onWindowResize() {
            if (layoutRef?.current && !startAnimation) {
                if (layoutRef?.current && !isMobile) {
                    setOverflow(layoutRef.current.getBoundingClientRect().left)
                }
            }
        }

        window.addEventListener('resize', onWindowResize)
        onWindowResize()
        return () => window.removeEventListener('resize', onWindowResize)
    }, [])

    return (
        <Box ref={layoutRef} sx={{ position: 'relative' }}>
            <Placeholder />
            <Box
                sx={{
                    height: '100%',
                    width: `calc(100% + ${overflow}px)`,
                    maxWidth: '1200px',
                    borderTopRightRadius: '40px',
                    borderBottomRightRadius: '40px',
                    overflow: 'hidden',
                    position: 'absolute',
                    right: '0',
                    left: 'auto',
                    top: 0,
                    zIndex: 100,
                    ...(overflow > 700 && {
                        borderRadius: '40px',
                    }),
                    ...(isMobile && {
                        borderRadius: '40px',
                        width: '100%',
                    }),
                }}
            >
                <Box sx={{ ...positionSx }}>
                    <AnimatedMap
                        disableMapInteraction={isMobile}
                        markers={projects}
                        mapboxAccessToken={process.env.GATSBY_MAPBOX_TOKEN}
                        initialPosition={{
                            latitude: 25.7465,
                            longitude: -30.4629,
                        }}
                        startAnimation={startAnimation}
                        zoom={1}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default MapOverflow
