import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import useIsMobile from '../hooks/useIsMobile'
import { graphql, useStaticQuery } from 'gatsby'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Button from 'components/Button'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import { HUBSPOT_PORTAL_ID, HUBSPOT_FORM_IDS } from '../constants'
import { useDebounce } from 'use-debounce'
import BackgroundImageWithPlaceholder from 'components/BackgroundImageWithPlaceholder'

const ContactForm = ({
    hubspotFormName,
    headers,
    buttonText,
    legalConsent,
}: {
    hubspotFormName?: string
    headers?: string[]
    buttonText?: string
    legalConsent: 'lead' | 'consent'
}) => {
    const [name, setName] = useState<string>()
    const [email, setEmail] = useState<string>()
    const [company, setCompany] = useState<string>()
    const [nameError, setNameError] = useState<boolean>()
    const [emailError, setEmailError] = useState<string>()
    const [companyError, setCompanyError] = useState<string>()
    const [debouncedEmail] = useDebounce(email, 1000)
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>()

    const hubspotFormId = HUBSPOT_FORM_IDS[hubspotFormName] ?? HUBSPOT_FORM_IDS.other
    const isMobile = useIsMobile()

    headers = headers ?? [`Let's create some climate impact!`]
    buttonText = buttonText ?? 'Contact Sales'

    const backgroundImageStaticQuery = useStaticQuery(graphql`
        query {
            desktop: file(relativePath: { eq: "BlueGradient.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    const isValidEmail = (e) => {
        const regexp = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)
        return !!e && regexp.test(e)
    }

    const updateName = (newName) => {
        setName(newName)
        setNameError(!newName)
    }
    const updateEmail = (newEmail) => {
        setEmail(newEmail)
    }

    useEffect(() => {
        if (email === undefined) {
            return
        }
        setEmailError(!isValidEmail(email))
    }, [debouncedEmail])

    const updateCompany = (newCompany) => {
        setCompany(newCompany)
        setCompanyError(!newCompany)
    }

    const isValid = () => {
        return !!name && !!company && isValidEmail(email)
    }

    const legalConsentOptions =
        legalConsent === 'lead'
            ? {
                  legitimateInterest: {
                      value: true,
                      subscriptionTypeId: 999,
                      legalBasis: 'LEAD',
                      text: headers.join(' '),
                  },
              }
            : {
                  consent: {
                      consentToProcess: true,
                      text: headers.join(' '),
                      communications: [
                          {
                              value: true,
                              subscriptionTypeId: 999,
                              text: headers.join(' '),
                          },
                      ],
                  },
              }

    const toHubspot = () => {
        const words = name.split(' ')
        const firstname = words[0]
        const lastname = words.slice(1).join(' ')

        console.log(`firstname:${firstname}`)
        console.log(`lastname: ${lastname}`)

        const payload = {
            fields: [
                {
                    objectTypeId: '0-1',
                    name: 'email',
                    value: email,
                },
                {
                    objectTypeId: '0-1',
                    name: 'firstname',
                    value: firstname,
                },
                {
                    objectTypeId: '0-1',
                    name: 'lastname',
                    value: lastname,
                },
                {
                    objectTypeId: '0-1',
                    name: 'company',
                    value: company,
                },
            ],
            legalConsentOptions: {
                ...legalConsentOptions,
            },
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        }
        fetch(
            `https://api.hsforms.com/submissions/v3/integration/submit/${HUBSPOT_PORTAL_ID}/${hubspotFormId}`,
            requestOptions,
        )
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                if (data.status === 'error') {
                    setSuccess(false)
                    return
                }
                setSuccess(true)
            })
    }

    const onSubmit = (event) => {
        try {
            setSubmitting(true)
            event.preventDefault()

            console.log(`name:     ${name}`)
            console.log(`email:    ${email}`)
            console.log(`company : ${company}`)

            if (!isValid()) {
                setNameError(!name)
                setEmailError(!isValidEmail(email))
                setCompanyError(!company)
                return
            }

            toHubspot()
            console.log(`Send to ${hubspotFormId}`)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <BackgroundImageWithPlaceholder
            placeholder={'https://assets.lune.co/lune-co/placeholders/testimonial.svg'}
            backgroundImageStaticQuery={backgroundImageStaticQuery}
            style={{
                height: isMobile ? `initial` : `267px`,
                borderRadius: `48px`,
                overflow: 'hidden',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    height: '100%',
                }}
            >
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={{
                        xs: 3,
                        sm: 4,
                        md: 4,
                        lg: 4,
                        xl: 4,
                    }}
                    py={8}
                    px={{
                        xs: 1,
                        sm: 0,
                        md: 0,
                        lg: 0,
                        xl: 0,
                    }}
                    sx={{
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            display: 'inline',
                            textAlign: 'center',
                        }}
                        px={{
                            xs: 2,
                            sm: 10,
                            md: 10,
                            lg: 10,
                            xl: 10,
                        }}
                    >
                        {isMobile ? (
                            <>
                                <Typography
                                    color={'Grey900'}
                                    variant={isMobile ? 'h5' : 'h4'}
                                    component="div"
                                    align="center"
                                >
                                    {headers[0]}
                                </Typography>
                                <Typography
                                    color={'Grey900'}
                                    variant={isMobile ? 'h5' : 'h4'}
                                    component="span"
                                    align="center"
                                >
                                    {headers[1]}
                                </Typography>
                            </>
                        ) : (
                            <Typography
                                color={'Grey900'}
                                variant={isMobile ? 'h5' : 'h4'}
                                component="span"
                                align="center"
                            >
                                {headers.join(' ')}
                            </Typography>
                        )}
                    </Box>
                    {success !== undefined ? (
                        <>
                            {success ? (
                                <Typography color={'Grey900'} variant={'body1'} align="center">
                                    Thanks for your interest, we’ll be in touch!
                                    <br />
                                    In the mean time,{' '}
                                    <Link
                                        variant={'body1'}
                                        color={'Grey900'}
                                        href="https://dashboard.lune.co/signup"
                                        onClick={() => gtag('event', 'request_access', {})}
                                        sx={{
                                            display: 'inline-block',
                                        }}
                                    >
                                        request access to Lune
                                    </Link>
                                    {` `}to make your first carbon purchase and explore the product
                                    further.
                                </Typography>
                            ) : (
                                <Typography color={'Grey900'} variant={'body1'} component="div">
                                    Sorry, there has been an error. Please try again later.`
                                </Typography>
                            )}
                        </>
                    ) : (
                        <form onSubmit={onSubmit}>
                            <Grid
                                container
                                spacing={1}
                                columns={{ xs: 4, sm: 12, md: 12, lg: 12, xl: 12 }}
                                px={{
                                    xs: 1,
                                    sm: 0,
                                    md: 0,
                                    lg: 0,
                                    xl: 0,
                                }}
                                direction="row"
                                justifyContent="center"
                                alignItems="stretch"
                            >
                                <Grid item xs={0} sm={1} md={1} lg={1} xl={1}></Grid>
                                <Grid
                                    item
                                    xs={0}
                                    sm={10}
                                    md={10}
                                    lg={10}
                                    xl={10}
                                    pr={{ xs: 1, sm: 0, md: 0, lg: 0, xl: 0 }}
                                >
                                    <Grid
                                        container
                                        spacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}
                                        columns={{ xs: 1, sm: 4, md: 4, lg: 4, xl: 4 }}
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="stretch"
                                    >
                                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                            <TextField
                                                error={nameError}
                                                placeholder="Name"
                                                name="name"
                                                size="small"
                                                sx={{ width: '100%' }}
                                                onChange={(e) => updateName(e.target.value)}
                                                inputMode={`text`}
                                            />
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                            <TextField
                                                error={emailError}
                                                placeholder="Work email"
                                                name="email"
                                                size="small"
                                                sx={{ width: '100%' }}
                                                onChange={(e) =>
                                                    updateEmail(e.target.value?.toLowerCase())
                                                }
                                                inputMode={`email`}
                                                value={email}
                                            />
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                            <TextField
                                                error={companyError}
                                                placeholder="Company"
                                                name="company"
                                                size="small"
                                                sx={{ width: '100%' }}
                                                onChange={(e) => updateCompany(e.target.value)}
                                                inputMode={`text`}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            sm={1}
                                            md={1}
                                            lg={1}
                                            xl={1}
                                            sx={{ width: '100%' }}
                                        >
                                            <Button
                                                disabled={submitting}
                                                type="submit"
                                                variant="contained"
                                                sx={{ width: '100%', height: '42px' }}
                                                startIcon={
                                                    <ArrowCircleRightIcon
                                                        sx={{
                                                            borderRadius: `40px`,
                                                            backgroundColor: `Grey900`,
                                                            color: `White`,
                                                        }}
                                                    />
                                                }
                                            >
                                                {buttonText}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={0} sm={1} md={1} lg={1} xl={1}></Grid>
                            </Grid>
                        </form>
                    )}
                </Stack>
            </div>
        </BackgroundImageWithPlaceholder>
    )
}

export default ContactForm
