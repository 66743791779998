import * as React from 'react'
import Box from '@mui/material/Box'
import XlContainer from '../components/XlContainer'
import StandardLayout from '../components/StandardLayout'
import ContactForm from 'views/ContactForm'
import Footer from 'views/Footer'
import useLogBreakpoints from 'hooks/useLogBreakpoints'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { StaticImage } from 'gatsby-plugin-image'
import useIsMobile from 'hooks/useIsMobile'
import HeroHeadingProduct from '../views/HeroHeadingProduct'
import MessagingSection from 'views/MessagingSection'
import TakeControlOffsettingSection from 'views/TakeControlOffsettingSection'
import LunePaySection from 'views/LunePaySection'
import ShareImpactSection from 'views/ShareImpactSection'
import { graphql, useStaticQuery } from 'gatsby'
import Testimonial from '../components/Testimonial'
import GreyGradient from 'components/GreyGradient'
import Page, { PageBody } from '../components/Page'
import { RenderOnDesktop, RenderOnMobile } from '../components/DeviceUtils'
import ProductAPIUseCasesSection from 'views/ProductAPIUseCasesSection'

const stackSpacing = {
    xs: 10,
    sm: 15,
    md: 15,
    lg: 15,
    xl: 15,
}

const ProductPage = ({ location }: { location?: string }) => {
    useLogBreakpoints()
    const isMobile = useIsMobile()

    return (
        <Page location={location} title="Lune - Product">
            <PageBody>
                <Stack spacing={stackSpacing} sx={{ overflow: 'hidden' }}>
                    <Stack spacing={stackSpacing}>
                        <GreyGradient
                            pb={{
                                xs: 10,
                                sm: 15,
                                md: 15,
                                lg: 15,
                                xl: 15,
                            }}
                            hide={isMobile}
                        >
                            <XlContainer>
                                <Stack
                                    spacing={stackSpacing}
                                    sx={{
                                        mt: {
                                            xs: 9,
                                            sm: 15,
                                            md: 15,
                                            lg: 15,
                                            xl: 15,
                                        },
                                    }}
                                >
                                    <StandardLayout>
                                        <RenderOnMobile>
                                            <Typography
                                                variant={`h2`}
                                                component="div"
                                                sx={{ textAlign: `left` }}
                                            >
                                                Scalable climate impact for you and your customers
                                            </Typography>
                                        </RenderOnMobile>
                                        <RenderOnDesktop>
                                            <Typography
                                                variant={`h2`}
                                                component="div"
                                                sx={{ textAlign: `center` }}
                                            >
                                                Scalable climate impact
                                            </Typography>
                                            <Typography
                                                variant={`h2`}
                                                component="div"
                                                sx={{ textAlign: `center` }}
                                            >
                                                for you and your customers
                                            </Typography>
                                        </RenderOnDesktop>
                                    </StandardLayout>

                                    {!isMobile && (
                                        <StandardLayout>
                                            <HeroHeadingProduct />
                                        </StandardLayout>
                                    )}
                                </Stack>
                            </XlContainer>
                        </GreyGradient>
                    </Stack>
                    <Box sx={{ width: '100%' }}>
                        <XlContainer>
                            <Stack
                                spacing={{
                                    xs: 5,
                                    sm: 15,
                                    md: 15,
                                    lg: 15,
                                    xl: 15,
                                }}
                            >
                                <GreyGradient radial>
                                    <StandardLayout
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            pb: 3,
                                        }}
                                    >
                                        <Typography
                                            component="div"
                                            variant={isMobile ? 'h4' : 'h3'}
                                            sx={{ textAlign: 'center' }}
                                        >
                                            Fund carbon projects
                                        </Typography>
                                    </StandardLayout>
                                </GreyGradient>

                                <GreyGradient hide={!isMobile}>
                                    <StandardLayout>
                                        <TakeControlOffsettingSection />
                                    </StandardLayout>
                                </GreyGradient>
                            </Stack>

                            <Stack
                                spacing={{
                                    xs: 5,
                                    sm: 15,
                                    md: 15,
                                    lg: 15,
                                    xl: 15,
                                }}
                                mt={{
                                    xs: 14,
                                    sm: 24,
                                    md: 24,
                                    lg: 24,
                                    xl: 24,
                                }}
                            >
                                <GreyGradient radial>
                                    <StandardLayout
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            pb: 3,
                                        }}
                                    >
                                        <Typography
                                            component="div"
                                            variant={isMobile ? 'h4' : 'h3'}
                                            sx={{ textAlign: 'center' }}
                                        >
                                            Embed climate impact using Lune’s API
                                        </Typography>
                                    </StandardLayout>
                                </GreyGradient>

                                <GreyGradient hide={!isMobile}>
                                    <StandardLayout>
                                        <ProductAPIUseCasesSection />
                                    </StandardLayout>
                                </GreyGradient>

                                <GreyGradient hide={!isMobile}>
                                    <StandardLayout>
                                        <LunePaySection />
                                    </StandardLayout>
                                </GreyGradient>
                            </Stack>

                            <Stack
                                spacing={{
                                    xs: 5,
                                    sm: 15,
                                    md: 15,
                                    lg: 15,
                                    xl: 15,
                                }}
                                mt={{
                                    xs: 14,
                                    sm: 24,
                                    md: 24,
                                    lg: 24,
                                    xl: 24,
                                }}
                            >
                                <GreyGradient radial>
                                    <StandardLayout
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                            pb: 3,
                                        }}
                                    >
                                        <Typography
                                            component="div"
                                            variant={isMobile ? 'h4' : 'h3'}
                                            sx={{ textAlign: 'center' }}
                                        >
                                            Engage your customers
                                        </Typography>
                                    </StandardLayout>
                                </GreyGradient>

                                <GreyGradient hide={!isMobile}>
                                    <StandardLayout>
                                        <ShareImpactSection />
                                    </StandardLayout>
                                </GreyGradient>

                                <GreyGradient hide={!isMobile}>
                                    <StandardLayout>
                                        <MessagingSection />
                                    </StandardLayout>
                                </GreyGradient>
                            </Stack>

                            <Stack
                                spacing={stackSpacing}
                                mt={{
                                    xs: 14,
                                    sm: 24,
                                    md: 24,
                                    lg: 24,
                                    xl: 24,
                                }}
                            >
                                <StandardLayout>
                                    <Testimonial
                                        text={`Lune offers an integrated solution that makes it very easy for our customers to purchase trusted, verified carbon credits without needing to do their own in-depth research – all at a decent price.`}
                                        profileName={'Konstantinos Kouzelis'}
                                        profileTitle={'CEO at Coolset'}
                                        profileAvatar={
                                            <StaticImage
                                                src="../images/KostantinosKouzelis.png"
                                                alt={`Konstantinos Kouzelis`}
                                            />
                                        }
                                        backgroundImageStaticQuery={useStaticQuery(graphql`
                                            query {
                                                desktop: file(
                                                    relativePath: {
                                                        eq: "TestimonialPinkForest.png"
                                                    }
                                                ) {
                                                    childImageSharp {
                                                        fluid(quality: 100, maxWidth: 1920) {
                                                            ...GatsbyImageSharpFluid_withWebp
                                                        }
                                                    }
                                                }
                                            }
                                        `)}
                                    />
                                </StandardLayout>

                                <StandardLayout>
                                    <ContactForm hubspotFormName={'product'} />
                                </StandardLayout>

                                <Footer />
                            </Stack>
                        </XlContainer>
                    </Box>
                </Stack>
            </PageBody>
        </Page>
    )
}

export default ProductPage
