import * as React from 'react'
import CTAButton from '../../components/CTAButton'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import { RenderOnDesktop, RenderOnMobile } from '../../components/DeviceUtils'
import Typography from '@mui/material/Typography'
import { Container, Stack, Box } from '@mui/material'
import HeroItems, { HeroItemProps, heroItems } from './HeroHeadingHomeItems'
import { useState } from 'react'
import HeroMobileItems from './HeroHeadingHomeItemsMobile'

const HeroHeadingHome = () => {
    const { palette } = useTheme()
    const MUIBackgroundImage = styled(BackgroundImage)({
        borderRadius: `48px`,
        overflow: 'hidden',
        height: '100%',
        filter: `saturate(1.2)`,
    })

    const data = useStaticQuery(graphql`
        query {
            background: file(relativePath: { eq: "HeroHeadingHomeBackground.png" }) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    const [hoveredItem, setHoveredItem] = useState<HeroItemProps>()

    return (
        <>
            <RenderOnDesktop>
                <Container
                    maxWidth="false"
                    sx={{
                        paddingLeft: '0px !important',
                        paddingRight: '0px !important',
                        maxWidth: '1408px',
                        borderRadius: '48px',
                        background: palette.Grey100,
                        overflow: 'hidden',
                        position: 'relative',
                    }}
                >
                    {heroItems.map((item) => (
                        <Box
                            key={`item-background-${item.id}`}
                            className={`${hoveredItem?.id === item.id ? 'active-bg' : ''}`}
                            sx={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                height: '100%',
                                backgroundImage: item.background,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                transition: 'opacity .3s ease-in-out',
                                opacity: 0,
                                '&.active-bg': {
                                    opacity: 1,
                                },
                            }}
                        />
                    ))}
                    <Box
                        pt={8}
                        sx={{
                            position: 'relative',
                            '.MuiTypography-root': {
                                transition: 'color .3s ease-in-out',
                                color: hoveredItem?.darkMode ? palette.White : palette.Grey900,
                            },
                        }}
                    >
                        <Typography variant={`h3`} component="div" sx={{ textAlign: `center` }}>
                            Create climate impact
                        </Typography>
                        <Typography variant={`h3`} component="div" sx={{ textAlign: `center` }}>
                            you can feel proud of
                        </Typography>
                        <Typography
                            variant={`body1`}
                            component="div"
                            mt={4}
                            sx={{
                                textAlign: `center`,
                                maxWidth: '800px',
                                marginRight: 'auto',
                                marginLeft: 'auto',
                            }}
                        >
                            Brand-conscious companies use Lune’s platform and API to calculate Scope
                            3 emissions, create positive impact through vetted climate projects, and
                            future-proof business growth.
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            mt: 8,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                maxWidth: '1200px',
                                width: '100%',
                            }}
                        >
                            <HeroItems onItemHover={setHoveredItem} />
                        </Box>
                    </Box>
                </Container>
            </RenderOnDesktop>
            <RenderOnMobile>
                <MUIBackgroundImage fluid={data.background.childImageSharp.fluid}>
                    <Box m={2}>
                        <Stack mt={6} spacing={4} direction="column">
                            <Typography variant={`h4`} component="div" sx={{ textAlign: `center` }}>
                                Create climate impact you can feel proud of
                            </Typography>
                            <Typography
                                variant={`body1`}
                                component="div"
                                sx={{ textAlign: `center` }}
                            >
                                Brand-conscious companies use Lune’s platform and API to calculate
                                Scope 3 emissions, create positive impact through vetted climate
                                projects, and future-proof business growth.
                            </Typography>
                            <Box
                                sx={{
                                    filter: `drop-shadow(0 2rem 1rem rgba(0,100,100,0.2))`,
                                }}
                            >
                                <HeroMobileItems />
                            </Box>
                            <CTAButton
                                text={'Request access'}
                                onClick={() => {
                                    gtag('event', 'request_access', {})
                                    navigate('https://dashboard.lune.co/signup', { replace: false })
                                }}
                            />
                        </Stack>
                    </Box>
                </MUIBackgroundImage>
            </RenderOnMobile>
        </>
    )
}

export default HeroHeadingHome
