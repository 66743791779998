import * as React from 'react'
import XlContainer from 'components/XlContainer'
import StandardLayout from 'components/StandardLayout'
import Stack from '@mui/material/Stack'
import { RenderOnDesktop, RenderOnMobile } from 'components/DeviceUtils'
import Typography from '@mui/material/Typography'
import CustomerBanner from 'components/CustomerBanner'
import { StaticImage } from 'gatsby-plugin-image'
import GreyGradient from 'components/GreyGradient'
import useIsMobile from 'hooks/useIsMobile'
import PaymentsTabSection from 'views/Customers/Sections/PaymentsAndBanking/PaymentsTabSection'

const stackSpacing = {
    xs: 10,
    sm: 15,
    md: 15,
    lg: 15,
    xl: 15,
}

const PaymentsAndBanking = () => {
    const isMobile = useIsMobile()
    return (
        <>
            <XlContainer>
                <Stack
                    spacing={stackSpacing}
                    sx={{
                        pt: {
                            xs: 9,
                            sm: 15,
                            md: 15,
                            lg: 15,
                            xl: 15,
                        },
                    }}
                >
                    <GreyGradient radial>
                        <StandardLayout sx={{ pb: 3 }}>
                            <RenderOnMobile>
                                <Typography
                                    variant={`h3`}
                                    component="div"
                                    sx={{ textAlign: `left` }}
                                >
                                    Creating a sustainable future through fintech & payments
                                </Typography>
                            </RenderOnMobile>
                            <RenderOnDesktop>
                                <Typography
                                    variant={`h3`}
                                    component="div"
                                    sx={{ textAlign: `center` }}
                                >
                                    Creating a sustainable future
                                </Typography>
                                <Typography
                                    variant={`h3`}
                                    component="div"
                                    sx={{ textAlign: `center` }}
                                >
                                    through fintech & payments
                                </Typography>
                            </RenderOnDesktop>
                        </StandardLayout>
                    </GreyGradient>
                </Stack>
            </XlContainer>
            <XlContainer>
                <Stack
                    spacing={stackSpacing}
                    sx={{
                        pt: {
                            xs: 9,
                            sm: 15,
                            md: 15,
                            lg: 15,
                            xl: 15,
                        },
                    }}
                >
                    <GreyGradient hide={!isMobile}>
                        <StandardLayout sx={{ py: isMobile ? 0 : 10 }}>
                            <PaymentsTabSection />
                        </StandardLayout>
                    </GreyGradient>
                    <StandardLayout>
                        <CustomerBanner
                            image={
                                <StaticImage
                                    placeholder={'dominantColor'}
                                    width={360}
                                    height={440}
                                    layout={'fixed'}
                                    src={'../../../../images/customerBanners/visa-desktop.png'}
                                    alt={'building'}
                                    style={{ borderRadius: '32px' }}
                                />
                            }
                            mobileImage={
                                <StaticImage
                                    placeholder={'dominantColor'}
                                    width={360}
                                    height={440}
                                    layout={'fixed'}
                                    src={'../../../../images/customerBanners/visa-mobile.png'}
                                    alt={'building'}
                                    style={{ borderRadius: '32px' }}
                                />
                            }
                            customerLogo={'https://assets.lune.co/lune-co/tabs-logos/visa.png'}
                            text="Enabling exponential climate impact through millions of Visa customers and partners"
                        />
                    </StandardLayout>
                    <StandardLayout>
                        <CustomerBanner
                            image={
                                <StaticImage
                                    placeholder={'dominantColor'}
                                    width={360}
                                    height={440}
                                    layout={'fixed'}
                                    src={'../../../../images/customerBanners/payhawk-desktop.png'}
                                    alt={'building'}
                                    style={{ borderRadius: '32px' }}
                                />
                            }
                            mobileImage={
                                <StaticImage
                                    placeholder={'dominantColor'}
                                    width={360}
                                    height={440}
                                    layout={'fixed'}
                                    src={'../../../../images/customerBanners/payhawk-mobile.png'}
                                    alt={'building'}
                                    style={{ borderRadius: '32px' }}
                                />
                            }
                            customerLogo={'https://assets.lune.co/lune-co/tabs-logos/payhawk.png'}
                            text="85% of customers want to see emissions visibility within their spending platform"
                        />
                    </StandardLayout>
                </Stack>
            </XlContainer>
        </>
    )
}

export default PaymentsAndBanking
