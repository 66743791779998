import * as React from 'react'
import StaticImageWrapper from '../components/StaticImageWrapper'
import { StaticImage } from 'gatsby-plugin-image'

const QualityImageWithPlaceholder = () => {
    return (
        <StaticImageWrapper
            placeholder={'https://assets.lune.co/lune-co/placeholders/home-techteam-projects.svg'}
        >
            <StaticImage
                placeholder={'dominantColor'}
                src={'../images/Quality.png'}
                alt={'Quality over quantity'}
                layout="fixed"
                objectPosition={'0% 0%'}
                objectFit={'contain'}
                quality={100}
                style={{ width: 'unset', aspectRatio: '2728 / 1600', height: '100%' }}
            />
        </StaticImageWrapper>
    )
}

export default QualityImageWithPlaceholder
