import Box from '@mui/material/Box'
import React from 'react'

export const RenderOnMobile = ({ children }: { children: React.ReactNode }) => (
    <Box
        component={`span`}
        sx={{
            display: {
                xs: 'initial',
                sm: 'none',
                md: 'none',
                lg: 'none',
                xl: 'none',
            },
        }}
    >
        {children}
    </Box>
)

export const RenderOnSmallScreen = ({ children }: { children: React.ReactNode }) => (
    <Box
        component={`span`}
        sx={{
            display: {
                xs: 'initial',
                sm: 'initial',
                md: 'none',
                lg: 'none',
                xl: 'none',
            },
        }}
    >
        {children}
    </Box>
)

export const RenderOnDesktop = ({ children }: { children: React.ReactNode }) => (
    <Box
        component={`span`}
        sx={{
            display: {
                xs: 'none',
                sm: 'initial',
                md: 'initial',
                lg: 'initial',
                xl: 'initial',
            },
        }}
    >
        {children}
    </Box>
)
