import React, { useEffect, useRef } from 'react'
import lottie from 'lottie-web'

interface LottieProps {
    path: string
    width?: number
    height?: number
    style?: any
    effects?: (anim: AminationItem) => void
    autoplay?: boolean
    loop?: boolean
    rendererSettings?: any
}

export const Lottie = ({ style, effects, rendererSettings, autoplay, loop, path }: LottieProps) => {
    const element = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (element.current) {
            const anim = lottie.loadAnimation({
                path,
                container: element.current,
                autoplay: autoplay ?? true,
                render: 'svg',
                loop: loop ?? true,
                ...(rendererSettings ? rendererSettings : {}),
            })

            if (effects) {
                effects(anim)
            }

            return () => anim.destroy()
        }
    }, [path])

    return <div {...(style ? { style } : {})} ref={element}></div>
}
