import TwoColumnLayout, { ColumnWrapOrder } from 'components/TwoColumnLayout'
import SectionBody1 from 'components/SectionBody1'
import H3 from 'components/H3'
import React from 'react'
import OverflowImage from 'components/OverflowImage'
import StaticImageWrapper from '../components/StaticImageWrapper'
import { StaticImage } from 'gatsby-plugin-image'

const CoBenefitsSection = () => {
    return (
        <TwoColumnLayout leftCol={5} rightCol={6} columnWrapOrderOnMobile={ColumnWrapOrder.NORMAL}>
            <OverflowImage float="right">
                <StaticImageWrapper
                    placeholder={
                        'https://assets.lune.co/lune-co/placeholders/projects-cobenefits.svg'
                    }
                >
                    <StaticImage
                        placeholder={'dominantColor'}
                        src={'../images/SDGs.png'}
                        alt={'UN SDGs'}
                        layout="fixed"
                        objectPosition={'100% 0%'}
                        objectFit={'contain'}
                        quality={100}
                        style={{
                            position: 'absolute',
                            right: '0px',
                            width: 'unset',
                            aspectRatio: '1504 / 1600',
                            height: '100%',
                        }}
                    />
                </StaticImageWrapper>
            </OverflowImage>

            <>
                <H3>Important co-benefits beyond CO₂</H3>
                <SectionBody1>
                    Removing and reducing CO₂ is priority #1 for measuring climate impact. However,
                    quality projects go beyond and support important co-benefits, such as{' '}
                    <strong>
                        improved biodiversity, protection of endangered species, and better jobs and
                        education in local communities.
                    </strong>
                </SectionBody1>
            </>
        </TwoColumnLayout>
    )
}

export default CoBenefitsSection
