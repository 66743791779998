import Box from '@mui/material/Box'
import Logo from '../../components/Logo'
import NavItem from './NavItem'
import UseCasesDropDownMenu from './UseCasesDropDownMenu'
import CompanyDropDownMenu from './CompanyDropDownMenu'
import LoginBtn from './LoginButton'
import CTAButton from '../../components/CTAButton'
import { navigate } from 'gatsby'
import React, { useRef, useCallback, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'

const DesktopNavBar = ({
    backgroundColor: initialBackgroundColor,
    onBackgroundChange,
}: {
    backgroundColor?: string
    onBackgroundChange?: (color: string) => void
}) => {
    const { palette } = useTheme()
    const ref = useRef()

    const changeBackground = useCallback(() => {
        const navHeight = ref?.current?.clientHeight
        const color =
            window.scrollY > navHeight ? palette.White : initialBackgroundColor ?? palette.White

        if (onBackgroundChange) {
            onBackgroundChange(color)
        }
    }, [])

    if (initialBackgroundColor !== palette.White) {
        useEffect(() => {
            window.addEventListener('scroll', changeBackground)
            return () => window.removeEventListener('scroll', changeBackground)
        }, [])
    }

    return (
        <Box
            ref={ref}
            display={`flex`}
            justifyContent={`space-between`}
            alignItems={`center`}
            height={`100%`}
        >
            <Box display={`flex`} alignItems={`center`}>
                <Box
                    width={104}
                    height={32}
                    sx={{
                        mr: {
                            xs: 0,
                            sm: 1,
                            md: 2.5,
                            lg: 2.5,
                            xl: 2.5,
                        },
                        display: {
                            xs: `initial`,
                            sm: `none`,
                            md: `initial`,
                            lg: `initial`,
                            xl: `initial`,
                        },
                    }}
                >
                    <Logo />
                </Box>
                <NavItem to={`/product/`} text={`Product`} />
                <UseCasesDropDownMenu />
                <NavItem to={`/projects/`} text={`Projects`} />
                <NavItem to={`/customers/`} text={`Customers`} />
                <CompanyDropDownMenu />
            </Box>
            <Box display={`flex`} alignItems={`center`}>
                <LoginBtn />
                <CTAButton
                    text={`Request access`}
                    onClick={() => {
                        gtag('event', 'request_access', {})
                        navigate('https://dashboard.lune.co/signup', { replace: false })
                    }}
                />
            </Box>
        </Box>
    )
}

export default DesktopNavBar
