import React from 'react'
import { Link as GLink } from 'gatsby'
import { styled, useTheme } from '@mui/material/styles'

function isExternal(url): boolean {
    return url.startsWith('http')
}

const Link = ({
    color,
    variant,
    to,
    children,
    sx,
    newPage,
}: {
    color?: string
    variant?: string
    to: string
    children: React.ReactNode
    sx?: any
    newPage?: boolean
}) => {
    const theme = useTheme()
    const styles = {
        ...(variant ? theme.typography[variant] : {}),
        ...(color ? { color: theme.palette[color] } : {}),
        ...(sx ? sx : {}),
    }

    const external = isExternal(to)

    const StyledLink = styled(external ? 'a' : GLink)({
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
        ...styles,
    })

    const props = {
        ...(external ? { href: to } : { to }),
        ...(newPage ? { target: '_blank' } : {}),
    }

    return <StyledLink {...props}>{children}</StyledLink>
}

export default Link
