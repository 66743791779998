import * as React from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

const TextButton = ({
    text,
    icon,
    to,
    newPage,
}: {
    text: string
    icon: React.ReactNode
    to: string
    newPage?: boolean
}) => {
    return (
        <Button
            href={to}
            variant="text"
            startIcon={icon}
            sx={{
                color: `Grey900`,
            }}
            {...(newPage ? { target: '_blank' } : {})}
        >
            <Typography variant="button" fontWeight="fontWeightMedium">
                {text}
            </Typography>
        </Button>
    )
}

export default TextButton
