import { PrismicRichText } from '@prismicio/react'
import StandardLayout from 'components/StandardLayout'
import StyledRichText from 'components/StyledRichText'
import * as React from 'react'

export const Content = ({
    slice,
}: {
    slice: {
        primary: {
            content: {
                richText: any
            }
        }
    }
}) => {
    return (
        <StandardLayout
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <StyledRichText
                sx={{
                    maxWidth: '720px',
                }}
            >
                <PrismicRichText field={slice.primary.content.richText} />
            </StyledRichText>
        </StandardLayout>
    )
}
