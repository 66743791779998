import React from 'react'
import MobileSubNav from './MobileSubNav'
import DesktopSubNav from './DesktopSubNav'
import { RenderOnDesktop, RenderOnSmallScreen } from 'components/DeviceUtils'
import { useTheme } from '@mui/material/styles'

export const desktopNavItemsMxSpacing = {
    xs: 0,
    sm: 0,
    md: 0,
    lg: 0,
    xl: 0,
}

const SubNavBar = ({
    backgroundColor,
    onBackgroundChange,
}: {
    backgroundColor?: string
    onBackgroundChange?: (color: string) => void
}) => {
    const { palette } = useTheme()
    return (
        <>
            <RenderOnSmallScreen>
                <MobileSubNav />
            </RenderOnSmallScreen>
            <RenderOnDesktop>
                <DesktopSubNav
                    backgroundColor={backgroundColor ?? palette.White}
                    onBackgroundChange={onBackgroundChange}
                />
            </RenderOnDesktop>
        </>
    )
}

export default SubNavBar
