import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import QuoteCard from 'components/QuoteCard'

const VestreQuoteCard = () => {
    return (
        <QuoteCard
            text={
                'It’s vital that businesses reduce emissions through Value Chain Interventions. Even in a Net Zero future, there will be areas that are much harder or (almost) impossible to completely abate, and so carbon removals are an important part of the solution for businesses, to address those areas.'
            }
            profileName={'Øyvind Bjørnstad'}
            profileTitle={'Chief Sustainability Officer at Vestre'}
            profileAvatar={
                <StaticImage
                    src="../../../../images/avatars/vestre-oyvind-bjornstad.png"
                    alt={`Øyvind Bjørnstad`}
                />
            }
            backgroundImageStaticQuery={useStaticQuery(graphql`
                query {
                    desktop: file(relativePath: { eq: "customerQuoteCards/vestre.png" }) {
                        childImageSharp {
                            fluid(quality: 90, maxWidth: 500) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            `)}
        />
    )
}

export default VestreQuoteCard
