export const LINKS = {
    jobs: 'https://lune.crew.work/jobs',
}

export const HUBSPOT_PORTAL_ID = 25160067

export const HUBSPOT_FORM_IDS = {
    home: 'b81434f9-0886-4989-b93e-4ead00fa371e',
    about: 'b81434f9-0886-4989-b93e-4ead00fa371e',
    product: 'b81434f9-0886-4989-b93e-4ead00fa371e',
    customers: 'b81434f9-0886-4989-b93e-4ead00fa371e',
    payments: 'c266f62f-d8c3-4243-bb83-94fe7994051f',
    other: 'b81434f9-0886-4989-b93e-4ead00fa371e',
    blog: '61b89c03-d03d-4314-a556-e6226d563c6d',
    logistics: '8f916e5c-b418-4e12-a84b-a3f17873bafd',
    projects: '99740f76-85df-450b-8b73-2eced32aa7eb',
    procurement: '113ae8b2-6bab-4d7c-ac70-f3cd8de3127f',
    buycarboncredits: 'b652ef65-de68-414d-a7db-4db379a59107',
}

export const DEFAULT_DESCRIPTION = `Brand-conscious companies use Lune’s platform and API to calculate Scope 3 emissions, create positive impact through vetted climate projects, and future-proof business growth.`

// BLOG_PAGE_SIZE is defined both in gatsby-node.js and src/constants.ts
// Both should have the same value
export const BLOG_PAGE_SIZE = 25
