import { Content } from './Content'
import { SubscribeToNewsletter } from './SubscribeToNewsletter'
import { BlogImage } from './BlogImage'
import { Quote } from './Quote'
import { Markdown } from './Markdown'

export const components = {
    content: Content,
    quote: Quote,
    subscribe_to_our_newsletter: SubscribeToNewsletter,
    image: BlogImage,
    markdown: Markdown,
}
