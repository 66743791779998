import * as React from 'react'
import Typography from '@mui/material/Typography'
import useIsMobile from 'hooks/useIsMobile'
import Stack from '@mui/material/Stack'
import BackgroundImageWithPlaceholder, {
    IGatsbyFluidImage,
} from 'components/BackgroundImageWithPlaceholder'
import { Avatar } from '@mui/material'

const QuoteCard = ({
    backgroundImageStaticQuery,
    text,
    profileName,
    profileTitle,
    profileAvatar,
}: {
    backgroundImageStaticQuery: IGatsbyFluidImage
    text: string
    profileName: string
    profileTitle: string
    profileAvatar: React.ReactNode
}) => {
    const isMobile = useIsMobile()

    return (
        <BackgroundImageWithPlaceholder
            placeholder={'https://assets.lune.co/lune-co/placeholders/testimonial.svg'}
            backgroundImageStaticQuery={backgroundImageStaticQuery}
            style={{
                ...(isMobile ? {} : { width: 486 }),
                borderRadius: `32px`,
                overflow: 'hidden',
                backgroundPosition: 'bottom',
            }}
        >
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="stretch"
                sx={{ padding: isMobile ? '32px 32px 200px 32px' : '72px 40px 240px 40px' }}
                spacing={{
                    xs: 3,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    xl: 4,
                }}
            >
                <Stack
                    direction="row"
                    spacing={{
                        xs: 2,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2,
                    }}
                >
                    <Avatar
                        sx={{
                            height: 48,
                            width: 48,
                            margin: 0,
                        }}
                    >
                        {profileAvatar}
                    </Avatar>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={0.5}
                    >
                        <Typography color={`Grey900`} variant={'h6'} component="span">
                            {profileName}
                        </Typography>
                        <Typography color={`Grey900`} variant={'caption'} component="span">
                            {profileTitle}
                        </Typography>
                    </Stack>
                </Stack>
                <Typography
                    color={`Grey900`}
                    fontSize={isMobile ? '18px' : '24px'}
                    fontWeight={400}
                    lineHeight={isMobile ? '21.6px' : '33.6px'}
                    component="span"
                >
                    {text}
                </Typography>
            </Stack>
        </BackgroundImageWithPlaceholder>
    )
}

export default QuoteCard
