require("prismjs/themes/prism-solarizedlight.css")

exports.shouldUpdateScroll = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'instant',
  });
  return false;
};
