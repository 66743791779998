import { graphql, useStaticQuery } from 'gatsby'

const useTags = () => {
    const data = useStaticQuery(graphql`
        {
            allPrismicTag {
                edges {
                    node {
                        data {
                            name {
                                text
                            }
                            color
                            index
                            slug
                        }
                    }
                }
            }
        }
    `)

    return data.allPrismicTag.edges.map(
        ({
            node: {
                data: {
                    name: { text },
                    color,
                    index,
                    slug,
                },
            },
        }) => ({
            name: text,
            color,
            slug,
            index,
        }),
    )
}

export default useTags
