import React from 'react'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'

const ArrowIcon = () => (
    <ArrowCircleRightIcon
        sx={{
            borderRadius: `40px`,
            backgroundColor: `White`,
            color: `Grey900`,
        }}
    />
)

export default ArrowIcon
