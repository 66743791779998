import React, { useCallback, useState } from 'react'
import Box from '@mui/material/Box'
import StandardLayout from '../components/StandardLayout'
import XlContainer from '../components/XlContainer'
import ContactForm from 'views/ContactForm'
import Footer from 'views/Footer'
import useLogBreakpoints from 'hooks/useLogBreakpoints'
import { useTheme } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { StaticImage } from 'gatsby-plugin-image'
import useIsMobile from 'hooks/useIsMobile'
import DifferentiationSection from 'views/DifferentiationSection'
import NailMessagingSection from 'views/NailMessagingSection'
import ExponentialImpactSection from 'views/ExponentialImpactSection'
import ShareImpactBreezeSection from 'views/ShareImpactBreezeSection'
import StandoutSection from 'views/StandoutSection'
import { graphql, useStaticQuery } from 'gatsby'
import Testimonial from '../components/Testimonial'
import GreyGradient from 'components/GreyGradient'
import Page, { PageBodyWithoutNav } from '../components/Page'
import { WindowLocation } from '@reach/router'
import NavBar from '../views/NavBar/NavBar'
import HeroHeadingShared from '../views/HeroHeadingShared'
import { RenderOnDesktop, RenderOnMobile } from '../components/DeviceUtils'
import PaymentsHero from '../images/PaymentsHero.png'

const stackSpacing = {
    xs: 10,
    sm: 15,
    md: 15,
    lg: 15,
    xl: 15,
}

const PaymentsPage = ({ location }: { location: WindowLocation }) => {
    useLogBreakpoints()
    const [navBackgroundColor, setNavBackgroundColor] = useState<string>('transparent')
    const { spacing } = useTheme()
    const isMobile = useIsMobile()

    const onNavBackgroundColorChange = useCallback(
        (color: string) => {
            setNavBackgroundColor(color)
        },
        [navBackgroundColor],
    )

    return (
        <Page location={location} title="Lune - Payments">
            <PageBodyWithoutNav>
                <Box
                    sx={{
                        position: `sticky`,
                        top: `0px`,
                        zIndex: `9999`,
                        backgroundColor: `${navBackgroundColor}`,
                    }}
                >
                    <XlContainer maxWidth="xl">
                        <Box
                            px={{
                                xs: 1,
                                sm: 2,
                                md: 2,
                                lg: 2,
                                xl: 2,
                            }}
                        >
                            <StandardLayout>
                                <NavBar
                                    backgroundColor={'transparent'}
                                    onBackgroundChange={onNavBackgroundColorChange}
                                />
                            </StandardLayout>
                        </Box>
                    </XlContainer>
                </Box>

                <Box
                    sx={{
                        ...(!isMobile
                            ? {
                                  marginTop: `-${spacing(9)}`, // move up the height of NavBar
                              }
                            : {}),
                    }}
                >
                    <GreyGradient hide={isMobile}>
                        <Box
                            sx={{
                                zIndex: '0',
                                overflow: 'hidden',
                            }}
                            mb={{
                                xs: 10,
                                sm: 15,
                                md: 15,
                                lg: 15,
                                xl: 15,
                            }}
                        >
                            <HeroHeadingShared
                                imageUrl={PaymentsHero}
                                placeholderImageUrl={
                                    'https://assets.lune.co/lune-co/placeholders/payments-header.svg'
                                }
                            >
                                <Box
                                    pt={{
                                        xs: 9,
                                        sm: 18,
                                        md: 18,
                                        lg: 18,
                                        xl: 18,
                                    }}
                                >
                                    <StandardLayout>
                                        <XlContainer maxWidth="xl">
                                            <RenderOnMobile>
                                                <Typography
                                                    variant={`h2`}
                                                    component="div"
                                                    sx={{ textAlign: `left` }}
                                                >
                                                    Stand out from the crowd by making every payment
                                                    count
                                                </Typography>
                                            </RenderOnMobile>
                                            <RenderOnDesktop>
                                                <Typography
                                                    variant={`h2`}
                                                    component="div"
                                                    sx={{ textAlign: `center` }}
                                                >
                                                    Stand out from the crowd
                                                </Typography>
                                                <Typography
                                                    variant={`h2`}
                                                    component="div"
                                                    sx={{ textAlign: `center` }}
                                                >
                                                    by making every payment count
                                                </Typography>
                                            </RenderOnDesktop>
                                        </XlContainer>
                                    </StandardLayout>
                                </Box>
                            </HeroHeadingShared>
                        </Box>
                    </GreyGradient>

                    <Stack spacing={stackSpacing} sx={{ overflow: 'hidden' }}>
                        <Box sx={{ width: '100%' }}>
                            <XlContainer>
                                <Stack spacing={stackSpacing}>
                                    <GreyGradient hide={!isMobile}>
                                        <StandardLayout>
                                            <ExponentialImpactSection />
                                        </StandardLayout>
                                    </GreyGradient>

                                    <GreyGradient hide={!isMobile}>
                                        <StandardLayout>
                                            <StandoutSection />
                                        </StandardLayout>
                                    </GreyGradient>

                                    <GreyGradient hide={!isMobile}>
                                        <StandardLayout>
                                            <DifferentiationSection />
                                        </StandardLayout>
                                    </GreyGradient>

                                    <GreyGradient hide={!isMobile}>
                                        <StandardLayout>
                                            <ShareImpactBreezeSection />
                                        </StandardLayout>
                                    </GreyGradient>

                                    <GreyGradient hide={!isMobile}>
                                        <StandardLayout>
                                            <NailMessagingSection />
                                        </StandardLayout>
                                    </GreyGradient>

                                    <StandardLayout>
                                        <Testimonial
                                            text={
                                                'It is essential that every business becomes more climate friendly. For TrueLayer, doing right for our planet is important, an ethos shared by both our customers and our employees. Lune was the easiest way for our business to take the first steps, have a real impact, and continue building the future of the payments industry.'
                                            }
                                            profileName={'Francesco Simoneschi'}
                                            profileTitle={'Founder & CEO at TrueLayer'}
                                            profileAvatar={
                                                <StaticImage
                                                    src="../images/FrancescoSimoneschi.png"
                                                    alt={`Francesco Simoneschi`}
                                                    width={160}
                                                />
                                            }
                                            backgroundImageStaticQuery={useStaticQuery(graphql`
                                                query {
                                                    desktop: file(
                                                        relativePath: {
                                                            eq: "TestimonialPayments.png"
                                                        }
                                                    ) {
                                                        childImageSharp {
                                                            fluid(quality: 90, maxWidth: 1920) {
                                                                ...GatsbyImageSharpFluid_withWebp
                                                            }
                                                        }
                                                    }
                                                }
                                            `)}
                                        />
                                    </StandardLayout>

                                    <StandardLayout>
                                        <ContactForm
                                            hubspotFormName={'payments'}
                                            headers={['Start building your competitive advantage']}
                                        />
                                    </StandardLayout>
                                    <Footer />
                                </Stack>
                            </XlContainer>
                        </Box>
                    </Stack>
                </Box>
            </PageBodyWithoutNav>
        </Page>
    )
}

export default PaymentsPage
