import * as React from 'react'
import { graphql } from 'gatsby'
import { WindowLocation } from '@reach/router'
import Box from '@mui/material/Box'
import { SliceZone } from '@prismicio/react'

import { components } from '../slices'
import { BlogHeader } from 'components/Blog'
import StandardLayout from 'components/StandardLayout'
import { BlogWrapper } from 'components/BlogWrapper'

export const query = graphql`
    query ($slug: String!) {
        prismicBlogpostSliced(data: { slug: { eq: $slug } }) {
            data {
                body {
                    ... on PrismicBlogpostSlicedDataBodyContent {
                        slice_type
                        primary {
                            content {
                                richText
                            }
                        }
                    }
                    ... on PrismicBlogpostSlicedDataBodyQuote {
                        slice_type
                        primary {
                            author_avatar {
                                gatsbyImageData
                            }
                            author_name
                            author_role
                            quote
                            quote_date
                        }
                    }
                    ... on PrismicBlogpostSlicedDataBodySubscribeToOurNewsletter {
                        slice_type
                    }
                    ... on PrismicBlogpostSlicedDataBodyImage {
                        slice_type
                        primary {
                            image {
                                alt
                                gatsbyImageData
                            }
                            size
                            attribution_caption
                            attribution_url
                        }
                    }
                    ... on PrismicBlogpostSlicedDataBodyMarkdown {
                        slice_type
                        primary {
                            content {
                                richText
                            }
                        }
                    }
                }
                subtitle {
                    text
                }
                title {
                    text
                }
                date
                brief
                main_image {
                    gatsbyImageData
                }
                author {
                    document {
                        ... on PrismicAuthor {
                            data {
                                author_avatar {
                                    gatsbyImageData
                                }
                                author_full_name
                                author_title
                            }
                        }
                    }
                }
                tags {
                    tag {
                        document {
                            ... on PrismicTag {
                                data {
                                    slug
                                    color
                                    name {
                                        text
                                    }
                                    index
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
const PrismicBlogPageWithSlices = ({
    location,
    data: {
        prismicBlogpostSliced: {
            data: { title, subtitle, date, author, body, tags, brief, main_image },
        },
    },
}: {
    location: WindowLocation
    data: {
        prismicBlogpostSliced: {
            data: {
                date: string
                brief: string
                body: any
                title: {
                    text: string
                }
                subtitle: {
                    text: string
                }
                author: {
                    document: {
                        data: {
                            author_full_name: string
                            author_title: string
                            author_avatar: {
                                gatsbyImageData: any
                            }
                        }
                    }
                }
                main_image: {
                    gatsbyImageData: {
                        images: {
                            fallback: {
                                src: string
                            }
                        }
                    }
                }
                tags: {
                    tag: {
                        document: {
                            data: {
                                slug: string
                                color: string
                                name: {
                                    text: string
                                }
                                index: number
                            }
                        }
                    }
                }[]
            }
        }
    }
}) => {
    const authorData = {
        name: author.document.data.author_full_name,
        avatar: author.document.data.author_avatar,
        title: author.document.data.author_title,
    }
    const meta = {
        image: main_image.gatsbyImageData.images.fallback.src,
        description: brief,
    }

    return (
        <BlogWrapper location={location} title={title.text} meta={meta}>
            <>
                <Box
                    sx={{
                        mt: { xs: 9, sm: 9, md: 9, lg: 9, xl: 9 },
                        mx: { xs: 0, sm: 0, md: 0, lg: 20, xl: 20 },
                    }}
                >
                    <StandardLayout>
                        <BlogHeader
                            title={title.text}
                            subtitle={subtitle.text}
                            author={authorData}
                            date={date}
                            tags={tags?.map((t) => {
                                return {
                                    name: t.tag.document.data.name.text,
                                    slug: t.tag.document.data.slug,
                                    color: t.tag.document.data.color,
                                    index: t.tag.document.data.index,
                                }
                            })}
                        />
                    </StandardLayout>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <SliceZone slices={body} components={components} />
                </Box>
            </>
        </BlogWrapper>
    )
}

export default PrismicBlogPageWithSlices
