import * as React from 'react'
import { graphql, navigate } from 'gatsby'
import { WindowLocation } from '@reach/router'
import useLogBreakpoints from 'hooks/useLogBreakpoints'
import Page, { PageBody } from 'components/Page'
import Stack from '@mui/material/Stack'
import XlContainer from 'components/XlContainer'
import { StaticImage } from 'gatsby-plugin-image'
import Box from '@mui/material/Box'
import StandardLayout from 'components/StandardLayout'
import { RenderOnDesktop, RenderOnMobile } from 'components/DeviceUtils'
import Typography from '@mui/material/Typography'
import BlogSummary from 'components/BlogSummary'
import Footer from 'views/Footer'
import useAuthorData from 'hooks/useAuthorData'
import useTags from 'hooks/useTags'
import TagStrip from 'components/TagStrip'
import Pagination from '@mui/material/Pagination'
import { SubscribeSection } from 'components/BlogSubscriptionForm'
import { HUBSPOT_FORM_IDS } from '../constants'

const stackSpacing = {
    xs: 10,
    sm: 15,
    md: 15,
    lg: 15,
    xl: 15,
}

type PrismicData = {
    allPrismicBlogpostSliced: {
        pageInfo: {
            currentPage: number
            hasNextPage: boolean
            hasPreviousPage: boolean
            pageCount: number
        }
        edges: {
            node: {
                data: {
                    slug: string
                    date: string
                    brief: string
                    title: {
                        text: string
                    }
                    author: {
                        id: string
                    }
                    main_image: {
                        gatsbyImageData: {
                            childImageSharp: {
                                fluid: {
                                    aspectRatio: number
                                    src: string
                                    srcSet: string
                                    sizes: string
                                }
                            }
                        }
                    }
                    tags: {
                        tag: {
                            document: {
                                data: {
                                    slug: string
                                    color: string
                                    name: {
                                        text: string
                                    }
                                    index: number
                                }
                            }
                        }
                    }
                }
            }
        }[]
    }
}

export const query = graphql`
    query ($slicedFilter: PrismicBlogpostSlicedFilterInput, $skip: Int!, $limit: Int!) {
        allPrismicBlogpostSliced(
            filter: $slicedFilter
            limit: $limit
            skip: $skip
            sort: { fields: data___date, order: DESC }
        ) {
            pageInfo {
                currentPage
                hasNextPage
                hasPreviousPage
                pageCount
            }
            edges {
                node {
                    data {
                        author {
                            id
                        }
                        brief
                        date
                        main_image {
                            gatsbyImageData
                            alt
                        }
                        slug
                        title {
                            text
                        }
                        tags {
                            tag {
                                document {
                                    ... on PrismicTag {
                                        id
                                        data {
                                            color
                                            index
                                            name {
                                                text
                                            }
                                            slug
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

function getPosts(data: PrismicData): {
    pageInfo: {
        currentPage: number
        hasNextPage: boolean
        hasPreviousPage: boolean
        pageCount: number
    }
    posts: {
        mainImage: { gatsbyImageData: any }
        date: string
        title: string
        brief?: string
        content?: string
        author: {
            author_full_name: string
            author_title: string
            author_avatar: { gatsbyImageData: any }
        }
        slug: string
        tags: {
            slug: string
            color: string
            name: string
            index: number
        }[]
    }[]
} {
    const { findById } = useAuthorData()

    const slicedPrismicPosts = data.allPrismicBlogpostSliced?.edges?.map(
        ({
            node: {
                data: { title, author, main_image, brief, date, slug, tags },
            },
        }) => ({
            mainImage: main_image,
            title: title.text,
            date,
            slug,
            brief,
            author: findById(author.id),
            tags: tags?.map((t) => ({
                ...t.tag.document?.data,
                name: t.tag.document?.data?.name?.text,
            })),
        }),
    )

    return {
        posts: slicedPrismicPosts,
        pageInfo: data.allPrismicBlogpostSliced?.pageInfo,
    }
}

const BlogIndexPage = ({ location, data }: { location: WindowLocation; data: PrismicData }) => {
    useLogBreakpoints()
    const tags = useTags()
    const { posts, pageInfo } = getPosts(data)

    const selectedTagSlug = location?.pathname.includes(`/blog/tags/`)
        ? location?.pathname.replace(`/blog/tags/`, '').split('/')[0]
        : undefined

    return (
        <Page
            location={location}
            title={`Lune - ${
                selectedTagSlug
                    ? `Blog - ${tags.find((t) => t.slug === selectedTagSlug)?.name}`
                    : 'Blog'
            }${pageInfo.currentPage > 1 ? ` - ${pageInfo.currentPage}` : ''}`}
        >
            <PageBody>
                <Stack spacing={stackSpacing} sx={{ overflow: 'hidden' }}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <XlContainer>
                            <Box mt={9}>
                                <StandardLayout>
                                    <RenderOnMobile>
                                        <Typography
                                            variant={`h2`}
                                            component="div"
                                            sx={{ textAlign: `left` }}
                                        >
                                            We write about climate, culture, and code
                                        </Typography>
                                        <TagStrip
                                            selectedTagSlug={selectedTagSlug}
                                            tags={tags}
                                            sx={{
                                                mt: 12,
                                                mb: -5,
                                            }}
                                        />
                                    </RenderOnMobile>
                                    <RenderOnDesktop>
                                        <Typography
                                            variant={`h2`}
                                            component="div"
                                            sx={{ textAlign: `center` }}
                                        >
                                            We write about climate, <br /> culture, and code
                                        </Typography>
                                        <TagStrip
                                            selectedTagSlug={selectedTagSlug}
                                            tags={tags}
                                            sx={{
                                                mt: 15,
                                            }}
                                        />
                                    </RenderOnDesktop>
                                </StandardLayout>
                            </Box>
                        </XlContainer>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <XlContainer>
                            <Stack spacing={stackSpacing}>
                                {posts.map(
                                    (
                                        {
                                            author,
                                            content,
                                            date,
                                            brief,
                                            mainImage,
                                            title,
                                            slug,
                                            tags,
                                        },
                                        i,
                                    ) => (
                                        <BlogSummary
                                            key={i}
                                            mainImage={mainImage}
                                            title={title}
                                            date={date}
                                            content={content}
                                            brief={brief}
                                            author={author}
                                            slug={slug}
                                            tags={tags}
                                            selectedSlug={selectedTagSlug}
                                        />
                                    ),
                                )}
                            </Stack>
                        </XlContainer>
                    </Box>
                    {pageInfo.pageCount > 1 && (
                        <Box
                            sx={{
                                display: `flex`,
                                justifyContent: `center`,
                                '& .MuiPaginationItem-root': {
                                    color: `Grey900`,
                                },
                                '& .Mui-selected': {
                                    border: `thin solid`,
                                    borderColor: `Grey900`,
                                    background: `none !important`,
                                },
                            }}
                        >
                            <Pagination
                                variant="text"
                                sx={{
                                    fontWeight: '500',
                                }}
                                page={pageInfo.currentPage}
                                count={pageInfo.pageCount}
                                onChange={(_event: React.ChangeEvent<unknown>, page: number) => {
                                    const basePath = selectedTagSlug
                                        ? `/blog/tags/${selectedTagSlug}`
                                        : `/blog`
                                    if (page === 1) {
                                        navigate(basePath)
                                    } else {
                                        navigate(`${basePath}/${page}`)
                                    }
                                }}
                            />
                        </Box>
                    )}
                    <Box
                        sx={{
                            width: '100%',
                        }}
                    >
                        <XlContainer>
                            <SubscribeSection
                                title="Subscribe to our monthly newsletter – and get a free copy of our Complete Guide to Offsetting"
                                backgroundColor="#f0f8e3"
                                image={
                                    <>
                                        <RenderOnDesktop>
                                            <StaticImage
                                                alt="Offsetting guide"
                                                src="../images/GuideToOffsettingLarge.png"
                                            />
                                        </RenderOnDesktop>
                                        <RenderOnMobile>
                                            <StaticImage
                                                alt="Offsetting guide"
                                                src="../images/GuideToOffsettingSmall.png"
                                            />
                                        </RenderOnMobile>
                                    </>
                                }
                                hubspotFormId={HUBSPOT_FORM_IDS.blog}
                                buttonText="Subscribe"
                                buttonIconName="ArrowCircleRight"
                                confirmationText="Thank you, you’ll get the guide in your inbox shortly."
                                implicitMarketingOptIn
                            />
                        </XlContainer>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <XlContainer>
                            <Footer />
                        </XlContainer>
                    </Box>
                </Stack>
            </PageBody>
        </Page>
    )
}

export default BlogIndexPage
