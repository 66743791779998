import React from 'react'
import StatementsWithBigIcons from './StatementsWithBigIcons'
import Box from '@mui/material/Box'
import { RenderOnDesktop } from '../components/DeviceUtils'

const Emoji = ({ emoji }: { emoji: string }) => (
    <Box
        sx={{
            fontSize: {
                xs: 42,
                sm: 64,
                md: 64,
                lg: 64,
                xl: 64,
            },
        }}
    >
        {emoji}
    </Box>
)

const PerksAndRecreation = () => {
    return (
        <>
            <StatementsWithBigIcons
                smallIcons
                statements={[
                    {
                        title: 'Competitive salary & equity',
                        iconNode: <Emoji emoji={`💰`} />,
                    },
                    {
                        title: 'Comprehensive health insurance',
                        iconNode: <Emoji emoji={`🏥`} />,
                    },
                    {
                        title: 'Family planning & fertility benefits',
                        iconNode: <Emoji emoji={`🍼`} />,
                    },
                    {
                        title: '25 days of holiday',
                        iconNode: <Emoji emoji={`🏝`} />,
                    },
                ]}
            />
            <RenderOnDesktop>
                <Box height={100} />
            </RenderOnDesktop>
            <StatementsWithBigIcons
                smallIcons
                statements={[
                    {
                        title: 'Quarterly team get together & offsite',
                        iconNode: <Emoji emoji={`🏎️`} />,
                    },
                    {
                        title: 'Flexible working: work from anywhere',
                        iconNode: <Emoji emoji={`💻`} />,
                    },
                    {
                        title: 'Office in London: come when you please',
                        iconNode: <Emoji emoji={`🏬`} />,
                    },
                ]}
            />
        </>
    )
}

export default PerksAndRecreation
