import TwoColumnLayout, { ColumnWrapOrder } from 'components/TwoColumnLayout'
import SectionBody1 from 'components/SectionBody1'
import React from 'react'
import H3 from 'components/H3'
import StaticImageWrapper from '../components/StaticImageWrapper'
import { StaticImage } from 'gatsby-plugin-image'

const ProjectDevelopersSection = () => {
    return (
        <TwoColumnLayout columnWrapOrderOnMobile={ColumnWrapOrder.REVERSE}>
            <>
                <H3>Project developers who deliver on their promises</H3>
                <SectionBody1>
                    Behind every project is a group of humans working tirelessly to deliver
                    significant climate benefits. The integrity of the project developer and their
                    capacity to deliver is as important as their methodology.{' '}
                    <strong>
                        Lune builds long term partnerships with leading project developers
                    </strong>{' '}
                    to support the sustainability of their important work.
                </SectionBody1>
            </>
            <StaticImageWrapper
                placeholder={'https://assets.lune.co/lune-co/placeholders/projects-quotes.svg'}
            >
                <StaticImage
                    placeholder={'dominantColor'}
                    src={'../images/Quote.png'}
                    alt={'Buy Offsets'}
                    layout="fullWidth"
                />
            </StaticImageWrapper>
        </TwoColumnLayout>
    )
}

export default ProjectDevelopersSection
