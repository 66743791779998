import { useStaticQuery, graphql } from 'gatsby'

const useAuthorData = () => {
    const data = useStaticQuery(graphql`
        {
            allPrismicAuthor {
                edges {
                    node {
                        prismicId
                        data {
                            author_title
                            author_full_name
                            author_avatar {
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
        }
    `)

    return {
        findById: (id: string) =>
            data.allPrismicAuthor?.edges?.find((edge: any) => edge?.node?.prismicId === id)?.node
                ?.data,
    }
}

export default useAuthorData
