import React from 'react'
import Body1 from 'components/Body1'

const SectionBody1 = ({ children }: { children: React.ReactNode }) => {
    return (
        <Body1
            sx={{
                mt: {
                    xs: 3,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    xl: 4,
                },
            }}
        >
            {children}
        </Body1>
    )
}

export default SectionBody1
