import React from 'react'
import { Box, SxProps } from '@mui/material'
import Shadow from 'components/Shadow'
import { StaticImage } from 'gatsby-plugin-image'

const DEFAULT_MAXHEIGHT = 868
const SHORT_MAXHEIGHT = 524

export const Placeholder = () => {
    return (
        <StaticImage
            src={'../images/placeholder.png'}
            quality={50}
            layout="constrained"
            height={DEFAULT_MAXHEIGHT}
            style={{
                zIndex: 10,
                position: 'relative',
                opacity: 0,
            }}
        />
    )
}

export const ShortPlaceholder = () => {
    return (
        <StaticImage
            src={'../images/placeholder.png'}
            quality={50}
            layout="constrained"
            height={SHORT_MAXHEIGHT}
            style={{
                zIndex: 10,
                position: 'relative',
            }}
        />
    )
}

const OverflowImage = ({
    children,
    shadow,
    containerSx,
    float,
    placeholder,
}: {
    children: React.ReactNode
    shadow?: boolean
    maxHeight?: number
    containerSx?: SxProps
    float?: 'left' | 'right'
    placeholder?: Placeholder | ShortPlaceholder
}) => {
    const positionSx = {
        position: 'absolute',
        height: '100%',
        width: 'fit-content',
        zIndex: 100,
        top: '0px',
        ...(float === 'right' ? { right: '0px' } : { left: '0px' }),
    }

    return (
        <Box sx={{ position: 'relative' }}>
            {placeholder ? placeholder : <Placeholder />}
            {shadow ? (
                <Shadow sx={{ ...positionSx, ...(containerSx ? containerSx : {}) }}>
                    {children}
                </Shadow>
            ) : (
                <Box sx={{ ...positionSx, ...(containerSx ? containerSx : {}) }}>{children}</Box>
            )}
        </Box>
    )
}

export default OverflowImage
