import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import QuoteCard from 'components/QuoteCard'

const AlgbraQuoteCard = () => {
    return (
        <QuoteCard
            text={
                'Connecting our customers to high-impact projects was really important to us. After trying consultancies, we realised that we wanted higher quality and greater transparency into carbon projects, and more control over the buying process. Lune ticked all of these boxes, making it easy for us to feel confident in the projects we offer our customers.'
            }
            profileName={'Marten Möller'}
            profileTitle={'ESG Manager at Algbra'}
            profileAvatar={
                <StaticImage
                    src="../../../../images/avatars/algbra-marten-moller.png"
                    alt={`Marten Möller`}
                />
            }
            backgroundImageStaticQuery={useStaticQuery(graphql`
                query {
                    desktop: file(relativePath: { eq: "customerQuoteCards/algbra.png" }) {
                        childImageSharp {
                            fluid(quality: 90, maxWidth: 500) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            `)}
        />
    )
}

export default AlgbraQuoteCard
