import useIsMobile from 'hooks/useIsMobile'
import TwoColumnLayout, { ColumnWrapOrder } from 'components/TwoColumnLayout'
import SectionBody1 from 'components/SectionBody1'
import Box from '@mui/material/Box'
import Line from 'components/Line'
import React from 'react'
import SectionLink from 'components/SectionLink'
import H3 from 'components/H3'
import OverflowImage from 'components/OverflowImage'
import QualityImageWithPlaceholder from '../components/QualityImgWithPlaceholder'

const QualityAndQuantitySection = () => {
    const isMobile = useIsMobile()

    return (
        <TwoColumnLayout
            spacing={{
                xs: 0,
                sm: 0,
                md: 0,
                lg: 0,
                xl: 0,
            }}
            columnWrapOrderOnMobile={ColumnWrapOrder.REVERSE}
        >
            <Box
                sx={{
                    mt: isMobile ? `50px` : `0px`,
                }}
            >
                <H3>Quality over quantity</H3>
                <SectionBody1>
                    <strong>Looking for peace of mind</strong> that your carbon projects are
                    creating real climate impact? All projects are handpicked based on rigorous
                    in-house analysis and leading external carbon offset evaluations. We curate
                    projects for{' '}
                    <strong>
                        durable, additional, and measurable carbon benefits with robust risk
                        mitigations.
                    </strong>
                </SectionBody1>

                <Line />

                <SectionLink
                    text={'Access our full evaluation process'}
                    to={'https://share-eu1.hsforms.com/1mXQPdoXfRQuLcy7O0yqn6wez9mr'}
                    newPage
                />
            </Box>
            <OverflowImage>
                <QualityImageWithPlaceholder />
            </OverflowImage>
        </TwoColumnLayout>
    )
}

export default QualityAndQuantitySection
