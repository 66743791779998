import { Link } from 'gatsby'
import React from 'react'
import Typography from '@mui/material/Typography'
import NavItemWrapper from './NavItemWrapper'

const NavItem = ({ to, text }: { to: string; text: string }) => (
    <Link
        to={to}
        style={{
            textDecoration: 'none',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            lineHeight: 0,
        }}
    >
        <NavItemWrapper>
            <Typography
                variant="button"
                color={`Grey900`}
                textAlign={`left`}
                whiteSpace={`nowrap`}
                lineHeight={0}
            >
                {text}
            </Typography>
        </NavItemWrapper>
    </Link>
)

export default NavItem
