import Box from '@mui/material/Box'
import DesktopNavItem from './DesktopNavItem'
import React, { useRef, useCallback, useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import XlContainer from 'components/XlContainer'
import StandardLayout from 'components/StandardLayout'

interface DesktopNavItem {
    to: string
    text: string
}

const items: DesktopNavItem[] = [
    {
        to: `logistics`,
        text: `Logistics`,
    },
    {
        to: `procurement`,
        text: `Procurement`,
    },
    {
        to: `paymentsAndBanking`,
        text: `Payments & Banking`,
    },
    {
        to: `buyCarbonCredits`,
        text: `Buy Carbon Credits`,
    },
]

const DesktopSubNav = ({
    backgroundColor: initialBackgroundColor,
    onBackgroundChange,
}: {
    backgroundColor?: string
    onBackgroundChange?: (color: string) => void
}) => {
    const { palette } = useTheme()
    const ref = useRef()

    const [activeSection, setActiveSection] = useState<string>('')
    const [clickedSection, setClickedSection] = useState<string>()
    const [highlight, setHighlight] = useState<boolean>(true)

    const onNavItemClick = (section: string) => {
        setHighlight(false)
        setClickedSection(section)
    }

    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('section')
            const scrollPosition = window.pageYOffset || document.documentElement.scrollTop

            let active = ''

            sections.forEach((section) => {
                const sectionTop = section.offsetTop - 100
                const sectionBottom = sectionTop + section.offsetHeight

                if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
                    active = section.id
                }
            })

            setActiveSection(active)
            if (active === clickedSection) {
                setHighlight(true)
                setClickedSection(undefined)
            }
        }

        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [clickedSection])

    const changeBackground = useCallback(() => {
        const navHeight = ref?.current?.clientHeight
        const color =
            window.scrollY > navHeight ? palette.White : initialBackgroundColor ?? palette.White

        if (onBackgroundChange) {
            onBackgroundChange(color)
        }
    }, [])

    if (initialBackgroundColor !== palette.White) {
        useEffect(() => {
            window.addEventListener('scroll', changeBackground)
            return () => window.removeEventListener('scroll', changeBackground)
        }, [])
    }

    return (
        <Box
            sx={{
                overflow: 'hidden',
                position: `sticky`,
                top: `72px`,
                zIndex: `9999`,
                backgroundColor: `white !important`,
                height: '72px',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <XlContainer maxWidth="xl">
                <Box
                    px={{
                        xs: 1,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2,
                    }}
                >
                    <StandardLayout>
                        <Box
                            ref={ref}
                            display={`flex`}
                            justifyContent={`space-between`}
                            alignItems={`center`}
                            height={`100%`}
                        >
                            <Box display={`flex`} alignItems={`center`}>
                                <ArrowForwardIcon width={16} height={16} sx={{ mr: 2 }} />
                                {items.map((item) => (
                                    <DesktopNavItem
                                        key={`customers-sub-navigation-${item.to}`}
                                        onClick={onNavItemClick}
                                        active={
                                            (highlight && activeSection === item.to) ||
                                            clickedSection === item.to
                                        }
                                        to={item.to}
                                        text={item.text}
                                    />
                                ))}
                            </Box>
                        </Box>
                    </StandardLayout>
                </Box>
            </XlContainer>
        </Box>
    )
}

export default DesktopSubNav
