import React, { useCallback, useState } from 'react'
import Box from '@mui/material/Box'
import StandardLayout from '../components/StandardLayout'
import XlContainer from '../components/XlContainer'
import Footer from 'views/Footer'
import useLogBreakpoints from 'hooks/useLogBreakpoints'
import { useTheme } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { StaticImage } from 'gatsby-plugin-image'
import useIsMobile from 'hooks/useIsMobile'
import PickBestProjects from 'views/PickBestProjects'
import TakeBackOffsettingSection from 'views/TakeBackOffsettingSection'
import { graphql, useStaticQuery } from 'gatsby'
import Testimonial from '../components/Testimonial'
import GreyGradient from 'components/GreyGradient'
import Page, { PageBodyWithoutNav } from '../components/Page'
import BuyCreditsQualityAndQuantitySection from '../views/BuyCreditsQualityAndQuantitySection'
import BuyCreditsShareImpactSection from '../views/BuyCreditsShareImpactSection'
import { WindowLocation } from '@reach/router'
import NavBar from '../views/NavBar/NavBar'
import HeroHeadingShared from '../views/HeroHeadingShared'
import { RenderOnDesktop, RenderOnMobile } from '../components/DeviceUtils'
import { SubscribeSection } from 'components/SubscribeSection'
import { HUBSPOT_FORM_IDS } from '../constants'
import BuyCarbonCreditsHero from '../images/BuyCarbonCreditsHero.png'

const stackSpacing = {
    xs: 10,
    sm: 15,
    md: 15,
    lg: 15,
    xl: 15,
}

const BuyCarbonCreditsPage = ({ location }: { location: WindowLocation }) => {
    useLogBreakpoints()
    const [navBackgroundColor, setNavBackgroundColor] = useState<string>('transparent')
    const { spacing } = useTheme()
    const isMobile = useIsMobile()

    const onNavBackgroundColorChange = useCallback(
        (color: string) => {
            setNavBackgroundColor(color)
        },
        [navBackgroundColor],
    )

    return (
        <Page location={location} title="Lune - Buy Carbon Credits">
            <PageBodyWithoutNav>
                <Box
                    sx={{
                        position: `sticky`,
                        top: `0px`,
                        zIndex: `9999`,
                        backgroundColor: `${navBackgroundColor}`,
                    }}
                >
                    <XlContainer maxWidth="xl">
                        <Box
                            px={{
                                xs: 1,
                                sm: 2,
                                md: 2,
                                lg: 2,
                                xl: 2,
                            }}
                        >
                            <StandardLayout>
                                <NavBar
                                    backgroundColor={'transparent'}
                                    onBackgroundChange={onNavBackgroundColorChange}
                                />
                            </StandardLayout>
                        </Box>
                    </XlContainer>
                </Box>

                <Box
                    sx={{
                        ...(!isMobile
                            ? {
                                  marginTop: `-${spacing(9)}`, // move up the height of NavBar
                              }
                            : {}),
                    }}
                >
                    <GreyGradient hide={isMobile}>
                        <Box
                            sx={{
                                zIndex: '0',
                                overflow: 'hidden',
                            }}
                            mb={{
                                xs: 10,
                                sm: 15,
                                md: 15,
                                lg: 15,
                                xl: 15,
                            }}
                        >
                            <HeroHeadingShared
                                imageUrl={BuyCarbonCreditsHero}
                                placeholderImageUrl={
                                    'https://assets.lune.co/lune-co/placeholders/directpurchase-header.svg'
                                }
                            >
                                <Box
                                    pt={{
                                        xs: 9,
                                        sm: 18,
                                        md: 18,
                                        lg: 18,
                                        xl: 18,
                                    }}
                                >
                                    <StandardLayout>
                                        <XlContainer maxWidth="xl">
                                            <RenderOnMobile>
                                                <Typography
                                                    variant={`h2`}
                                                    component="div"
                                                    sx={{ textAlign: `left` }}
                                                >
                                                    Confidently fund the best projects on the
                                                    planet, for the planet
                                                </Typography>
                                            </RenderOnMobile>
                                            <RenderOnDesktop>
                                                <Typography
                                                    variant={`h2`}
                                                    component="div"
                                                    sx={{ textAlign: `center` }}
                                                >
                                                    Confidently fund the best projects
                                                </Typography>
                                                <Typography
                                                    variant={`h2`}
                                                    component="div"
                                                    sx={{ textAlign: `center` }}
                                                >
                                                    on the planet, for the planet
                                                </Typography>
                                            </RenderOnDesktop>
                                        </XlContainer>
                                    </StandardLayout>
                                </Box>
                            </HeroHeadingShared>
                        </Box>
                    </GreyGradient>

                    <Stack spacing={stackSpacing} sx={{ overflow: 'hidden' }}>
                        <Box sx={{ width: '100%' }}>
                            <XlContainer>
                                <Stack spacing={stackSpacing}>
                                    <GreyGradient hide={!isMobile}>
                                        <StandardLayout>
                                            <TakeBackOffsettingSection />
                                        </StandardLayout>
                                    </GreyGradient>

                                    <GreyGradient hide={!isMobile}>
                                        <StandardLayout>
                                            <PickBestProjects />
                                        </StandardLayout>
                                    </GreyGradient>

                                    <GreyGradient hide={!isMobile}>
                                        <StandardLayout>
                                            <BuyCreditsQualityAndQuantitySection />
                                        </StandardLayout>
                                    </GreyGradient>

                                    <GreyGradient hide={!isMobile}>
                                        <StandardLayout>
                                            <BuyCreditsShareImpactSection />
                                        </StandardLayout>
                                    </GreyGradient>

                                    <StandardLayout>
                                        <Testimonial
                                            text={
                                                'Lune’s diverse and high-quality project bundles allowed us to significantly de-risk our climate strategy in line with the Oxford Offsetting Principles. We assessed a number of providers and we are pleased to be working with Lune.'
                                            }
                                            profileName={'Dean Malmgren'}
                                            profileTitle={'Partner, IDEO'}
                                            profileAvatar={
                                                <StaticImage
                                                    src="../images/deanMalmgrenIDEO.png"
                                                    alt={`Dean Malmgren`}
                                                    width={160}
                                                />
                                            }
                                            backgroundImageStaticQuery={useStaticQuery(graphql`
                                                query {
                                                    desktop: file(
                                                        relativePath: {
                                                            eq: "TestimonialBuyCredits.png"
                                                        }
                                                    ) {
                                                        childImageSharp {
                                                            fluid(quality: 100, maxWidth: 1920) {
                                                                ...GatsbyImageSharpFluid_withWebp
                                                            }
                                                        }
                                                    }
                                                }
                                            `)}
                                        />
                                    </StandardLayout>

                                    <SubscribeSection
                                        hubspotFormId={HUBSPOT_FORM_IDS.buycarboncredits}
                                    />

                                    <Footer />
                                </Stack>
                            </XlContainer>
                        </Box>
                    </Stack>
                </Box>
            </PageBodyWithoutNav>
        </Page>
    )
}

export default BuyCarbonCreditsPage
