import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import QuoteCard from 'components/QuoteCard'

const BudbeeQuoteCard = () => {
    return (
        <QuoteCard
            text={
                'Sustainability is core to our business so we work to reduce our emissions while compensating for the remainder. We switched to Lune because of the high level of transparency and flexibility their carbon offsetting platform provided – especially compared to the consultancies we’ve worked with in the past.'
            }
            profileName={'Ann Sonne'}
            profileTitle={'Group Communication & Sustainability at Instabee Group'}
            profileAvatar={
                <StaticImage
                    src="../../../../images/avatars/budbee-ann-sonne.png"
                    alt={`Ann Sonne`}
                />
            }
            backgroundImageStaticQuery={useStaticQuery(graphql`
                query {
                    desktop: file(relativePath: { eq: "customerQuoteCards/budbee.png" }) {
                        childImageSharp {
                            fluid(quality: 90, maxWidth: 500) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            `)}
        />
    )
}

export default BudbeeQuoteCard
