import * as React from 'react'
import { Box } from '@mui/system'
import { HeroItemProps, heroItems } from './HeroHeadingHomeItems'
import { LuneTheme } from 'lune-ui-lib'
import { useState } from 'react'

const HeroImgWrapper = ({ item }: { item: HeroItemProps }) => {
    const { palette } = LuneTheme
    const [loadedImage, setLoadedImage] = useState<boolean>(false)
    return (
        <Box
            sx={{
                width: '280px',
                height: '280px',
                ml: 2,
                ...(!loadedImage && {
                    borderRadius: '20px',
                    backgroundColor: palette.Grey200,
                }),
                img: {
                    opacity: loadedImage ? 1 : 0,
                    width: '100%',
                },
            }}
        >
            <img onLoad={() => setLoadedImage(true)} src={item.mobileImage} alt={item.label} />
        </Box>
    )
}

const HeroMobileItems = () => {
    return (
        <Box
            sx={{
                height: '280px',
                margin: 'auto',
                overflow: 'hidden',
                position: 'relative',
                width: 'calc(100% + 32px)',
                ml: '-16px',
            }}
        >
            <Box
                sx={{
                    '@keyframes scroll': {
                        '0%': {
                            transform: 'translateX(0px)',
                        },
                        '100%': {
                            transform: 'translateX(-1680px)',
                        },
                    },
                    animation: 'scroll 25s linear infinite 1s',
                    display: 'flex',
                    width: 'calc(280px * 12)',
                }}
            >
                {heroItems.map((item, index) => {
                    return <HeroImgWrapper key={`home-heading-item-${index}-mobile`} item={item} />
                })}
                {heroItems.map((item, index) => {
                    return <HeroImgWrapper key={`home-heading-item-${index}-mobile`} item={item} />
                })}
            </Box>
        </Box>
    )
}

export default HeroMobileItems
