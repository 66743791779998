import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined'
import { Chip } from 'lune-ui-lib'
import TabTwoColumnLayout from 'components/TabTwoColumnLayout'
import useIsMobile from 'hooks/useIsMobile'
import AlgbraQuoteCard from 'views/Customers/Sections/PaymentsAndBanking/AlgbraQuoteCard'
import PayhawkQuoteCard from 'views/Customers/Sections/PaymentsAndBanking/PayhawkQuoteCard'
import VisaQuoteCard from 'views/Customers/Sections/PaymentsAndBanking/VisaQuoteCard'

const CarbonOffsetChip = ({ color }: { color: string }) => {
    return (
        <Chip
            sx={{ maxWidth: 'max-content', background: color }}
            icon={<CloudDownloadOutlinedIcon sx={{ width: '20px', height: '20px' }} />}
            label={'Carbon Offsetting'}
        />
    )
}

const EmmissionCalculationsChip = ({ color }: { color: string }) => {
    return (
        <Chip
            sx={{ maxWidth: 'max-content', background: color }}
            icon={<CalculateOutlinedIcon sx={{ width: '20px', height: '20px' }} />}
            label={'Emission Calculations'}
        />
    )
}

const PaymentsTabSection = () => {
    const isMobile = useIsMobile()
    return (
        <TabTwoColumnLayout
            withImageTabs={true}
            labels={[
                <img
                    key={0}
                    alt={'Visa logo'}
                    height={24}
                    src={'https://assets.lune.co/lune-co/tabs-logos/visa.png'}
                />,
                <img
                    key={1}
                    alt={'Payhawk logo'}
                    height={24}
                    src={'https://assets.lune.co/lune-co/tabs-logos/payhawk.png'}
                />,
                <img
                    key={2}
                    alt={'Algbra logo'}
                    height={24}
                    src={'https://assets.lune.co/lune-co/tabs-logos/algbra.png'}
                />,
            ]}
            left={[
                <Box key={0}>
                    <Stack spacing={5}>
                        <Typography variant={isMobile ? 'body1' : 'testimonialMobile'}>
                            Visa saw their unique opportunity to create exponentially positive
                            climate impact for millions of customers and partners by making payments
                            greener.
                        </Typography>
                        <Typography variant={isMobile ? 'body1' : 'testimonialMobile'}>
                            By partnering with Lune, merchants can launch a green checkout while
                            banks, fintechs, and issuers can help customers contribute to high
                            quality carbon projects — all using Lune’s API.
                        </Typography>
                        <Stack spacing={1}>
                            <EmmissionCalculationsChip color={'#FFD6B8'} />
                            <CarbonOffsetChip color={'#FFD6B8'} />
                        </Stack>
                    </Stack>
                </Box>,
                <Box key={1}>
                    <Stack spacing={5}>
                        <Typography variant={isMobile ? 'body1' : 'testimonialMobile'}>
                            After surveying their customers using Lune’s go-to-market resources,
                            Payhawk found that 85% of their customers wanted emissions visibility
                            within their spend management platform. Incoming regulatory requirements
                            is putting pressure on SMBs.
                        </Typography>
                        <Typography variant={isMobile ? 'body1' : 'testimonialMobile'}>
                            {`Having integrated Lune's API, Payhawk automatically calculates the emissions of all spend and enables their SMB customers to get emissions reports in seconds.`}
                        </Typography>
                        <Stack spacing={1}>
                            <EmmissionCalculationsChip color={'#FFE5BA'} />
                        </Stack>
                    </Stack>
                </Box>,
                <Box key={2}>
                    <Stack spacing={5}>
                        <Typography variant={isMobile ? 'body1' : 'testimonialMobile'}>
                            Algbra’ is changing the banking industry to become more ethical and
                            sustainable.
                        </Typography>
                        <Typography variant={isMobile ? 'body1' : 'testimonialMobile'}>
                            In order to offer their users the highest quality carbon offsetting
                            options, they use Lune to allow consumers to offset their emissions and
                            contribute to vetted climate projects directly through the Algbra’ app.
                        </Typography>
                        <Stack spacing={1}>
                            <CarbonOffsetChip color={'#EEEDEB'} />
                        </Stack>
                    </Stack>
                </Box>,
            ]}
            right={[
                <Box key={0}>
                    <VisaQuoteCard />
                </Box>,
                <Box key={1}>
                    <PayhawkQuoteCard />
                </Box>,
                <Box key={2}>
                    <AlgbraQuoteCard />
                </Box>,
            ]}
        />
    )
}

export default PaymentsTabSection
