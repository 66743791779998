import * as React from 'react'
import Box from '@mui/material/Box'
import { FC } from 'react'
import { CtaTile, Text } from 'lune-ui-lib'
import { navigate } from 'gatsby'
import { RenderOnDesktop, RenderOnSmallScreen } from '../../components/DeviceUtils'

import BuyCarbon from '../../images/menuTileImages/BuyCarbon.png'
import Payments from '../../images/menuTileImages/Payments.png'
import Fintech from '../../images/menuTileImages/Fintech.png'
import Logistics from '../../images/menuTileImages/Logistics.png'
import Procurement from '../../images/menuTileImages/Procurement.png'

enum UseCaseGroup {
    BUSINESS = 'business',
    CUSTOMERS = 'customers',
}

const useCases = [
    {
        group: UseCaseGroup.BUSINESS,
        url: '/buy-carbon-credits',
        label: 'Buy carbon credits',
        img: <img src={BuyCarbon} alt={`BuyCarbon`} />,
    },
    {
        group: UseCaseGroup.CUSTOMERS,
        url: '/payments',
        label: 'Payments',
        img: <img src={Payments} alt={`Payments`} />,
    },
    {
        group: UseCaseGroup.CUSTOMERS,
        url: '/logistics',
        label: 'Logistics',
        img: <img src={Logistics} alt={`Logistics`} />,
    },
    {
        group: UseCaseGroup.CUSTOMERS,
        url: '/fintech-and-banking',
        label: 'Banking & Fintech',
        img: <img src={Fintech} alt={`Banking & Fintech`} />,
    },
    {
        group: UseCaseGroup.CUSTOMERS,
        url: '/procurement',
        label: 'Procurement',
        img: <img src={Procurement} alt={`Procurement`} />,
    },
]

import { Stack } from '@mui/system'

const UseCasesMenu: FC = () => {
    return (
        <>
            <RenderOnSmallScreen>
                {useCases.map((useCase) => (
                    <Box
                        key={`mobile-menu-use-case-${useCase.label}`}
                        sx={{
                            margin: '8px 16px',
                        }}
                    >
                        <CtaTile
                            mini
                            showArrow={false}
                            image={useCase.img}
                            title={useCase.label}
                            href={useCase.url}
                        />
                    </Box>
                ))}
            </RenderOnSmallScreen>
            <RenderOnDesktop>
                <Stack sx={{ padding: '24px 32px', width: '552px' }} spacing={4}>
                    {Object.values(UseCaseGroup).map((group) => (
                        <Box key={`use-case-group-${group}`}>
                            <Text
                                variant={`overline`}
                                color={`Grey600`}
                                sx={{
                                    display: 'block',
                                    pt: '10px',
                                    pb: '10px',
                                    mb: 1,
                                }}
                            >
                                For your {group}
                            </Text>
                            <Box
                                sx={{
                                    display: 'grid',
                                    gap: '8px',
                                    gridTemplateColumns: '50% 50%',
                                }}
                            >
                                {useCases
                                    .filter((useCase) => useCase.group === group)
                                    .map((useCase) => (
                                        <CtaTile
                                            key={`menu-use-case-${useCase.label}`}
                                            mini
                                            sxProps={{
                                                pr: 1,
                                            }}
                                            showArrow={false}
                                            image={useCase.img}
                                            title={useCase.label}
                                            href={useCase.url}
                                        />
                                    ))}
                            </Box>
                        </Box>
                    ))}
                </Stack>
            </RenderOnDesktop>
        </>
    )
}

export default UseCasesMenu
