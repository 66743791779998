import { useStaticQuery, graphql } from 'gatsby'

const useSiteMetadata = () => {
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    siteUrl
                    image
                }
            }
        }
    `)
    return site.siteMetadata as {
        title: string
        siteUrl: string
        image: string
    }
}

export default useSiteMetadata
