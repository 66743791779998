import { Box, Stack, Typography } from '@mui/material'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined'
import TabTwoColumnLayout from 'components/TabTwoColumnLayout'
import useIsMobile from 'hooks/useIsMobile'
import { Chip } from 'lune-ui-lib'
import React from 'react'
import FortoQuoteCard from 'views/Customers/Sections/Logistics/FortoQuoteCard'
import ChainioQuoteCard from 'views/Customers/Sections/Logistics/ChainioQuoteCard'

const CarbonOffsetChip = ({ color }: { color: string }) => {
    return (
        <Chip
            sx={{ maxWidth: 'max-content', background: color }}
            icon={<CloudDownloadOutlinedIcon sx={{ width: '20px', height: '20px' }} />}
            label={'Carbon Offsetting'}
        />
    )
}

const EmmissionCalculationsChip = ({ color }: { color: string }) => {
    return (
        <Chip
            sx={{ maxWidth: 'max-content', background: color }}
            icon={<CalculateOutlinedIcon sx={{ width: '20px', height: '20px' }} />}
            label={'Emission Calculations'}
        />
    )
}

const LogisticsTabSection = () => {
    const isMobile = useIsMobile()
    return (
        <TabTwoColumnLayout
            withImageTabs={true}
            labels={[
                <img
                    key={0}
                    alt={'Forto logo'}
                    height={24}
                    src={'https://assets.lune.co/lune-co/tabs-logos/forto.png'}
                />,
                <img
                    key={0}
                    alt={'Chain-io logo'}
                    height={24}
                    src={'https://assets.lune.co/lune-co/tabs-logos/chain-io.png'}
                />,
            ]}
            left={[
                <Box key={0}>
                    <Stack spacing={5}>
                        <Typography variant={isMobile ? 'body1' : 'testimonialMobile'}>
                            Forto saw unmet demand for sustainable logistics, driven by their own
                            strategic vision as well as tightening regulations in the logistics
                            field.
                        </Typography>
                        <Typography variant={isMobile ? 'body1' : 'testimonialMobile'}>
                            By integrating Lune’s API, Forto is able to offer a cutting edge
                            sustainable logistics product to grow their business and delight their
                            customers.
                        </Typography>
                        <Stack spacing={1}>
                            <CarbonOffsetChip color={'#D2EEFF'} />
                            <EmmissionCalculationsChip color={'#D2EEFF'} />
                        </Stack>
                    </Stack>
                </Box>,
                <Box key={1}>
                    <Stack spacing={5}>
                        <Typography variant={isMobile ? 'body1' : 'testimonialMobile'}>
                            Chain.io has understood the challenges associated with complex Scope 3
                            emissions reporting and regulatory compliance for their customers.
                        </Typography>
                        <Typography variant={isMobile ? 'body1' : 'testimonialMobile'}>
                            By integrating the Lune API, customers can now easily fulfill market and
                            regulatory requirements for emissions visibility and greener supply
                            chains without ever leaving their TMS.
                        </Typography>
                        <EmmissionCalculationsChip color={'#E3F1E8'} />
                    </Stack>
                </Box>,
            ]}
            right={[
                <Box key={0}>
                    <FortoQuoteCard />
                </Box>,
                <Box key={1}>
                    <ChainioQuoteCard />
                </Box>,
            ]}
        />
    )
}

export default LogisticsTabSection
