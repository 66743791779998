import React from 'react'
import TwoColumnLayout, { ColumnWrapOrder } from 'components/TwoColumnLayout'
import H3 from 'components/H3'
import SectionBody1 from 'components/SectionBody1'
import Line from 'components/Line'
import SectionLink from 'components/SectionLink'
import OverflowImage from 'components/OverflowImage'
import StaticImageWrapper from '../components/StaticImageWrapper'
import { StaticImage } from 'gatsby-plugin-image'

const EmissionsCalcsSection = () => {
    return (
        <TwoColumnLayout columnWrapOrderOnMobile={ColumnWrapOrder.REVERSE}>
            <>
                <H3>Create exponential impact beyond your own business</H3>
                <SectionBody1>
                    Turn your payments platform into a force for good by enabling thousands or
                    millions of customers to easily remove CO2 from the atmosphere and protect
                    nature globally.
                </SectionBody1>

                <Line />

                <SectionLink
                    text={'Request access'}
                    to={'https://dashboard.lune.co/signup'}
                    onClick={() => gtag('event', 'request_access', {})}
                    newPage
                />
            </>
            <OverflowImage>
                <StaticImageWrapper
                    placeholder={
                        'https://assets.lune.co/lune-co/placeholders/payments-business-pay.svg'
                    }
                >
                    <StaticImage
                        placeholder={'dominantColor'}
                        src={'../images/ChipsPayments.png'}
                        alt={'Exponential impact'}
                        layout="fixed"
                        objectPosition={'100% 0%'}
                        objectFit={'contain'}
                        quality={100}
                        style={{ width: 'unset', aspectRatio: '2028 / 1600', height: '100%' }}
                    />
                </StaticImageWrapper>
            </OverflowImage>
        </TwoColumnLayout>
    )
}

export default EmissionsCalcsSection
