import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import QuoteTile from 'components/QuoteTile'

const LogixboardQuoteTile = () => {
    return (
        <QuoteTile
            backgroundImageStaticQuery={useStaticQuery(graphql`
                query {
                    desktop: file(relativePath: { eq: "customerQuoteTiles/logixboard.png" }) {
                        childImageSharp {
                            fluid(quality: 90, maxWidth: 420) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            `)}
            text={'Helping freight forwarders delight their customers with emissions visibility'}
            logo={
                <img
                    src="https://assets.lune.co/lune-co/client-logos/logixboard.png"
                    alt="Logixboard logo"
                    height={12}
                />
            }
        />
    )
}

export default LogixboardQuoteTile
