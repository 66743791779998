import React from 'react'
import Typography from '@mui/material/Typography'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { desktopNavItemsMxSpacing } from './SubNavBar'
import Box from '@mui/material/Box'

const DesktopNavItem = ({
    to,
    text,
    active,
    onClick,
}: {
    to: string
    text: string
    active: boolean
    onClick: (section) => void
}) => {
    return (
        <AnchorLink
            onClick={() => onClick(to)}
            href={`#${to}`}
            offset="100"
            style={{
                textDecoration: 'none',
                textAlign: 'left',
                whiteSpace: 'nowrap',
                lineHeight: 0,
            }}
        >
            <Box
                sx={{
                    mx: desktopNavItemsMxSpacing,
                    mb: 0,
                    p: 2,
                    py: {
                        xs: 2,
                        sm: 2.5,
                        md: 2.5,
                        lg: 2.5,
                        xl: 2.5,
                    },
                    backgroundColor: active ? 'Grey200' : `transparent`,
                    '&:hover': {
                        backgroundColor: 'Grey200',
                    },
                    borderRadius: '24px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                height={22}
                component="div"
            >
                <Typography
                    variant="button"
                    color={`Grey900`}
                    textAlign={`left`}
                    whiteSpace={`nowrap`}
                    lineHeight={0}
                >
                    {text}
                </Typography>
            </Box>
        </AnchorLink>
    )
}
export default DesktopNavItem
