import * as React from 'react'
import TabTwoColumnLayout from '../components/TabTwoColumnLayout'
import SectionButton from 'components/SectionButton'
import { Typography, Box } from '@mui/material'
import H3 from 'components/H3'
import ForYourTechTeam from 'components/ForYourTechTeam'
import ForYourSustainabilityTeam from 'components/ForYourSustainabilityTeam'
import { RenderOnDesktop, RenderOnMobile } from '../components/DeviceUtils'
import OverflowImage from 'components/OverflowImage'
import StaticImageWrapper from '../components/StaticImageWrapper'
import { StaticImage } from 'gatsby-plugin-image'
import QualityImageWithPlaceholder from '../components/QualityImgWithPlaceholder'

const HomeProductProjectsSection = () => {
    return (
        <TabTwoColumnLayout
            labels={['Our Product', 'Our Projects']}
            chips={[<ForYourTechTeam key={0} />, <ForYourSustainabilityTeam key={1} />]}
            left={[
                <Box key={0}>
                    <H3 component="span">Get started quickly with modern software</H3>
                    <>
                        <RenderOnMobile>
                            <Typography
                                color={'Grey900'}
                                variant={'sectionMobile'}
                                component="span"
                            >
                                {' '}
                                for emission calculations and high quality carbon projects.
                            </Typography>
                        </RenderOnMobile>
                        <RenderOnDesktop>
                            <Typography color={'Grey900'} variant={'section'} component="span">
                                {' '}
                                for emission calculations and high quality carbon projects.
                            </Typography>
                        </RenderOnDesktop>
                    </>

                    <SectionButton text={'Explore our product'} to={'/product'} />
                </Box>,
                <Box key={1}>
                    <H3 component="span">Learn more about our selection of carbon projects</H3>
                    <>
                        <RenderOnMobile>
                            <Typography
                                color={'Grey900'}
                                variant={'sectionMobile'}
                                component="span"
                            >
                                {' '}
                                vetted for quality over quantity.
                            </Typography>
                        </RenderOnMobile>
                        <RenderOnDesktop>
                            <Typography color={'Grey900'} variant={'section'} component="span">
                                {' '}
                                vetted for quality over quantity.
                            </Typography>
                        </RenderOnDesktop>
                    </>

                    <SectionButton text={'Explore our projects'} to={'/projects'} />
                </Box>,
            ]}
            right={[
                <OverflowImage
                    containerSx={{
                        filter: `drop-shadow(-24px 24px 0px rgba(0,100,100,0.05))`,
                    }}
                    key={0}
                >
                    <StaticImageWrapper
                        placeholder={
                            'https://assets.lune.co/lune-co/placeholders/home-techteam-product.svg'
                        }
                    >
                        <StaticImage
                            placeholder={'dominantColor'}
                            src={'../images/DashboardAndAPI.png'}
                            alt={'Dashboard and API'}
                            layout="fixed"
                            objectPosition={'0% 0%'}
                            objectFit={'contain'}
                            quality={100}
                            style={{ width: 'unset', aspectRatio: '2000 / 1601', height: '100%' }}
                        />
                    </StaticImageWrapper>
                </OverflowImage>,
                <OverflowImage key={1}>
                    <QualityImageWithPlaceholder />
                </OverflowImage>,
            ]}
        />
    )
}

export default HomeProductProjectsSection
