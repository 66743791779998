import React from 'react'
import TwoColumnLayout, { ColumnWrapOrder } from 'components/TwoColumnLayout'
import { StaticImage } from 'gatsby-plugin-image'
import H3 from 'components/H3'
import SectionBody1 from 'components/SectionBody1'
import Line from 'components/Line'
import SectionLink from 'components/SectionLink'
import StaticImageWrapper from '../components/StaticImageWrapper'

const DifferentiationSection = () => {
    return (
        <TwoColumnLayout columnWrapOrderOnMobile={ColumnWrapOrder.REVERSE}>
            <>
                <H3>Differentiate by bringing climate into core business</H3>
                <SectionBody1>
                    Delight and retain customers by making climate action part of every purchase for
                    both online or offline payments.
                </SectionBody1>

                <Line />

                <SectionLink
                    text={'View API integration guide'}
                    to={'https://docs.lune.co/guides/integrate-payments'}
                    newPage
                />
            </>
            <StaticImageWrapper
                placeholder={'https://assets.lune.co/lune-co/placeholders/payments-api.svg'}
            >
                <StaticImage
                    placeholder={'dominantColor'}
                    quality={100}
                    src={'../images/PaymentsConfirmation.png'}
                    alt={'Percentage contribution'}
                    layout="fullWidth"
                />
            </StaticImageWrapper>
        </TwoColumnLayout>
    )
}

export default DifferentiationSection
