import * as React from 'react'
import HeroHeadingHome from '../views/HeroHeadingHome/HeroHeadingHome'
import Box from '@mui/material/Box'
import XlContainer from '../components/XlContainer'
import HomeUseCasesSection from '../views/HomeUseCasesSection'
import HomeProductProjectsSection from '../views/HomeProductProjectsSection'
import StandardLayout from '../components/StandardLayout'
import Testimonial from '../components/Testimonial'
import GreyGradient from 'components/GreyGradient'
import ContactForm from 'views/ContactForm'
import Footer from 'views/Footer'
import useLogBreakpoints from 'hooks/useLogBreakpoints'
import LogosSection from 'views/LogosSection'
import { StaticImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import Stack from '@mui/material/Stack'
import useIsMobile from '../hooks/useIsMobile'
import Page, { PageBody } from '../components/Page'
import { DEFAULT_DESCRIPTION } from '../constants'
import FundCarbonProjects from '../views/FundCarbonProjects'

const Home = ({ location }: { location?: string }) => {
    const isMobile = useIsMobile()
    useLogBreakpoints()
    return (
        <Page location={location} meta={{ description: DEFAULT_DESCRIPTION }}>
            <PageBody>
                <Stack
                    spacing={{
                        xs: 10,
                        sm: 15,
                        md: 15,
                        lg: 15,
                        xl: 15,
                    }}
                    sx={{ overflow: 'hidden' }}
                >
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <XlContainer maxWidth="xl">
                            <Box
                                px={{
                                    xs: 1,
                                    sm: 2,
                                    md: 2,
                                    lg: 2,
                                    xl: 2,
                                }}
                            >
                                <HeroHeadingHome />
                            </Box>
                        </XlContainer>
                    </Box>

                    <GreyGradient>
                        <XlContainer>
                            <Stack
                                spacing={{
                                    xs: 10,
                                    sm: 15,
                                    md: 15,
                                    lg: 15,
                                    xl: 15,
                                }}
                                pb={{
                                    xs: 10,
                                    sm: 15,
                                    md: 15,
                                    lg: 15,
                                    xl: 15,
                                }}
                            >
                                <StandardLayout>
                                    <Box display="flex" justifyContent="center" alignItems="center">
                                        <LogosSection />
                                    </Box>
                                </StandardLayout>
                            </Stack>
                        </XlContainer>
                    </GreyGradient>

                    <Box sx={{ width: '100%' }}>
                        <XlContainer>
                            <GreyGradient hide={!isMobile}>
                                <StandardLayout>
                                    <HomeUseCasesSection />
                                </StandardLayout>
                            </GreyGradient>
                        </XlContainer>
                    </Box>

                    <Box sx={{ width: '100%' }}>
                        <XlContainer>
                            <GreyGradient hide={!isMobile}>
                                <StandardLayout>
                                    <FundCarbonProjects />
                                </StandardLayout>
                            </GreyGradient>
                        </XlContainer>
                    </Box>

                    <Box sx={{ width: '100%' }}>
                        <XlContainer>
                            <GreyGradient hide={!isMobile}>
                                <StandardLayout>
                                    <HomeProductProjectsSection />
                                </StandardLayout>
                            </GreyGradient>
                        </XlContainer>
                    </Box>

                    <Box sx={{ width: '100%' }}>
                        <XlContainer>
                            <Stack
                                spacing={{
                                    xs: 10,
                                    sm: 15,
                                    md: 15,
                                    lg: 15,
                                    xl: 15,
                                }}
                            >
                                <StandardLayout>
                                    <Testimonial
                                        text={
                                            'As an inaugural carbon credit provider for Net Zero Marketplace, Lune’s focus on sourcing high-impact carbon credits supports our mission of building trust and harnessing the potential of the voluntary carbon market to help drive meaningful climate action at scale.'
                                        }
                                        profileName={'Nina Schoen'}
                                        profileTitle={'Director of Product Management'}
                                        profileAvatar={
                                            <StaticImage
                                                src="../images/NinaShoen.png"
                                                alt={`Nina Shoen`}
                                            />
                                        }
                                        profileLogo={
                                            'https://assets.lune.co/lune-co/client-logos/salesforce-logo.png'
                                        }
                                        textColor={'White'}
                                        backgroundImageStaticQuery={useStaticQuery(graphql`
                                            query {
                                                desktop: file(
                                                    relativePath: {
                                                        eq: "SalesforceTestimonial.png"
                                                    }
                                                ) {
                                                    childImageSharp {
                                                        fluid(quality: 90, maxWidth: 1920) {
                                                            ...GatsbyImageSharpFluid_withWebp
                                                        }
                                                    }
                                                }
                                            }
                                        `)}
                                        whiteQuote
                                    />
                                </StandardLayout>

                                <StandardLayout>
                                    <ContactForm hubspotFormName={'home'} />
                                </StandardLayout>

                                <Footer />
                            </Stack>
                        </XlContainer>
                    </Box>
                </Stack>
            </PageBody>
        </Page>
    )
}

export default Home
