import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import useIsMobile from '../hooks/useIsMobile'

const Profile = ({
    avatar,
    name,
    title,
    textColor,
    logo,
    date,
}: {
    avatar?: React.ReactNode
    name: string
    title: string
    textColor?: string
    logo?: string
    date?: string
}) => {
    const isMobile = useIsMobile()
    const color = textColor ?? 'Grey900'
    return (
        <Stack
            direction="row"
            spacing={{
                xs: 2,
                sm: 4,
                md: 4,
                lg: 4,
                xl: 4,
            }}
        >
            {avatar && (
                <Avatar
                    alt={name}
                    sx={{
                        height: {
                            xs: 56,
                            sm: 80,
                            md: 80,
                            lg: 80,
                            xl: 80,
                        },
                        width: {
                            xs: 56,
                            sm: 80,
                            md: 80,
                            lg: 80,
                            xl: 80,
                        },
                        margin: 0,
                    }}
                >
                    {avatar}
                </Avatar>
            )}
            <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={0.5}>
                <Typography color={color} variant={isMobile ? 'h6' : 'h5'} component="span">
                    {name}
                </Typography>
                <Typography color={color} variant={isMobile ? 'body2' : 'body1'} component="span">
                    {title + (date ? `, ${date}` : '')}
                </Typography>
                {logo && (
                    <Box py={1}>
                        <img height={40} src={logo} />
                    </Box>
                )}
            </Stack>
        </Stack>
    )
}

export default Profile
