import * as React from 'react'
import StaticImageWrapper from '../components/StaticImageWrapper'
import { StaticImage } from 'gatsby-plugin-image'

const HeroHeadingProduct = () => (
    <StaticImageWrapper
        placeholder={'https://assets.lune.co/lune-co/placeholders/product-header.svg'}
    >
        <StaticImage
            placeholder={'dominantColor'}
            src={`../images/ProductHero.png`}
            alt={`Product`}
            layout="fullWidth"
        />
    </StaticImageWrapper>
)

export default HeroHeadingProduct
