import React, { FC } from 'react'
import Typography from '@mui/material/Typography'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const MENU_ITEM_HEIGHT = '64px'

const MobileNavItem: FC<{ to: string; text: string }> = ({ to, text }) => {
    return (
        <AnchorLink
            href={`#${to}`}
            offset="100"
            style={{
                textDecoration: 'none',
                textAlign: 'left',
                whiteSpace: 'nowrap',
                lineHeight: 0,
                width: '100%',
            }}
        >
            <Typography
                variant="button"
                sx={{
                    height: MENU_ITEM_HEIGHT,
                    color: `Grey900`,
                    whiteSpace: 'nowrap',
                    textAlign: 'left',
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                {text}
            </Typography>
        </AnchorLink>
    )
}

export default MobileNavItem
