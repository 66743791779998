import * as React from 'react'
import { Helmet } from 'react-helmet'
import useSiteMetadata from '../hooks/useSiteMetadata'
import 'css/layout.css'
import { WindowLocation } from '@reach/router'
import NavBar from '../views/NavBar/NavBar'
import StandardLayout from './StandardLayout'
import Box from '@mui/material/Box'
import XlContainer from './XlContainer'
import { LuneTheme, LuneThemeProvider } from 'lune-ui-lib'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

export interface Meta {
    image?: string
    description?: string
}

// A custom way to define some head tags. react-helmet and react-helmet-async leak tags
// across pages in the resulting HTML files, they're just broken.
export const hackyHackPageHeadTags = {}

export const PageBody = ({ children }: { children: React.ReactNode }) => (
    <>
        <Box
            sx={{
                overflow: 'hidden',
                position: `sticky`,
                top: `0px`,
                zIndex: `9999`,
                backgroundColor: `white !important`,
            }}
        >
            <XlContainer maxWidth="xl">
                <Box
                    px={{
                        xs: 1,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2,
                    }}
                >
                    <StandardLayout>
                        <NavBar />
                    </StandardLayout>
                </Box>
            </XlContainer>
        </Box>
        {children}
    </>
)

// no op, but present for consistency
export const PageBodyWithoutNav = ({ children }: { children: React.ReactNode }) => <>{children}</>

const Page = ({
    title,
    children,
    meta,
    location,
}: {
    title?: string
    meta?: Meta
    children: React.ReactNode
    location?: WindowLocation
}) => {
    const defaultMeta = useSiteMetadata()
    const fullUrl = `${defaultMeta.siteUrl}${location?.pathname ?? ''}`

    const imageUrl = (image: string | undefined): string => {
        if (!image) {
            return `${defaultMeta.siteUrl}${defaultMeta.image}`
        }

        if (image.startsWith('http')) {
            return image
        }

        return `${defaultMeta.siteUrl}${image}`
    }

    const socialMeta = {
        url: fullUrl,
        title: title ?? defaultMeta.title,
        description: meta?.description,
        image: imageUrl(meta?.image),
    }

    // This code is SSR-specific
    if (typeof window === 'undefined') {
        if (!location || !location.pathname) {
            throw new Error('location and pathname are required')
        }

        if (hackyHackPageHeadTags[location.pathname] !== undefined) {
            throw new Error(`Head tags already defined for ${location.pathname}`)
        }

        // See the hackyHackPageHeadTags comment for why we use this mechanism instead of
        // react-helmet and react-helmet-async.
        hackyHackPageHeadTags[location.pathname] = (
            <>
                <title>{title ?? defaultMeta.title}</title>

                <meta name="title" content={socialMeta.title} />
                {socialMeta.description && (
                    <meta name="description" content={socialMeta.description} />
                )}

                <meta property="og:type" content="website" />
                <meta property="og:url" content={socialMeta.fullUrl} />
                <meta property="og:title" content={socialMeta.title} />
                {socialMeta.description && (
                    <meta property="og:description" content={socialMeta.description} />
                )}
                <meta property="og:image" content={socialMeta.image} />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:title" content={socialMeta.title} />
                {socialMeta.description && (
                    <meta property="twitter:description" content={socialMeta.description} />
                )}
                <meta property="twitter:image" content={socialMeta.image} />

                <script
                    type="text/javascript"
                    id="hs-script-loader"
                    async
                    defer
                    src="//js-eu1.hs-scripts.com/25160067.js"
                ></script>
                {process.env.ENABLE_PLAUSIBLE === 'true' && (
                    <script
                        defer
                        data-domain="lune.co"
                        src="https://plausible.io/js/plausible.js"
                    ></script>
                )}

                <script
                    async
                    type="text/javascript"
                    src="https://www.googletagmanager.com/gtag/js?id=G-HBDD0KQ0C0"
                ></script>
                <script
                    type="text/javascript"
                    dangerouslySetInnerHTML={{
                        __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());

                  gtag('config', 'G-HBDD0KQ0C0');
                `,
                    }}
                />

                <script
                    type="text/javascript"
                    dangerouslySetInnerHTML={{
                        __html: `
                    _linkedin_partner_id = "4361794";
                    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                    window._linkedin_data_partner_ids.push(_linkedin_partner_id);
                `,
                    }}
                />
                <script
                    type="text/javascript"
                    dangerouslySetInnerHTML={{
                        __html: `
                    (function(l) {
                    if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
                    window.lintrk.q=[]}
                    var s = document.getElementsByTagName("script")[0];
                    var b = document.createElement("script");
                    b.type = "text/javascript";b.async = true;
                    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                    s.parentNode.insertBefore(b, s);})(window.lintrk);
                `,
                    }}
                />
                <noscript>{`
                    <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=4361794&fmt=gif" />
                `}</noscript>
            </>
        )
    }

    if (process.env.GATSBY_BUGSNAG_KEY) {
        Bugsnag.start({
            apiKey: process.env.GATSBY_BUGSNAG_KEY,
            plugins: [new BugsnagPluginReact()],
        })
    }

    return (
        <>
            {/*
            Without this (Helmet+title) navigating between pages in a browser doesn't update
            the title and the first title that was used when we loaded the site sticks
            around forever.

            This is *not* used for server-side rendering of <head> tags.

            For server-side rendering we use the hackyHackPageHeadTags hack above so if you need
            to add a tag to a page do it there, not here. The <Helmet> block below is only for
            client-side title updates.

            Now, there *may* be an issue with react-helmet as we use it here. You see how
            we use hackyHackPageHeadTags because react-helmet and react-helmet-async are broken
            for server-side rendering? That may still be the case here to some extent and
            may result in some pages' titles being inaccurate in a browser but that's still
            an improvement over almost all titles being inaccurate (because of the lack of
            client-side updates)
            */}
            <Helmet>
                <title>{title ?? defaultMeta.title}</title>
            </Helmet>

            <LuneThemeProvider theme={LuneTheme}>{children}</LuneThemeProvider>
        </>
    )
}

export default Page
