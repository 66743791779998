import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { MapRef } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import Map, { MapProps } from './Map'
import MapMarker from './MapMarker'

export interface AnimatedMapMarker {
    title: string
    subtitle: string
    iconSrc: string
    lat: number
    lng: number
    active?: boolean
}

export interface AnimatedMapProps extends MapProps {
    markers: AnimatedMapMarker[]
    startAnimation?: boolean
}

const AnimatedMap: FC<AnimatedMapProps> = ({
    zoom,
    mapboxAccessToken,
    initialPosition,
    markers,
    globe,
    startAnimation,
    disableMapInteraction,
}) => {
    const mapRef = useRef<MapRef>(null)
    const [activePositionIndex, setActivePositionIndex] = useState<number>(0)
    const [activeMarker, setActiveMarker] = useState<AnimatedMapMarker>()
    const [interacted, setInteracted] = useState<boolean>(false)
    const [initAnimation, setInitAnimation] = useState<boolean>(false)

    const activeMarkers: AnimatedMapMarker[] = markers.filter((m) => m.active)

    const fly = useCallback(() => {
        mapRef.current?.flyTo({
            center: {
                lat: activeMarkers[activePositionIndex].lat,
                lng: activeMarkers[activePositionIndex].lng,
            },
            zoom: 3.5,
            duration: 5000,
            easing: (t) => {
                // easeOutQuint curve -> start fast with a long, slow wind-down
                return 1 - Math.pow(1 - t, 5)
            },
        })
        setTimeout(() => {
            setActiveMarker(activeMarkers[activePositionIndex])
        }, 0)
    }, [activeMarkers, activePositionIndex])

    useEffect(() => {
        const animationInterval = setInterval(() => {
            if (!interacted) {
                if (activePositionIndex === activeMarkers.length - 1) {
                    setActivePositionIndex(0)
                } else {
                    setActivePositionIndex(activePositionIndex + 1)
                }
            } else {
                setInteracted(false)
            }
        }, 5000)

        return () => {
            clearInterval(animationInterval)
        }
    }, [activeMarkers])

    useEffect(() => {
        if (activeMarkers?.length && startAnimation && !initAnimation) {
            setTimeout(() => {
                fly()
                setInitAnimation(true)
            }, 2000)
        }
    }, [startAnimation])

    useEffect(() => {
        if (activeMarkers?.length && startAnimation && initAnimation) {
            fly()
        }
    }, [activePositionIndex])

    return (
        <Map
            disableMapInteraction={disableMapInteraction}
            globe={globe}
            mapRef={mapRef}
            mapboxAccessToken={mapboxAccessToken}
            initialPosition={initialPosition}
            zoom={zoom}
            onMove={(e) => {
                setInteracted(
                    e.originalEvent?.type === 'mousemove' || e.originalEvent?.type === 'mouseup',
                )
            }}
        >
            {markers.map((marker) => (
                <MapMarker
                    active={activeMarker?.title === marker.title}
                    key={`map-marker-${marker.title}`}
                    title={marker.title}
                    subTitle={marker.subtitle}
                    latitude={marker.lat}
                    longitude={marker.lng}
                    src={marker.iconSrc}
                />
            ))}
        </Map>
    )
}

export default AnimatedMap
