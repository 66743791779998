import React from 'react'
import { Chip } from 'lune-ui-lib'
import { SxProps } from '@mui/material'
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined'

const ForSustainabilityPages = ({ sx }: { sx?: SxProps }) => {
    return (
        <Chip
            sx={{ ...sx, maxWidth: 'max-content' }}
            label={'Sustainability landing pages'}
            icon={<AutoStoriesOutlinedIcon />}
        />
    )
}

export default ForSustainabilityPages
