import { desktopNavItemsMxSpacing } from './NavBar'
import Box from '@mui/material/Box'
import React from 'react'

const NavItemWrapper = ({
    children,
    onMouseOver,
    onMouseLeave,
    isOpen,
    id,
}: {
    children: React.ReactNode
    onMouseOver?: () => void
    onMouseLeave?: () => void
    isOpen?: boolean
    id?: string
}) => (
    <Box
        id={id || `item`}
        sx={{
            mx: desktopNavItemsMxSpacing,
            mb: 0,
            p: 2,
            py: {
                xs: 2,
                sm: 2.5,
                md: 2.5,
                lg: 2.5,
                xl: 2.5,
            },
            backgroundColor: isOpen ? 'Grey200' : `transparent`,
            '&:hover': {
                backgroundColor: 'Grey200',
            },
            borderRadius: '24px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
        height={22}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        component="div"
    >
        {children}
    </Box>
)

export default NavItemWrapper
