import React from 'react'
import { Button } from 'lune-ui-lib'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'

const SectionButton = ({ text, to }: { text: string; to: string }) => {
    return (
        <div>
            <Button
                leftIcon={<ArrowCircleRightIcon />}
                href={to}
                sx={{
                    my: {
                        xs: 4,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                }}
            >
                {text}
            </Button>
        </div>
    )
}

export default SectionButton
