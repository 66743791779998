import { WindowLocation } from '@reach/router'
import Page, { PageBody } from '../components/Page'
import Stack from '@mui/material/Stack'
import useLogBreakpoints from 'hooks/useLogBreakpoints'
import useIsMobile from 'hooks/useIsMobile'
import GreyGradient from 'components/GreyGradient'
import XlContainer from 'components/XlContainer'
import StandardLayout from 'components/StandardLayout'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import QualityAndQuantitySection from 'views/QualityAndQuantitySection'
import CoBenefitsSection from 'views/CoBenefitsSection'
import ProjectDevelopersSection from 'views/ProjectDevelopersSection'
import NotAnExpertSection from 'views/NotAnExpertSection'
import Footer from 'views/Footer'
import Box from '@mui/material/Box'
import ProjectsCarousel from 'views/ProjectsCarousel/ProjectsCarousel'
import { SubscribeSection } from 'components/SubscribeSection'
import { HUBSPOT_FORM_IDS } from '../constants'
import StaticImageWrapper from '../components/StaticImageWrapper'
import { StaticImage } from 'gatsby-plugin-image'

const stackSpacing = {
    xs: 10,
    sm: 15,
    md: 15,
    lg: 15,
    xl: 15,
}

const ProjectsPage = ({ location }: { location: WindowLocation }) => {
    useLogBreakpoints()
    const isMobile = useIsMobile()

    return (
        <Page location={location} title="Lune - Projects">
            <PageBody>
                <Stack spacing={stackSpacing} sx={{ overflow: 'hidden' }}>
                    <Stack spacing={stackSpacing}>
                        <GreyGradient
                            pb={{
                                xs: 10,
                                sm: 15,
                                md: 15,
                                lg: 15,
                                xl: 15,
                            }}
                            hide={isMobile}
                        >
                            <XlContainer>
                                <Stack
                                    spacing={stackSpacing}
                                    sx={{
                                        mt: {
                                            xs: 9,
                                            sm: 15,
                                            md: 15,
                                            lg: 15,
                                            xl: 15,
                                        },
                                    }}
                                >
                                    <StandardLayout>
                                        {isMobile ? (
                                            <Typography
                                                variant={`h2`}
                                                component="div"
                                                sx={{ textAlign: `left` }}
                                            >
                                                Quality carbon projects that feel like a breath of
                                                fresh air
                                            </Typography>
                                        ) : (
                                            <>
                                                <Typography
                                                    variant={`h2`}
                                                    component="div"
                                                    sx={{ textAlign: `center` }}
                                                >
                                                    Quality carbon projects
                                                </Typography>
                                                <Typography
                                                    variant={`h2`}
                                                    component="div"
                                                    sx={{ textAlign: `center` }}
                                                >
                                                    that feel like a breath of fresh air
                                                </Typography>
                                            </>
                                        )}
                                    </StandardLayout>

                                    {!isMobile && (
                                        <StandardLayout>
                                            <StaticImageWrapper
                                                placeholder={
                                                    'https://assets.lune.co/lune-co/placeholders/projects-header.svg'
                                                }
                                            >
                                                <StaticImage
                                                    placeholder={'dominantColor'}
                                                    src={`../images/ProjectsHero.png`}
                                                    alt={`Payments`}
                                                    layout="fullWidth"
                                                />
                                            </StaticImageWrapper>
                                        </StandardLayout>
                                    )}
                                </Stack>
                            </XlContainer>
                        </GreyGradient>
                    </Stack>
                    <Box sx={{ width: '100%' }}>
                        <XlContainer>
                            <Stack spacing={stackSpacing}>
                                <GreyGradient hide={!isMobile}>
                                    <StandardLayout>
                                        <QualityAndQuantitySection />
                                    </StandardLayout>
                                </GreyGradient>
                                <GreyGradient hide={!isMobile}>
                                    <StandardLayout>
                                        <CoBenefitsSection />
                                    </StandardLayout>
                                </GreyGradient>
                                <GreyGradient
                                    hide={!isMobile}
                                    sx={{
                                        pt: 0,
                                    }}
                                >
                                    <StandardLayout>
                                        <ProjectDevelopersSection />
                                    </StandardLayout>
                                </GreyGradient>
                            </Stack>
                        </XlContainer>
                        <GreyGradient>
                            <XlContainer>
                                <StandardLayout
                                    pt={stackSpacing}
                                    pb={{
                                        ...stackSpacing,
                                        xs: 0,
                                    }}
                                >
                                    <NotAnExpertSection />
                                </StandardLayout>
                            </XlContainer>
                        </GreyGradient>
                        <GreyGradient>
                            <ProjectsCarousel />
                        </GreyGradient>
                        <XlContainer
                            sx={{
                                paddingTop: stackSpacing,
                                paddingBottom: stackSpacing,
                            }}
                        >
                            <SubscribeSection hubspotFormId={HUBSPOT_FORM_IDS.projects} />
                        </XlContainer>
                        <XlContainer>
                            <Footer />
                        </XlContainer>
                    </Box>
                </Stack>
            </PageBody>
        </Page>
    )
}

export default ProjectsPage
