import * as React from 'react'
import Typography from '@mui/material/Typography'
import useIsMobile from 'hooks/useIsMobile'
import Stack from '@mui/material/Stack'
import { Box } from '@mui/system'

const QuoteCardSimple = ({
    text,
    logo,
    backgroundColor,
}: {
    backgroundColor: string
    text: string
    logo: string
}) => {
    const isMobile = useIsMobile()

    return (
        <Box
            style={{
                ...(isMobile ? {} : { width: 486 }),
                borderRadius: `32px`,
                overflow: 'hidden',
                backgroundColor: backgroundColor,
            }}
        >
            <Stack
                sx={{
                    padding: isMobile ? '32px' : '32px 40px 32px 40px',
                    alignItems: 'flex-start',
                }}
                spacing={{
                    xs: 3,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    xl: 4,
                }}
            >
                <img src={logo} height={'16px'} width={'auto'} />
                <Typography
                    color={`Grey900`}
                    fontSize={isMobile ? '18px' : '24px'}
                    fontWeight={400}
                    lineHeight={isMobile ? '21.6px' : '33.6px'}
                    component="span"
                >
                    {text}
                </Typography>
            </Stack>
        </Box>
    )
}

export default QuoteCardSimple
