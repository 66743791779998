import { Box, Stack, Typography } from '@mui/material'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined'
import TabTwoColumnLayout from 'components/TabTwoColumnLayout'
import useIsMobile from 'hooks/useIsMobile'
import { Chip } from 'lune-ui-lib'
import React from 'react'
import PaidQuoteCard from 'views/Customers/Sections/Procurement/PaidQuoteCard'

const CarbonOffsetChip = ({ color }: { color: string }) => {
    return (
        <Chip
            sx={{ maxWidth: 'max-content', background: color }}
            icon={<CloudDownloadOutlinedIcon sx={{ width: '20px', height: '20px' }} />}
            label={'Carbon Offsetting'}
        />
    )
}

const EmmissionCalculationsChip = ({ color }: { color: string }) => {
    return (
        <Chip
            sx={{ maxWidth: 'max-content', background: color }}
            icon={<CalculateOutlinedIcon sx={{ width: '20px', height: '20px' }} />}
            label={'Emission Calculations'}
        />
    )
}

const ProcurementTabSection = () => {
    const isMobile = useIsMobile()
    return (
        <TabTwoColumnLayout
            withImageTabs={true}
            labels={[
                <img
                    key={0}
                    alt={'Paid logo'}
                    height={24}
                    src={'https://assets.lune.co/lune-co/tabs-logos/paid.png'}
                />,
            ]}
            left={[
                <Box key={0}>
                    <Stack spacing={5}>
                        <Typography variant={isMobile ? 'body1' : 'testimonialMobile'}>
                            Paid believes that procurement has a unique opportunity to drive
                            business sustainability targets. They want to lead the industry and add
                            new value to their customers through their core product.
                        </Typography>
                        <Typography variant={isMobile ? 'body1' : 'testimonialMobile'}>
                            Paid now automatically calculates the emissions from long-tail spend,
                            making it easy to automatically offset those emissions.
                        </Typography>
                        <Stack spacing={1}>
                            <CarbonOffsetChip color={'#D5F2D0'} />
                            <EmmissionCalculationsChip color={'#D5F2D0'} />
                        </Stack>
                    </Stack>
                </Box>,
            ]}
            right={[
                <Box key={0}>
                    <PaidQuoteCard />
                </Box>,
            ]}
        />
    )
}

export default ProcurementTabSection
