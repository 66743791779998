import StandardLayout from '../components/StandardLayout'
import * as React from 'react'
import Typography from '@mui/material/Typography'
import useIsMobile from '../hooks/useIsMobile'
import Box from '@mui/material/Box'
import { StaticImage } from 'gatsby-plugin-image'
import StatementsWithBigIcons from './StatementsWithBigIcons'
import Stack from '@mui/material/Stack'
import XlContainer from '../components/XlContainer'
import StaticImageWrapper from '../components/StaticImageWrapper'

const CrookedGradientBackgroundSectionAbout = () => {
    const isMobile = useIsMobile()

    return (
        <Box
            sx={{
                height: isMobile ? `initial` : `1400px`,
                position: `relative`,
            }}
            my={{
                xs: 5,
                sm: 15,
                md: 15,
                lg: 20,
                xl: 35,
            }}
            mb={{
                xs: 5,
                sm: 0,
                md: 0,
                lg: 0,
                xl: 15,
            }}
        >
            <StaticImage
                style={{
                    height: `100%`,
                    width: `100%`,
                    clipPath: `polygon(100% 0, 100% 75%, 0 100%, 0 25%)`,
                    position: `absolute`,
                    top: 0,
                    left: 0,
                    zIndex: 0,
                }}
                src="../images/Original.png"
                alt={``}
            />
            <Box
                sx={{
                    position: `relative`,
                    zIndex: 999,
                    height: `100%`,
                }}
            >
                <XlContainer>
                    <Stack
                        spacing={{
                            xs: 5,
                            sm: 15,
                            md: 15,
                            lg: 15,
                            xl: 15,
                        }}
                    >
                        <StandardLayout>
                            <Typography
                                variant="h3"
                                sx={{
                                    textAlign: {
                                        xs: `left`,
                                        sm: `center`,
                                        md: `center`,
                                        lg: `center`,
                                        xl: `center`,
                                    },
                                    mt: {
                                        xs: 0,
                                        sm: 12,
                                        md: 12,
                                        lg: 12,
                                        xl: 12,
                                    },
                                }}
                            >
                                We are a remote-first team <br /> with an office in London.
                            </Typography>
                        </StandardLayout>
                        <StandardLayout>
                            <StatementsWithBigIcons
                                smallIcons
                                statements={[
                                    {
                                        iconNode: (
                                            <Typography
                                                padding={0}
                                                variant={isMobile ? `h4` : `h2`}
                                            >
                                                12
                                            </Typography>
                                        ),
                                        title: `nationalities`,
                                    },
                                    {
                                        iconNode: (
                                            <Typography
                                                padding={0}
                                                variant={isMobile ? `h4` : `h2`}
                                            >
                                                14
                                            </Typography>
                                        ),
                                        title: `languages`,
                                    },
                                    {
                                        iconNode: (
                                            <Typography variant={isMobile ? `h4` : `h2`}>
                                                37
                                            </Typography>
                                        ),
                                        title: `hobbies`,
                                    },
                                    {
                                        iconNode: (
                                            <Typography variant={isMobile ? `h4` : `h2`}>
                                                9
                                            </Typography>
                                        ),
                                        title: `pets`,
                                    },
                                ]}
                            />
                        </StandardLayout>
                        <Box
                            sx={{
                                display: `flex`,
                                justifyContent: `center`,
                                width: `100%`,
                            }}
                        >
                            <StaticImageWrapper
                                placeholder={
                                    'https://assets.lune.co/lune-co/placeholders/about-people.svg'
                                }
                            >
                                <StaticImage
                                    placeholder={'dominantColor'}
                                    src={'../images/PeopleGridSept23.png'}
                                    alt={'People'}
                                    formats={[`auto`]}
                                />
                            </StaticImageWrapper>
                        </Box>
                    </Stack>
                </XlContainer>
            </Box>
        </Box>
    )
}

export default CrookedGradientBackgroundSectionAbout
