import * as React from 'react'
import Box from '@mui/material/Box'

import HideRevealToggleButton from 'components/HideRevealToggleButton'
import Typography from '@mui/material/Typography'
import StandardLayout from 'components/StandardLayout'
import MobileNavItem from './MobileNavItem'

const MENU_ITEM_HEIGHT = '64px'

const MobileSubNav = () => {
    const [open, setOpen] = React.useState(false)
    return (
        <Box
            sx={{
                // 320px -> 5 * 64px per menu item
                height: open ? '320px' : '64px',
                borderTop: '1px solid',
                borderBottom: '1px solid',
                borderColor: `Grey300`,
                overflow: 'hidden',
                transition: 'height .3s cubic-bezier(0.4, 0, 1, 1)',
                mt: 10,
                mb: 10,
            }}
        >
            <Box
                sx={{
                    height: MENU_ITEM_HEIGHT,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
                onClick={() => setOpen(!open)}
            >
                <StandardLayout sx={{ width: '100%' }}>
                    <Box
                        sx={{
                            width: '100%',
                            justifyContent: 'space-between',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            variant="button"
                            sx={{
                                height: MENU_ITEM_HEIGHT,
                                color: `Grey900`,
                                whiteSpace: 'nowrap',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            Jump to
                        </Typography>
                        <Box
                            sx={{
                                padding: '0 16px',
                                alignItems: 'center',
                                display: 'flex',
                            }}
                        >
                            <HideRevealToggleButton isOpen={open} />
                        </Box>
                    </Box>
                </StandardLayout>
            </Box>
            <Box
                sx={{
                    width: `100%`,
                }}
            >
                <StandardLayout
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        paddingRight: '0 !important',
                    }}
                >
                    <MobileNavItem to={`logistics`} text={`Logistics`} />
                    <MobileNavItem to={`procurement`} text={`Procurement`} />
                    <MobileNavItem to={`paymentsAndBanking`} text={`Payments & Banking`} />
                    <MobileNavItem to={`buyCarbonCredits`} text={`Buy Carbon Credits`} />
                </StandardLayout>
            </Box>
        </Box>
    )
}

export default MobileSubNav
