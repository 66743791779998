import React from 'react'
import QuoteCardSimple from 'components/QuoteCardSimple'

const VestreQuoteCardSimple = () => {
    return (
        <QuoteCardSimple
            text={
                'Portfolio of permanent carbon removal projects, including Direct Air Capture, Biochar and Concrete Mineralisation.'
            }
            logo={'https://assets.lune.co/lune-co/client-logos/Vestre.png'}
            backgroundColor={'#FFED00'}
        />
    )
}

export default VestreQuoteCardSimple
