import React from 'react'
import TwoColumnLayout, { ColumnWrapOrder } from 'components/TwoColumnLayout'
import Line from 'components/Line'
import SectionLink from 'components/SectionLink'
import H3 from 'components/H3'
import Box from '@mui/material/Box'
import SectionBody1 from 'components/SectionBody1'
import RetailAndPaymentsAnimation from '../components/RetailAndPaymentsAnimation'
import ForGTM from '../components/ForGTM'

const MessagingSection = () => {
    return (
        <TwoColumnLayout columnWrapOrderOnMobile={ColumnWrapOrder.REVERSE} leftCol={5} rightCol={6}>
            <>
                <ForGTM
                    sx={{
                        mb: {
                            xs: 4,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                    }}
                />
                <H3>Bring climate impact into the conversation</H3>
                <SectionBody1>
                    Create stronger relationships with your customers by safely bringing climate and
                    emissions insights into your customer experience using Lune’s best practice
                    go-to-market messaging guidance.
                </SectionBody1>

                <Line />

                <SectionLink
                    text={'Request access today!'}
                    to={'https://dashboard.lune.co/signup'}
                    onClick={() => gtag('event', 'request_access', {})}
                    newPage
                />
            </>
            <Box display="flex" justifyContent="flex-end">
                <RetailAndPaymentsAnimation />
            </Box>
        </TwoColumnLayout>
    )
}

export default MessagingSection
