import React from 'react'
import { Lottie } from './Lottie'
import Box from '@mui/material/Box'
import useIsMobile from '../hooks/useIsMobile'

const RetailAndPaymentsAnimation = () => {
    const isMobile = useIsMobile()

    const additionalStyle = isMobile
        ? {
              width: '419px',
              height: '400px',
          }
        : {
              width: '628px',
              height: '620px',
          }

    const effects = (anim: AminationItem) => {
        anim.onComplete = () => {
            const direction = -anim.playDirection
            anim.setDirection(direction)
            anim.play()
        }
    }

    return (
        <Box style={{ position: 'relative', overflow: 'visible', ...additionalStyle }}>
            <Lottie
                loop={false}
                path={'/animations/retail_and_payments_animation.json'}
                style={{
                    position: 'absolute',
                    ...additionalStyle,
                }}
                rendererSettings={{
                    preserveAspectRatio: 'xMinYMid slice',
                }}
                effects={effects}
            />
        </Box>
    )
}

export default RetailAndPaymentsAnimation
