import camelCaseKeys from 'camelcase-keys'
import React from 'react'
import { Media } from 'lune-ui-lib'
import { Box } from '@mui/material'

const getImg = (src: string, mobile: boolean) => {
    return (
        <Box
            sx={{
                width: 'unset',
                aspectRatio: mobile ? '20 / 13' : '4 / 5',
                height: '100%',
                borderRadius: '48px',
                overflow: 'hidden',
            }}
        >
            <Media
                sx={{
                    '.placeholder-image, .main-media': {
                        width: mobile ? '100%' : 'auto',
                        maxWidth: 'unset',
                        height: '100%',
                    },
                }}
                alt={`Project image`}
                type={'image'}
                src={src}
                placeholderSrc={'https://assets.lune.co/lune-co/placeholders/projects-project.svg'}
            />
        </Box>
    )
}

const projects = [
    {
        id: 'qdk23N8xjQLEnvY0rAgX79RB4zDKOlor',
        name: 'Charm Industrial',
        short_name: 'Charm Industrial',
        description:
            'Charm uses plants to capture CO2 from the atmosphere. The company converts waste biomass into bio-oil through fast pyrolysis. That means heating things like plant waste up to 500℃ for a few seconds in an oxygen-free chamber.\r\n\r\nThe stable, carbon-rich liquid is injected deep underground through EPA-regulated injection wells, where the bio-oil sinks and solidifies in place for permanent storage. This removes CO2 permanently from the atmosphere, out of reach of wildfires, soil erosion and political folly.',
        slug: 'charm-industrial',
        project_type: 'Biochar',
        registry_name: '',
        registry_link: 'https://charmindustrial.com/registry',
        latitude: 37.671713,
        longitude: -96.981268,
        country_name: 'United States of America',
        country_code: 'USA',
        region: null,
        logo: 'https://assets.lune.co/projects/project-images-v1/charm-industrial/charm-industrial-logo.png',
        primary_image: 'https://assets.lune.co/projects/charm-industrial.jpeg',
        thumbnail_image: 'https://assets.lune.co/projects/charm-industrial-thumb.jpeg',
        results: [
            'Permanently removed 5,000 tCO2 in 2021 – the largest permanent removal by any one company at the time\r',
            'Highest levels of additionality\r',
            'Uniquely transparent lifecycle assessment with measurement, reporting and verification\r',
            'Improved financial health for farmers\r',
            'Improved air quality in rural communities where excess biomass is often burned',
        ],
        un_sdg: [8, 9, 13],
        disabled: false,
        media: [
            {
                type: 'image',
                url: 'https://assets.lune.co/projects/project-images-v1/charm-industrial/charm-industrial-oil.jpg',
                attribution_text: 'charmindustrial.com',
                attribution_url: 'https://www.charmindustrial.com',
            },
            {
                type: 'image',
                url: 'https://assets.lune.co/projects/project-images-v1/charm-industrial/charm-industrial-silo.jpg',
                attribution_text: 'charmindustrial.com',
                attribution_url: 'https://www.charmindustrial.com',
            },
            {
                type: 'image',
                url: 'https://assets.lune.co/projects/project-images-v1/charm-industrial/charm-industrial-lauren.jpg',
                attribution_text: 'charmindustrial.com',
                attribution_url: 'https://www.charmindustrial.com',
            },
            {
                type: 'image',
                url: 'https://assets.lune.co/projects/project-images-v1/charm-industrial/charm-industrial-wood.jpeg',
                attribution_text: 'charmindustrial.com',
                attribution_url: 'https://www.charmindustrial.com',
            },
            {
                type: 'video',
                url: 'https://assets.lune.co/projects/project-images-v1/charm-industrial/charm-industrial-video.mp4',
                attribution_text: 'charmindustrial.com',
                attribution_url: 'https://www.charmindustrial.com',
            },
        ],
        bundles: [
            {
                id: 'GVxg3b7MoBkrNQA3eYnvmw1J8a6LqjE9',
                name: 'Bio-Oil Sequestration',
                unit_price: '529.46',
                gross_unit_price: '588.29',
                currency: 'GBP',
                background_colour: '#003F7F',
                primary_image: 'https://assets.lune.co/bundles/bio-oil-bundle.png',
                primary_image_hires: 'https://assets.lune.co/bundles/bio-oil-bundle.png',
                small_thumbnail: null,
                description:
                    'Waste biomass from agriculture is converted into a stable, carbon-rich liquid and then pumped deep underground. This removes CO2 permanently from the atmosphere.',
                disabled: false,
                available_quantity: '5000',
                offset_type: 'carbon_removal',
                carbon_permanence: 'long_term',
            },
        ],
        resultsWithIcons: [
            {
                icon: 'co2',
                text: 'Permanently removed 5,000 tCO2 in 2021 – the largest permanent removal by any one company at the time\r',
            },
            { icon: 'addCircle', text: 'Highest levels of additionality\r' },
            {
                icon: 'inventory',
                text: 'Uniquely transparent lifecycle assessment with measurement, reporting and verification\r',
            },
            { icon: 'volunteerActivism', text: 'Improved financial health for farmers\r' },
            {
                icon: 'air',
                text: 'Improved air quality in rural communities where excess biomass is often burned',
            },
        ],
        img: {
            m: getImg(
                'https://assets.lune.co/projects/project-images-v1/charm-industrial/charm-industrial-oil.jpg',
                true,
            ),
            d: getImg(
                'https://assets.lune.co/projects/project-images-v1/charm-industrial/charm-industrial-oil.jpg',
                false,
            ),
        },
    },
    {
        id: 'DVndoQ0PZjGMzvYOWY6kbgN1eOJx9B82',
        name: 'UNDO',
        short_name: 'UNDO',
        description:
            'UNDO focuses on permanently removing CO2 from the atmosphere through enhanced weathering. Enhanced weathering is the acceleration of natural rock weathering, a geological process where CO2 is absorbed in rocks when they come into contact with rain. \r\n\r\nUNDO accelerates the natural reaction between basalt rocks and CO2 by more than 100x. The company works with partners who provide the rock, then spread it on agricultural land, where it acts as a natural fertilizer. Because the crushed rock is a by-product of an existing industry, the method is highly scalable and energy-efficient. The project will fully sequester CO2 over a period of 20 years, instead of thousands of years.',
        slug: 'undo',
        project_type: 'Mineralisation',
        registry_name: '',
        registry_link: null,
        latitude: 56.533453,
        longitude: -5.977094,
        country_name: 'United Kingdom',
        country_code: 'GBR',
        region: 'Scotland',
        logo: 'https://assets.lune.co/projects/project-images-v1/undo/undo-logo.png',
        primary_image: 'https://assets.lune.co/projects/ffc-ew-1.jpg',
        thumbnail_image: 'https://assets.lune.co/projects/ffc-ew-1-thumbnail.jpg',
        results: [
            'UNDO aims to be the first company to permanently remove 1 million tCO2 from the atmosphere\r',
            'Scalable and permanent carbon removal\r',
            'Highest levels of additionality\r',
            'Improved soil health and reduced need for fertilizers\r',
            'Slowed ocean acidification which improves coral reefs and fisheries',
        ],
        un_sdg: [6, 13, 14, 15],
        disabled: false,
        media: [
            {
                type: 'image',
                url: 'https://assets.lune.co/projects/project-images-v1/undo/undo-field-aerial.png',
                attribution_text: 'un-do.com',
                attribution_url: 'https://www.un-do.com',
            },
            {
                type: 'image',
                url: 'https://assets.lune.co/projects/project-images-v1/undo/undo-dirt-mount.png',
                attribution_text: 'un-do.com',
                attribution_url: 'https://www.un-do.com',
            },
            {
                type: 'video',
                url: 'https://assets.lune.co/projects/project-images-v1/undo/undo-weathering-explainer.mp4',
                attribution_text: 'un-do.com',
                attribution_url: 'https://www.un-do.com',
            },
            {
                type: 'image',
                url: 'https://assets.lune.co/projects/project-images-v1/undo/undo-tractor.png',
                attribution_text: 'un-do.com',
                attribution_url: 'https://www.un-do.com',
            },
            {
                type: 'image',
                url: 'https://assets.lune.co/projects/project-images-v1/undo/undo-sprout.jpg',
                attribution_text: 'un-do.com',
                attribution_url: 'https://www.un-do.com',
            },
        ],
        bundles: [
            {
                id: 'DVndoQ0PZjGMzvYOWY6kbgN1eOJx9B82',
                name: 'Enhanced Weathering',
                unit_price: '215',
                gross_unit_price: '238.89',
                currency: 'GBP',
                background_colour: '#6A5750',
                primary_image: 'https://assets.lune.co/bundles/enhanced-weathering.png',
                primary_image_hires: 'https://assets.lune.co/bundles/enhanced-weathering.png',
                small_thumbnail: null,
                description:
                    'Enhanced Weathering is a highly scalable nature based solution, permanently removing CO2 from the atmosphere. When it rains, carbon from the rain is locked in rocks through mineralisation.',
                disabled: false,
                available_quantity: '20000',
                offset_type: 'carbon_removal',
                carbon_permanence: 'long_term',
            },
        ],
        resultsWithIcons: [
            {
                icon: 'co2',
                text: 'UNDO aims to be the first company to permanently remove 1 million tCO2 from the atmosphere\r',
            },
            { icon: 'nature', text: 'Scalable and permanent carbon removal\r' },
            { icon: 'addCircle', text: 'Highest levels of additionality\r' },
            {
                icon: 'volunteerActivism',
                text: 'Improved soil health and reduced need for fertilizers\r',
            },
            {
                icon: 'air',
                text: 'Slowed ocean acidification which improves coral reefs and fisheries',
            },
        ],
        img: {
            m: getImg(
                'https://assets.lune.co/projects/project-images-v1/undo/undo-tractor.png',
                true,
            ),
            d: getImg(
                'https://assets.lune.co/projects/project-images-v1/undo/undo-tractor.png',
                false,
            ),
        },
    },
    {
        id: '15xRmEXZq0NnJLpPnydPr7akGOjV9g3z',
        name: 'Running Tide',
        short_name: 'Running Tide',
        description:
            'Running Tide is a global ocean health company on a mission to rebalance the carbon cycle, restore marine ecosystems, and revitalise coastal communities. Their multi-pathway carbon removal system uses cutting-edge technology to amplify the natural processes of the ocean that remove carbon from the fast cycle to the slow cycle. \r\n\r\nThe company deploys buoys made of biomass and seeded with kelp into the ocean. The company deploys buoys made of terrestrial biomass (like forestry products) and seeded with macroalgae (like kelp) into the open ocean. The buoys are coated with natural alkaline minerals, such as calcium carbonate, to sequester carbon and combat ocean acidification through the scientifically validated process of ocean alkalinity enhancement. \r\n\r\nThe kelp captures carbon as it grows and, after an optimal period of time, the whole assembly sinks into the deep ocean where carbon will be safely stored for upwards of 1,000 years or more.',
        slug: 'running-tide',
        project_type: 'Blue Carbon',
        registry_name: '',
        registry_link: null,
        latitude: 64.373401,
        longitude: -22.033189,
        country_name: 'Iceland',
        country_code: 'ISL',
        region: null,
        logo: 'https://assets.lune.co/projects/project-images-v1/running-tide/running-tide-logo.png',
        primary_image: 'https://assets.lune.co/projects/running-tide-1.jpg',
        thumbnail_image: 'https://assets.lune.co/projects/running-tide-1-thumb.jpg',
        results: [
            'The company aims to remove 1 million tCO2 by 2025\r',
            'Highest levels of additionality\r',
            'Using underutilised infrastructure\r',
            'Bringing quality employment to waterfront communities',
        ],
        un_sdg: [8, 9, 13, 14],
        disabled: false,
        media: [
            {
                type: 'image',
                url: 'https://assets.lune.co/projects/project-images-v1/running-tide/running-tide-seaweed.jpg',
                attribution_text: 'instagram.com/runningtide',
                attribution_url: 'https://www.instagram.com/p/CO5mw6ALJp8/?hl=en',
            },
            {
                type: 'image',
                url: 'https://assets.lune.co/projects/project-images-v1/running-tide/running-tide-kelp.jpg',
                attribution_text: 'instagram.com/runningtide',
                attribution_url: 'https://www.instagram.com/p/CNnx6jfrj_E/?hl=en',
            },
            {
                type: 'video',
                url: 'https://assets.lune.co/projects/project-images-v1/running-tide/running-tide-diver.mp4',
                attribution_text: 'instagram.com/runningtide',
                attribution_url: 'https://www.instagram.com/p/CP3x8LsHWt3/?hl=en',
            },
            {
                type: 'image',
                url: 'https://assets.lune.co/projects/project-images-v1/running-tide/running-tide-person-on-kelp.jpg',
                attribution_text: 'instagram.com/runningtide',
                attribution_url: 'https://www.instagram.com/p/CPl7fibrX-G/?hl=en',
            },
            {
                type: 'image',
                url: 'https://assets.lune.co/projects/project-images-v1/running-tide/running-tide-buoys.jpg',
                attribution_text: 'runningtide.com',
                attribution_url: 'https://www.runningtide.com',
            },
        ],
        bundles: [
            {
                id: 'xWaKJL3okjD46VpJ4yGXnQNZRe1vzP0w',
                name: 'Ocean Carbon Removal',
                unit_price: '220.78',
                gross_unit_price: '245.31',
                currency: 'GBP',
                background_colour: '#037E8A',
                primary_image: 'https://assets.lune.co/bundles/seaweed-sequestration.png',
                primary_image_hires: 'https://assets.lune.co/bundles/seaweed-sequestration.png',
                small_thumbnail: null,
                description:
                    "The ocean is the planet's most efficient carbon removal system. By harnessing fast-growing seaweed, CO2 is removed and permanently stored deep on the ocean bed.",
                disabled: false,
                available_quantity: '4913',
                offset_type: 'carbon_removal',
                carbon_permanence: 'long_term',
            },
        ],
        resultsWithIcons: [
            { icon: 'co2', text: 'The company aims to remove 1 million tCO2 by 2025\r' },
            { icon: 'addCircle', text: 'Highest levels of additionality\r' },
            { icon: 'aspectRatio', text: 'Using underutilised infrastructure\r' },
            {
                icon: 'volunteerActivism',
                text: 'Bringing quality employment to waterfront communities',
            },
        ],
        img: {
            m: getImg(
                'https://assets.lune.co/projects/project-images-v1/running-tide/running-tide-aerial-coast.png',
                true,
            ),
            d: getImg(
                'https://assets.lune.co/projects/project-images-v1/running-tide/running-tide-aerial-coast.png',
                false,
            ),
        },
    },
    {
        id: 'PkdBeZnaJxQ5G1ponY3Rb04KgmEwlWzN',
        name: 'Rimba Raya',
        short_name: 'Rimba Raya',
        description:
            'Rimba Raya, nearly the size of Singapore, protects one of the most highly endangered ecosystems in the world. Located on the southern coast of Borneo, in the province of Central Kalimantan, Indonesia, it was once designated for destruction and conversion into a palm oil plantation.\r\n\r\nThe project covers over 64,000 hectares of tropical peat forest and forms a vital patrolled buffer zone between the ever-encroaching bulldozers of the palm oil industry and the Tanjung Puting National Park. The area is home to several endangered animal species, including one of the last remaining wild populations of orangutans on earth, the Bornean Orangutan, whose population has declined over 95% in the last century.',
        slug: 'rimba-forest',
        project_type: 'Forest conservation',
        registry_name: 'Verra',
        registry_link: 'https://registry.verra.org/app/projectDetail/VCS/674',
        latitude: -3.182846,
        longitude: 112.2951,
        country_name: 'Indonesia',
        country_code: 'IDN',
        region: null,
        logo: 'https://assets.lune.co/projects/project-images-v1/rimba-raya/rimba-raya-logo.png',
        primary_image: 'https://assets.lune.co/projects/rimba-raya-1.png',
        thumbnail_image: 'https://assets.lune.co/projects/rimba-raya-1-thumbnail.png',
        results: [
            '3.5 million tonnes CO2 per year\r',
            'Improved job opportunities and education\r',
            'Community development programs focused on the welfare of women and children\r',
            'Protection of endangered plants and animal species',
        ],
        un_sdg: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
        disabled: false,
        media: [
            {
                type: 'image',
                url: 'https://assets.lune.co/projects/project-images-v1/rimba-raya/rimba-raya-forest-river.jpeg',
                attribution_text: 'facebook.com/RimbaRayaForest',
                attribution_url:
                    'https://www.facebook.com/RimbaRayaForest/photos/a.598247406909106/598247300242450',
            },
            {
                type: 'image',
                url: 'https://assets.lune.co/projects/project-images-v1/rimba-raya/rimba-raya-bird.png',
                attribution_text: 'facebook.com/RimbaRayaForest',
                attribution_url: 'https://www.facebook.com/RimbaRayaForest/photos/1657619684305201',
            },
            {
                type: 'image',
                url: 'https://assets.lune.co/projects/project-images-v1/rimba-raya/rimba-raya-tree.jpeg',
                attribution_text: 'facebook.com/RimbaRayaForest',
                attribution_url:
                    'https://www.facebook.com/RimbaRayaForest/photos/a.1150223111711530/1378612892205883',
            },
            {
                type: 'image',
                url: 'https://assets.lune.co/projects/project-images-v1/rimba-raya/rimba-raya-orangutan.png',
                attribution_text: 'facebook.com/RimbaRayaForest',
                attribution_url: 'https://www.facebook.com/RimbaRayaForest/photos/1843537602380074',
            },
            {
                type: 'image',
                url: 'https://assets.lune.co/projects/project-images-v1/rimba-raya/rimba-raya-insect.jpeg',
                attribution_text: 'facebook.com/RimbaRayaForest',
                attribution_url: 'https://www.facebook.com/RimbaRayaForest/photos/1500650503335454',
            },
        ],
        bundles: [
            {
                id: 'q9aKx7b6nNXMk3Yv3pD1mlW5Od2eLZE8',
                name: 'Conserving forests in Asia',
                unit_price: '11.29',
                gross_unit_price: '12.54',
                currency: 'GBP',
                background_colour: '#012C08',
                primary_image: 'https://assets.lune.co/bundles/asia-forestry.png',
                primary_image_hires: 'https://assets.lune.co/bundles/asia-forestry.png',
                small_thumbnail: null,
                description:
                    'Large-scale forest protection and conservation projects in countries like Indonesia and Cambodia, resulting in reduced and avoided emissions while improving the lives of local communities.',
                disabled: false,
                available_quantity: '19610.744204',
                offset_type: 'emissions_reduction',
                carbon_permanence: 'short_term',
            },
        ],
        resultsWithIcons: [
            { icon: 'co2', text: '3.5 million tonnes CO2 per year\r' },
            { icon: 'inventory', text: 'Improved job opportunities and education\r' },
            {
                icon: 'volunteerActivism',
                text: 'Community development programs focused on the welfare of women and children\r',
            },
            { icon: 'nature', text: 'Protection of endangered plants and animal species' },
        ],
        img: {
            m: getImg(
                'https://assets.lune.co/projects/project-images-v1/rimba-raya/rimba-raya-forest-river.jpeg',
                true,
            ),
            d: getImg(
                'https://assets.lune.co/projects/project-images-v1/rimba-raya/rimba-raya-forest-river.jpeg',
                false,
            ),
        },
    },
    {
        id: 'vQ3d1BKL7XqoJmY8vA8MN4PDaObzZEn0',
        name: 'Ackron Mixed',
        short_name: 'Ackron Mixed',
        description:
            'The Ackron Mixed project is located in northern Scotland and is one of the oldest projects in the UK Woodland Carbon Code. The land was previously used for sheep grazing before the 47 ha area was designated for afforestation, consisting of a mix of native broadleaf trees and conifer, well-suited for the local climate. Spacing was left intentionally for UK Priority species, including Black Grouse and Short-eared Owls.\r\n\r\nIn addition to the official verification, the project has undergone a technology-enabled verification by Treeconomy, using satellite imagery and LiDAR sensors to accurately measure the forest carbon stock over time. The project is one of the very few in the UK that has not pre-sold future credits and is now selling certified ex-post WCU credits, representing a rare source of high-quality nature-based offset credits.',
        slug: 'ackron-mixed',
        project_type: 'Afforestation',
        registry_name: 'Woodland Carbon Code',
        registry_link:
            'https://mer.markit.com/br-reg/public/project.jsp?project_id=103000000004596',
        latitude: 58.542446,
        longitude: -3.882732,
        country_name: 'United Kingdom',
        country_code: 'GBR',
        region: null,
        logo: 'https://assets.lune.co/projects/project-images-v1/ackron-mixed/ackron-mixed-logo.png',
        primary_image: 'https://assets.lune.co/projects/ackron-2.png',
        thumbnail_image: 'https://assets.lune.co/projects/ackron-thumb.png',
        results: [
            '29,000 tCO2 removed over the project lifetime\r',
            'Increased biodiversity and protection for UK Priority species\r',
            'Restored land use and regenerated natural ecosystems\r',
            'Improved local quality of air',
        ],
        un_sdg: [13, 15],
        disabled: false,
        media: [
            {
                type: 'image',
                url: 'https://assets.lune.co/projects/project-images-v1/ackron-mixed/ackron-mixed-forest.jpeg',
                attribution_text: 'treeconomy.co',
                attribution_url:
                    'https://www.treeconomy.co/blog/ackron-case-study-measuring-woodland-carbon',
            },
            {
                type: 'image',
                url: 'https://assets.lune.co/projects/project-images-v1/ackron-mixed/ackron-mixed-marsh.jpeg',
                attribution_text: 'treeconomy.co',
                attribution_url:
                    'https://www.treeconomy.co/blog/ackron-case-study-measuring-woodland-carbon',
            },
            {
                type: 'image',
                url: 'https://assets.lune.co/projects/project-images-v1/ackron-mixed/ackron-mixed-area.png',
                attribution_text: 'treeconomy.co',
                attribution_url:
                    'https://www.treeconomy.co/blog/ackron-case-study-measuring-woodland-carbon',
            },
        ],
        bundles: [
            {
                id: 'X90ng23WKvLqbkpM4YMBD4wJQrV6O75m',
                name: 'Reforesting the United Kingdom',
                unit_price: '70',
                gross_unit_price: '77.78',
                currency: 'GBP',
                background_colour: '#003110',
                primary_image: 'https://assets.lune.co/bundles/uk-afforestation.png',
                primary_image_hires: 'https://assets.lune.co/bundles/uk-afforestation.png',
                small_thumbnail: null,
                description:
                    'Projects reforesting degraded lands across the United Kingdom, resulting in carbon removed from the atmosphere and several co-benefits.',
                disabled: false,
                available_quantity: '81',
                offset_type: 'carbon_removal',
                carbon_permanence: 'short_term',
            },
        ],
        resultsWithIcons: [
            { icon: 'co2', text: '29,000 tCO2 removed over the project lifetime\r' },
            {
                icon: 'volunteerActivism',
                text: 'Increased biodiversity and protection for UK Priority species\r',
            },
            { icon: 'forest', text: 'Restored land use and regenerated natural ecosystems\r' },
            { icon: 'air', text: 'Improved local quality of air' },
        ],
        img: {
            m: getImg(
                'https://assets.lune.co/projects/project-images-v1/ackron-mixed/ackron-mixed-forest.jpeg',
                true,
            ),
            d: getImg(
                'https://assets.lune.co/projects/project-images-v1/ackron-mixed/ackron-mixed-forest.jpeg',
                false,
            ),
        },
    },
]

export default camelCaseKeys(
    projects.map((p) => ({
        ...camelCaseKeys(p, { deep: true }),
        img: p.img,
    })),
)
