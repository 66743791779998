import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import StaticImageWrapper from 'components/StaticImageWrapper'
import useIsMobile from 'hooks/useIsMobile'
import { RenderOnDesktop, RenderOnMobile } from './DeviceUtils'

const CustomerBanner = ({
    image,
    mobileImage,
    customerLogo,
    text,
    logoHeight,
}: {
    image: JSX.Element
    mobileImage: JSX.Element
    customerLogo: string
    text: string
    logoHeight?: string
}) => {
    const isMobile = useIsMobile()
    return (
        <Box sx={{ py: 10 }}>
            <Stack spacing={0} direction="row" alignItems={'center'} justifyContent={'flex-start'}>
                <StaticImageWrapper
                    sx={
                        isMobile
                            ? { maxWidth: 'max-content', marginLeft: -22.5 }
                            : { maxWidth: 'max-content' }
                    }
                    genericPlaceholder
                >
                    <RenderOnMobile>{mobileImage}</RenderOnMobile>
                    <RenderOnDesktop>{image}</RenderOnDesktop>
                </StaticImageWrapper>
                <Box
                    sx={
                        isMobile
                            ? { position: 'absolute', left: 0, px: 3, zIndex: 1 }
                            : { ml: -9, zIndex: 1 }
                    }
                >
                    <Box pb={2}>
                        <img height={logoHeight ?? '24px'} src={customerLogo} />
                    </Box>
                    <Typography
                        color={'Grey900'}
                        fontSize={isMobile ? '36px' : '64px'}
                        fontWeight={400}
                        lineHeight={isMobile ? '43.2px' : '76.8px'}
                    >
                        {text}
                    </Typography>
                </Box>
            </Stack>
        </Box>
    )
}

export default CustomerBanner
