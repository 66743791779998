import React from 'react'
import { Box } from '@mui/material'
import { Button } from 'lune-ui-lib'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Transition } from 'react-transition-group'
import projects from 'views/ProjectsCarousel/projectsData'
import SingleProjectCarouselView from 'views/ProjectsCarousel/SingleProjectCarouselView'
import XlContainer from 'components/XlContainer'
import StandardLayout from 'components/StandardLayout'
import { useTheme } from '@mui/material/styles'
import useIsMobile from 'hooks/useIsMobile'

const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
} as any

const ProjectsCarousel = () => {
    const isMobile = useIsMobile()
    const [projectIndex, setProjectIndex] = React.useState(0)
    const theme = useTheme()

    const controlButtonStyles = {
        position: 'initial',
        border: `none`,
        padding: `0`,
        width: isMobile ? `32px` : `48px`,
        height: isMobile ? `32px` : `48px`,
        background: `${(theme.palette as any).Grey200}`,
        color: `blue`,
        '&:hover': {
            background: `${(theme.palette as any).Grey300}`,
            border: `none`,
        },
        '& svg': {
            color: `${(theme.palette as any).Grey400}`,
        },
        '.MuiTouchRipple-child': {
            backgroundColor: `${(theme.palette as any).Grey600}`,
        },
    }

    const buttonWrapperSpacing = {
        xs: theme.spacing(6),
        sm: theme.spacing(9),
        md: theme.spacing(18),
        lg: theme.spacing(18),
        // 1248 is the maxWidth of the xl container
        xl: `calc((100% - 1248px)/2)`,
    }

    const controlButtonWrapperStyles = {
        position: `absolute`,
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        height: `100%`,
        '&:hover': {
            cursor: `pointer`,
            '& > button': {
                background: `${(theme.palette as any).Grey300}`,
            },
        },
    }

    const handlePrev = () =>
        setProjectIndex((p) => {
            const nextIndex = p + 1
            return nextIndex === projects.length ? 0 : nextIndex
        })

    const handleNext = () =>
        setProjectIndex((p) => {
            const nextIndex = p - 1
            return nextIndex < 0 ? projects.length - 1 : nextIndex
        })

    const prevBtn = (
        <Button
            // eslint-disable-next-line
            // @ts-ignore
            disableRipple
            iconButton
            sx={{
                ...controlButtonStyles,
                ...(isMobile && { left: `36px` }),
            }}
            rightIcon={
                <NavigateBeforeIcon
                    sx={{
                        background: `reed`,
                        color: `Grey900`,
                    }}
                />
            }
            variant="outlined"
        />
    )

    const nextBtn = (
        <Button
            // eslint-disable-next-line
            // @ts-ignore
            disableRipple
            iconButton
            sx={{
                ...controlButtonStyles,
                ...(isMobile && { right: `36px` }),
            }}
            rightIcon={
                <NavigateNextIcon
                    sx={{
                        background: `reed`,
                        color: `Grey900`,
                    }}
                />
            }
            variant="outlined"
        />
    )

    return (
        <Box
            sx={{
                position: `relative`,
                display: `flex`,
                alignItems: `center`,
            }}
        >
            <XlContainer>
                <StandardLayout
                    pt={{
                        xs: 10,
                        sm: 15,
                        md: 15,
                        lg: 15,
                        xl: 15,
                    }}
                    pb={{
                        xs: 0,
                        sm: 15,
                        md: 15,
                        lg: 15,
                        xl: 15,
                    }}
                >
                    <Box
                        id={`projects-carousel`}
                        sx={{
                            position: `relative`,
                            display: `flex`,
                            alignItems: `center`,
                        }}
                        height={{
                            xs: `initial`,
                            sm: 960,
                            md: 800,
                            lg: 780,
                            xl: 600,
                        }}
                    >
                        {projects.map((project, index) => {
                            return (
                                <Transition
                                    in={index === projectIndex}
                                    timeout={300}
                                    key={project.id}
                                >
                                    {(state) => (
                                        <div
                                            style={{
                                                position:
                                                    index === projectIndex ? `static` : `absolute`,
                                                visibility:
                                                    index === projectIndex ? `visible` : `hidden`,
                                                transition: `opacity 300ms ease-in-out`,
                                                opacity: 0,
                                                ...transitionStyles[state],
                                            }}
                                        >
                                            <SingleProjectCarouselView
                                                project={project}
                                                handles={{
                                                    handlePrev,
                                                    handleNext,
                                                }}
                                                controls={{
                                                    prevBtn,
                                                    nextBtn,
                                                }}
                                            />
                                        </div>
                                    )}
                                </Transition>
                            )
                        })}
                    </Box>
                </StandardLayout>
            </XlContainer>
            {!isMobile && (
                <>
                    <Box
                        onClick={handlePrev}
                        width={buttonWrapperSpacing}
                        sx={controlButtonWrapperStyles}
                    >
                        {prevBtn}
                    </Box>
                    <Box
                        onClick={handleNext}
                        sx={{
                            right: 0,
                            ...controlButtonWrapperStyles,
                        }}
                        width={buttonWrapperSpacing}
                    >
                        {nextBtn}
                    </Box>
                </>
            )}
        </Box>
    )
}

export default ProjectsCarousel
