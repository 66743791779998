import React from 'react'
import { Box } from '@mui/material'

export const LogoChip = ({ color, logoSrc }: { color: string; logoSrc: string }) => {
    return (
        <Box
            sx={{
                width: 'max-content',
                maxHeight: '40px',
                padding: '12px 16px',
                borderRadius: '48px',
                backgroundColor: color,
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <img height={14} alt={'logo'} src={logoSrc} />
        </Box>
    )
}
