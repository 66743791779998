import * as React from 'react'
import TabTwoColumnLayout from '../components/TabTwoColumnLayout'
import { Box } from '@mui/material'
import SectionBody1 from 'components/SectionBody1'
import Shadow from 'components/Shadow'
import Line from 'components/Line'
import SectionLink from 'components/SectionLink'
import H3 from 'components/H3'
import OverflowImage from 'components/OverflowImage'

import StaticImageWrapper from '../components/StaticImageWrapper'
import { StaticImage } from 'gatsby-plugin-image'

const FintechUseCasesSection = () => {
    return (
        <TabTwoColumnLayout
            labels={['Business to Business', 'Business to Consumer']}
            left={[
                <Box key={0}>
                    <H3>Empower your customers to make better decisions for the planet</H3>
                    <SectionBody1>
                        Convert the 85% of SMBs who want to understand and control the emissions
                        from their spend. Help them manage their Scope 3, which represent 80-90% of
                        total emissions.
                    </SectionBody1>

                    <Line />

                    <SectionLink
                        text={'View API integration guide'}
                        to={'https://docs.lune.co/guides/integrate-fintech'}
                        newPage
                    />
                </Box>,
                <Box key={1}>
                    <H3>Empower your customers to make better decisions for the planet</H3>
                    <SectionBody1>
                        Let consumers take control over their carbon footprint and make greener
                        choices by automatically calculating the emissions of any purchase.
                    </SectionBody1>

                    <Line />

                    <SectionLink
                        text={'View API integration guide'}
                        to={'https://docs.lune.co/guides/integrate-fintech'}
                        newPage
                    />
                </Box>,
            ]}
            right={[
                <OverflowImage shadow key={0}>
                    <StaticImageWrapper
                        placeholder={
                            'https://assets.lune.co/lune-co/placeholders/fintech-customers-b2b.svg'
                        }
                    >
                        <StaticImage
                            placeholder={'dominantColor'}
                            src={'../images/FintechB2B.png'}
                            alt={'B2B'}
                            layout="fixed"
                            objectPosition={'100% 0%'}
                            objectFit={'contain'}
                            quality={100}
                            style={{ width: 'unset', aspectRatio: '1714 / 1600', height: '100%' }}
                        />
                    </StaticImageWrapper>
                </OverflowImage>,
                <Shadow key={1}>
                    <StaticImageWrapper
                        placeholder={
                            'https://assets.lune.co/lune-co/placeholders/fintech-customers-b2c.svg'
                        }
                    >
                        <StaticImage
                            placeholder={'dominantColor'}
                            src={'../images/FitechB2C.png'}
                            alt={'B2C'}
                            layout="constrained"
                            height={868}
                            quality={100}
                        />
                    </StaticImageWrapper>
                </Shadow>,
            ]}
        />
    )
}

export default FintechUseCasesSection
