import React, { useState, useEffect, useRef, FC, ReactNode, useMemo } from 'react'
import { Box } from '@mui/system'

const CarouselShadow = ({ rotate }: { rotate?: boolean }) => {
    return useMemo(() => {
        return (
            <Box
                sx={{
                    position: 'absolute',
                    bottom: rotate ? '0' : 'auto',
                    top: rotate ? 'auto' : '0',
                    left: 0,
                    right: 0,
                    height: '120px',
                    transform: rotate ? 'rotate(180deg)' : 'none',
                    zIndex: 1,
                    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
                }}
            />
        )
    }, [rotate])
}

const VerticalCarousel: FC<{ cards: ReactNode[] }> = ({ cards }) => {
    const CAROUSEL_HEIGHT = 880
    const [isHovered, setIsHovered] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(1)
    const [transform, setTransform] = useState<number>(0)
    const [noTransition, setNoTransition] = useState<boolean>(false)

    // including extra cards to make the infinite loop effect
    cards = [cards[cards.length - 1], ...cards, cards[0], cards[1]]

    const elementsRefs = useRef([])
    // Function to store the ref for each element
    const storeRef = (index, ref) => {
        elementsRefs.current[index] = ref
    }

    const calcAndSetTransform = (index: number) => {
        const activeElementOffsetTop = (elementsRefs.current[index] as HTMLElement).offsetTop
        const activeElementHeight = (elementsRefs.current[index] as HTMLElement).offsetHeight
        const offset = (CAROUSEL_HEIGHT - activeElementHeight) / 2
        setTransform(activeElementOffsetTop - offset)
    }

    useEffect(() => {
        if (elementsRefs.current[0]) {
            calcAndSetTransform(1)
            setCurrentIndex(2)
            setNoTransition(false)
        }
    }, [elementsRefs])

    const moveCarousel = () => {
        calcAndSetTransform(currentIndex)
        const nextIndex = currentIndex + 1
        setCurrentIndex(nextIndex)
        setNoTransition(false)

        if (currentIndex === cards.length - 2) {
            setTimeout(() => {
                setNoTransition(true)
                calcAndSetTransform(1)
                setCurrentIndex(2)
            }, 300)
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!isHovered) {
                moveCarousel()
            }
        }, 5000)

        return () => clearTimeout(timer)
    }, [currentIndex, isHovered, cards])

    const handleMouseEnter = () => {
        setIsHovered(true)
    }

    const handleMouseLeave = () => {
        setIsHovered(false)
    }

    const carousel = useMemo(() => {
        return (
            <Box
                key={'animated-container'}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    transition: `transform ${noTransition ? '0' : '300ms'} ease`,
                    transform: `translate3d(0, -${transform}px, 0)`,
                    willChange: 'transform, opacity',
                    backfaceVisibility: 'hidden',
                    perspective: 1000,
                    gap: 3,
                }}
            >
                {cards.map((card, index) => {
                    return (
                        <Box
                            ref={(ref) => storeRef(index, ref)}
                            key={index}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                width: '468px',
                            }}
                        >
                            {card}
                        </Box>
                    )
                })}
            </Box>
        )
    }, [transform, cards])

    return (
        <Box
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{
                height: `${CAROUSEL_HEIGHT}px`,
                margin: 'auto',
                overflow: 'hidden',
                position: 'relative',
            }}
        >
            <CarouselShadow />
            {carousel}
            <CarouselShadow rotate={true} />
        </Box>
    )
}

export default VerticalCarousel
