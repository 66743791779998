import React, { useCallback, useState } from 'react'
import Box from '@mui/material/Box'
import StandardLayout from '../components/StandardLayout'
import XlContainer from '../components/XlContainer'
import ContactForm from 'views/ContactForm'
import Footer from 'views/Footer'
import useLogBreakpoints from 'hooks/useLogBreakpoints'
import { useTheme } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { StaticImage } from 'gatsby-plugin-image'
import useIsMobile from 'hooks/useIsMobile'
import EmpowerCustomersSection from 'views/EmpowerCustomersSection'
import ProcurementExponentialImpactSection from 'views/ProcurementExponentialImpactSection'
import ShareImpactBreezeSection from 'views/ShareImpactBreezeSection'
import TakeActionSection from 'views/TakeActionSection'
import { graphql, useStaticQuery } from 'gatsby'
import Testimonial from '../components/Testimonial'
import GreyGradient from 'components/GreyGradient'
import Page, { PageBodyWithoutNav } from '../components/Page'
import { WindowLocation } from '@reach/router'
import NavBar from '../views/NavBar/NavBar'
import HeroHeadingShared from '../views/HeroHeadingShared'
import { RenderOnDesktop, RenderOnMobile } from '../components/DeviceUtils'
import ProcurementHero from '../images/ProcurementHero.png'

const stackSpacing = {
    xs: 10,
    sm: 15,
    md: 15,
    lg: 15,
    xl: 15,
}

const PaymentsPage = ({ location }: { location: WindowLocation }) => {
    useLogBreakpoints()
    const [navBackgroundColor, setNavBackgroundColor] = useState<string>('transparent')
    const { spacing } = useTheme()
    const isMobile = useIsMobile()

    const onNavBackgroundColorChange = useCallback(
        (color: string) => {
            setNavBackgroundColor(color)
        },
        [navBackgroundColor],
    )

    return (
        <Page location={location} title="Lune - Procurement">
            <PageBodyWithoutNav>
                <Box
                    sx={{
                        position: `sticky`,
                        top: `0px`,
                        zIndex: `9999`,
                        backgroundColor: `${navBackgroundColor}`,
                    }}
                >
                    <XlContainer maxWidth="xl">
                        <Box
                            px={{
                                xs: 1,
                                sm: 2,
                                md: 2,
                                lg: 2,
                                xl: 2,
                            }}
                        >
                            <StandardLayout>
                                <NavBar
                                    backgroundColor={'transparent'}
                                    onBackgroundChange={onNavBackgroundColorChange}
                                />
                            </StandardLayout>
                        </Box>
                    </XlContainer>
                </Box>

                <Box
                    sx={{
                        ...(!isMobile
                            ? {
                                  marginTop: `-${spacing(9)}`, // move up the height of NavBar
                              }
                            : {}),
                    }}
                >
                    <GreyGradient hide={isMobile}>
                        <Box
                            sx={{
                                zIndex: '0',
                                overflow: 'hidden',
                            }}
                            mb={{
                                xs: 10,
                                sm: 15,
                                md: 15,
                                lg: 15,
                                xl: 15,
                            }}
                        >
                            <HeroHeadingShared
                                imageUrl={ProcurementHero}
                                placeholderImageUrl={
                                    'https://assets.lune.co/lune-co/placeholders/procurement-header.svg'
                                }
                            >
                                <Box
                                    pt={{
                                        xs: 9,
                                        sm: 18,
                                        md: 18,
                                        lg: 18,
                                        xl: 18,
                                    }}
                                >
                                    <StandardLayout>
                                        <XlContainer maxWidth="xl">
                                            <RenderOnMobile>
                                                <Typography
                                                    variant={`h2`}
                                                    component="div"
                                                    sx={{ textAlign: `left` }}
                                                >
                                                    Kickstart the journey towards sustainable supply
                                                    chains
                                                </Typography>
                                            </RenderOnMobile>
                                            <RenderOnDesktop>
                                                <Typography
                                                    variant={`h2`}
                                                    component="div"
                                                    sx={{ textAlign: `center` }}
                                                >
                                                    Kickstart the journey
                                                </Typography>
                                                <Typography
                                                    variant={`h2`}
                                                    component="div"
                                                    sx={{ textAlign: `center` }}
                                                >
                                                    towards sustainable supply chains
                                                </Typography>
                                            </RenderOnDesktop>
                                        </XlContainer>
                                    </StandardLayout>
                                </Box>
                            </HeroHeadingShared>
                        </Box>
                    </GreyGradient>

                    <Stack spacing={stackSpacing} sx={{ overflow: 'hidden' }}>
                        <Box sx={{ width: '100%' }}>
                            <XlContainer>
                                <Stack spacing={stackSpacing}>
                                    <GreyGradient hide={!isMobile}>
                                        <StandardLayout>
                                            <ProcurementExponentialImpactSection />
                                        </StandardLayout>
                                    </GreyGradient>

                                    <GreyGradient hide={!isMobile}>
                                        <StandardLayout>
                                            <EmpowerCustomersSection />
                                        </StandardLayout>
                                    </GreyGradient>

                                    <GreyGradient hide={!isMobile}>
                                        <StandardLayout>
                                            <TakeActionSection />
                                        </StandardLayout>
                                    </GreyGradient>

                                    <GreyGradient hide={!isMobile}>
                                        <StandardLayout>
                                            <ShareImpactBreezeSection inverted />
                                        </StandardLayout>
                                    </GreyGradient>

                                    <StandardLayout>
                                        <Testimonial
                                            text={
                                                'Integrating with Lune’s API has made it incredibly easy for us to bring a sustainable solution into our procurement platform – automating real-world climate impact as we automate procurement processes.'
                                            }
                                            profileName={'Graham Morley'}
                                            profileTitle={'Product Manager at Paid'}
                                            profileAvatar={
                                                <StaticImage
                                                    src="../images/GrahamMorley.png"
                                                    alt={`Graham Morley`}
                                                />
                                            }
                                            textColor={'White'}
                                            backgroundImageStaticQuery={useStaticQuery(graphql`
                                                query {
                                                    desktop: file(
                                                        relativePath: {
                                                            eq: "TestimonialProcurement.png"
                                                        }
                                                    ) {
                                                        childImageSharp {
                                                            fluid(quality: 100, maxWidth: 1920) {
                                                                ...GatsbyImageSharpFluid_withWebp
                                                            }
                                                        }
                                                    }
                                                }
                                            `)}
                                            whiteQuote
                                        />
                                    </StandardLayout>

                                    <StandardLayout>
                                        <ContactForm
                                            hubspotFormName="procurement"
                                            headers={[`Let's get started`]}
                                        />
                                    </StandardLayout>
                                    <Footer />
                                </Stack>
                            </XlContainer>
                        </Box>
                    </Stack>
                </Box>
            </PageBodyWithoutNav>
        </Page>
    )
}

export default PaymentsPage
