import React from 'react'
import Button from 'components/Button'
import FacebookIcon from '@mui/icons-material/Facebook'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const Btn = ({ icon, sx, title }: { icon: React.ReactNode; sx: any; title: string }) => (
    <Button
        sx={{
            ...sx,
            height: `48px`,
            px: `16px`,
            overflow: `visible`,
            display: `flex`,
            justifyContent: `center`,
        }}
    >
        <Box
            sx={{
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
            }}
        >
            {icon}{' '}
            <Typography
                variant="button"
                sx={{
                    ml: `8px`,
                    display: {
                        xs: 'none',
                        sm: 'initial',
                        md: 'initial',
                        lg: 'initial',
                        xl: 'initial',
                    },
                }}
            >
                {title}
            </Typography>
        </Box>
    </Button>
)

const SocialShareSection = ({ url }: { url: string }) => {
    console.log(url)
    return (
        <Box
            sx={{
                display: `flex`,
                justifyContent: `center`,
                flexWrap: `wrap`,
            }}
        >
            <a
                style={{
                    textDecoration: `none`,
                    marginRight: `8px`,
                }}
                href={`https://www.facebook.com/share.php?u=${url}`}
            >
                <Btn
                    title={`Facebook`}
                    icon={<FacebookIcon />}
                    sx={{
                        backgroundColor: `#1877F2`,
                    }}
                />
            </a>

            <a
                style={{
                    textDecoration: `none`,
                    marginRight: `8px`,
                }}
                href={`https://twitter.com/intent/tweet?url=${url}&text=Check this out!"`}
            >
                <Btn
                    title={`Twitter`}
                    icon={<TwitterIcon />}
                    sx={{
                        backgroundColor: `#1DA1F2`,
                    }}
                />
            </a>

            <a
                style={{
                    textDecoration: `none`,
                }}
                href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
            >
                <Btn
                    title={`LinkedIn`}
                    icon={<LinkedInIcon />}
                    sx={{
                        backgroundColor: `#0A66C2`,
                    }}
                />
            </a>
        </Box>
    )
}

export default SocialShareSection
