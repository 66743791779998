import React from 'react'
import TwoColumnLayout, { ColumnWrapOrder } from 'components/TwoColumnLayout'
import Box from '@mui/material/Box'
import Line from 'components/Line'
import H3 from 'components/H3'
import SectionBody1 from 'components/SectionBody1'
import SectionLink from 'components/SectionLink'
import RetailAndPaymentsAnimation from '../components/RetailAndPaymentsAnimation'

const InstagramSection = () => {
    return (
        <TwoColumnLayout columnWrapOrderOnMobile={ColumnWrapOrder.REVERSE}>
            <>
                <H3>Bring climate into supply chain conversations</H3>
                <SectionBody1>
                    Create stronger relationships with your customers by safely bringing climate and
                    emissions insights into everyday logistics conversation using Lune’s best
                    practice go-to-market messaging guidance.
                </SectionBody1>

                <Line />

                <SectionLink
                    text={'Request access'}
                    to={'https://dashboard.lune.co/signup'}
                    onClick={() => gtag('event', 'request_access', {})}
                    newPage
                />
            </>
            <Box display="flex" justifyContent="flex-end">
                <RetailAndPaymentsAnimation />
            </Box>
        </TwoColumnLayout>
    )
}

export default InstagramSection
