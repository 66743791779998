import React from 'react'
import Typography from '@mui/material/Typography'

const Body1 = (props: { children: React.ReactNode }) => {
    const { children } = props
    return (
        <Typography color={'Grey900'} variant={'body1'} component="div" {...props}>
            {children}
        </Typography>
    )
}

export default Body1
