import * as React from 'react'
import Box from '@mui/material/Box'
import XlContainer from 'components/XlContainer'
import StandardLayout from 'components/StandardLayout'
import ContactForm from 'views/ContactForm'
import Footer from 'views/Footer'
import useLogBreakpoints from 'hooks/useLogBreakpoints'
import Stack from '@mui/material/Stack'
import useIsMobile from 'hooks/useIsMobile'
import GreyGradient from 'components/GreyGradient'
import Page, { PageBody } from 'components/Page'
import { RenderOnDesktop, RenderOnMobile } from 'components/DeviceUtils'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import SubNavBar from 'views/Customers/CustomersSubNav/SubNavBar'
import Logistics from 'views/Customers/Sections/Logistics/Logistics'
import Procurement from 'views/Customers/Sections/Procurement/Procurement'
import PaymentsAndBanking from 'views/Customers/Sections/PaymentsAndBanking/PaymentsAndBanking'
import BuyCarbonCredits from 'views/Customers/Sections/BuyCarbonCredits/BuyCarbonCredits'
import { useState } from 'react'
import CustomerBanner from 'components/CustomerBanner'
import { StaticImage } from 'gatsby-plugin-image'
import HeroHeadingShared from 'views/HeroHeadingShared'
import CustomersHero from '../images/CustomersHero.png'

const stackSpacing = {
    xs: 10,
    sm: 15,
    md: 15,
    lg: 15,
    xl: 15,
}

const CustomersPage = ({ location }: { location?: string }) => {
    useLogBreakpoints()
    const { spacing } = useTheme()
    const isMobile = useIsMobile()
    const [sectionsContainer, setSectionsContainer] = useState<HTMLElement>(null)

    return (
        <Page location={location} title="Lune - Customers">
            <PageBody>
                <Box
                    sx={{
                        ...(!isMobile
                            ? {
                                  marginTop: `-${spacing(9)}`, // move up the height of NavBar
                              }
                            : {}),
                    }}
                >
                    <GreyGradient hide={isMobile}>
                        <Box
                            sx={{
                                zIndex: '0',
                                overflow: 'hidden',
                                margin: 0,
                            }}
                        >
                            <HeroHeadingShared
                                imageUrl={CustomersHero}
                                placeholderImageUrl="https://assets.lune.co/lune-co/placeholders/customers-hero-placeholder.svg"
                            >
                                <Box
                                    pt={{
                                        xs: 9,
                                        sm: 18,
                                        md: 18,
                                        lg: 18,
                                        xl: 18,
                                    }}
                                >
                                    <StandardLayout>
                                        <XlContainer>
                                            <RenderOnMobile>
                                                <Typography
                                                    variant={`h2`}
                                                    component="div"
                                                    sx={{ textAlign: `left` }}
                                                >
                                                    Our customers grow while protecting the planet
                                                </Typography>
                                            </RenderOnMobile>
                                            <RenderOnDesktop>
                                                <Typography
                                                    variant={`h2`}
                                                    component="div"
                                                    sx={{ textAlign: `center` }}
                                                >
                                                    Our customers grow
                                                </Typography>
                                                <Typography
                                                    variant={`h2`}
                                                    component="div"
                                                    sx={{ textAlign: `center` }}
                                                >
                                                    while protecting the planet
                                                </Typography>
                                            </RenderOnDesktop>
                                        </XlContainer>
                                    </StandardLayout>
                                </Box>
                            </HeroHeadingShared>
                        </Box>
                    </GreyGradient>
                    {/* // SUB NAVIGATION */}
                    <SubNavBar viewport={sectionsContainer} />
                    {/* // SECTIONS */}
                    <Stack
                        spacing={stackSpacing}
                        sx={{
                            overflow: 'hidden',
                            ...(!isMobile
                                ? {
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                  }
                                : {}),
                        }}
                    >
                        <XlContainer>
                            <StandardLayout sx={{ pt: stackSpacing }}>
                                <CustomerBanner
                                    image={
                                        <StaticImage
                                            placeholder={'dominantColor'}
                                            width={360}
                                            height={440}
                                            layout={'fixed'}
                                            src={'../images/customerBanners/salesforce-desktop.png'}
                                            alt={'building'}
                                            style={{ borderRadius: '32px' }}
                                        />
                                    }
                                    mobileImage={
                                        <StaticImage
                                            placeholder={'dominantColor'}
                                            width={360}
                                            height={440}
                                            layout={'fixed'}
                                            src={'../images/customerBanners/salesforce-mobile.png'}
                                            alt={'building'}
                                            style={{ borderRadius: '32px' }}
                                        />
                                    }
                                    customerLogo={
                                        'https://assets.lune.co/lune-co/client-logos/salesforce.png'
                                    }
                                    text="Connecting their thousands of customers to vetted carbon projects around the world"
                                    logoHeight="40px"
                                />
                            </StandardLayout>
                        </XlContainer>
                        <Box ref={(r) => setSectionsContainer(r)}>
                            <section id={'logistics'}>
                                <Logistics />
                            </section>
                            <section id={'procurement'}>
                                <Procurement />
                            </section>
                            <section id={'paymentsAndBanking'}>
                                <PaymentsAndBanking />
                            </section>
                            <section id={'buyCarbonCredits'}>
                                <BuyCarbonCredits />
                            </section>
                        </Box>
                        {/* // FOOTER */}
                        <Box sx={{ width: '100%' }}>
                            <XlContainer>
                                <Stack spacing={stackSpacing}>
                                    <StandardLayout>
                                        <ContactForm
                                            hubspotFormName={'customers'}
                                            headers={['Start building your competitive advantage']}
                                        />
                                    </StandardLayout>

                                    <Footer />
                                </Stack>
                            </XlContainer>
                        </Box>
                    </Stack>
                </Box>
            </PageBody>
        </Page>
    )
}

export default CustomersPage
