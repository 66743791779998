import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import QuoteCard from 'components/QuoteCard'

const IdeoQuoteCard = () => {
    return (
        <QuoteCard
            text={
                'Lune is our preferred partner due to the emphasis on quality, real impact, and transparency – something that is generally lacking in the carbon markets. We are committed to credible offsetting and, going forward, aim to increase our spend on permanent carbon removal projects in line with the Oxford Offsetting Principles.'
            }
            profileName={'Derek Robson'}
            profileTitle={'CEO at IDEO'}
            profileAvatar={
                <StaticImage
                    src="../../../../images/avatars/ideo-derek-robson.png"
                    alt={`Derek Robson`}
                />
            }
            backgroundImageStaticQuery={useStaticQuery(graphql`
                query {
                    desktop: file(relativePath: { eq: "customerQuoteCards/ideo.png" }) {
                        childImageSharp {
                            fluid(quality: 90, maxWidth: 500) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            `)}
        />
    )
}

export default IdeoQuoteCard
