import * as React from 'react'
import Button from '@mui/material/Button'
import { styled, useTheme } from '@mui/material/styles'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'

const CTAButton = ({ text, onClick }: { text: string; onClick: () => void }) => {
    const theme = useTheme()
    const BootstrapButton = styled(Button)({
        backgroundColor: `${(theme.palette as any).Grey900} !important`,
        color: `${(theme.palette as any).White} !important`,
        '&:hover': {
            backgroundColor: `${(theme.palette as any).Black} !important`,
            whiteSpace: 'nowrap',
        },
    })

    return (
        <BootstrapButton
            disableElevation
            variant="contained"
            onClick={onClick}
            sx={{
                width: {
                    xs: `100%`,
                    sm: `max-content`,
                    md: `max-content`,
                    lg: `max-content`,
                    xl: `max-content`,
                },
                height: `48px`,
                backgroundColor: `Grey900`,
                borderRadius: `40px`,
                whiteSpace: 'nowrap',
                '&:hover': {
                    boxShadow: 4,
                },
                '&:active': {
                    boxShadow: 6,
                },
            }}
            startIcon={
                <ArrowCircleRightIcon
                    sx={{
                        display: `flex`,
                        alignItems: `center`,
                        justifyContent: `center`,
                        width: `20px`,
                        height: `20px`,
                        borderRadius: `40px`,
                        backgroundColor: `Grey900`,
                        color: `White`,
                    }}
                />
            }
        >
            {text}
        </BootstrapButton>
    )
}

export default CTAButton
