import React from 'react'
import TwoColumnLayout, { ColumnWrapOrder } from 'components/TwoColumnLayout'
import Box from '@mui/material/Box'
import { RenderOnDesktop, RenderOnMobile } from 'components/DeviceUtils'
import VerticalCarousel from 'views/Customers/Components/VerticalCarousel'
import VerticalCarouselMobile from 'views/Customers/Components/VerticalCarouselMobile'
import { Typography } from '@mui/material'
import useIsMobile from 'hooks/useIsMobile'
import FortoQuoteCard from 'views/Customers/Sections/BuyCarbonCredits/FortoQuoteCard'
import GoCardlessQuoteCard from 'views/Customers/Sections/BuyCarbonCredits/GoCardlessQuoteCard'
import SalesforceQuoteCard from 'views/Customers/Sections/BuyCarbonCredits/SalesforceQuoteCard'
import VestreQuoteCardSimple from 'views/Customers/Sections/BuyCarbonCredits/VestreQuoteCardSimple'
import MoominQuoteCardSimple from 'views/Customers/Sections/BuyCarbonCredits/MoominQuoteCardSimple'

const QuoteCardsCarousel = () => {
    const isMobile = useIsMobile()
    const quoteCards = [
        <FortoQuoteCard key={'quote-card-forto'} />,
        <VestreQuoteCardSimple key={'quote-card-vestre'} />,
        <GoCardlessQuoteCard key={'quote-card-goCardless'} />,
        <MoominQuoteCardSimple key={'quote-card-moomin'} />,
        <SalesforceQuoteCard key={'quote-card-salesforce'} />,
    ]
    return (
        <TwoColumnLayout leftCol={6} rightCol={5} columnWrapOrderOnMobile={ColumnWrapOrder.NORMAL}>
            <Typography
                color={'Grey900'}
                variant={'body1'}
                sx={{
                    color: `Grey900`,
                    fontSize: isMobile ? '36px' : '64px',
                    lineHeight: isMobile ? '43.2px' : '76.8px',
                }}
            >
                Funding <strong>quality over quantity</strong> in the fight against climate change.
            </Typography>
            <Box display="flex" justifyContent="center">
                <RenderOnMobile>
                    <VerticalCarouselMobile cards={quoteCards} />
                </RenderOnMobile>
                <RenderOnDesktop>
                    <VerticalCarousel cards={quoteCards} />
                </RenderOnDesktop>
            </Box>
        </TwoColumnLayout>
    )
}

export default QuoteCardsCarousel
