import TwoColumnLayout, { ColumnWrapOrder } from 'components/TwoColumnLayout'
import SectionBody1 from 'components/SectionBody1'
import Box from '@mui/material/Box'
import Line from 'components/Line'
import React from 'react'
import SectionLink from 'components/SectionLink'
import H3 from 'components/H3'
import OverflowImage from 'components/OverflowImage'
import { StaticImage } from 'gatsby-plugin-image'
import StaticImageWrapper from '../components/StaticImageWrapper'

const GetStartedNoTimeSection = () => {
    return (
        <TwoColumnLayout columnWrapOrderOnMobile={ColumnWrapOrder.REVERSE}>
            <Box>
                <H3>Get started in no time</H3>
                <SectionBody1>
                    Skip the engineering sprint altogether using a low/no code hosted carbon offset
                    page. It’s so easy, Lune customers have had interns complete the implementation
                    in hours.
                </SectionBody1>

                <Line />

                <SectionLink
                    text={'View example page'}
                    to={
                        'https://pay.lune.co/zK7ExbX0ejgLM5YR3Qp3B2mVnW9rawPl?quantity=100&bundle_id=xWaKJL3okjD46VpJ4yGXnQNZRe1vzP0w&bundle_id=15xRmEXZq0NnJLpPnydPr7akGOjV9g3z&bundle_id=L0M3zv7Qr2OGRqY9WAVdbwKPx5XWao64'
                    }
                    newPage
                />
            </Box>
            <OverflowImage shadow>
                <StaticImageWrapper
                    placeholder={
                        'https://assets.lune.co/lune-co/placeholders/fintech-examplepage-lunepay.svg'
                    }
                >
                    <StaticImage
                        placeholder={'dominantColor'}
                        src={'../images/LunePay.png'}
                        alt={'Lune Pay'}
                        layout="fixed"
                        objectPosition={'100% 0%'}
                        objectFit={'contain'}
                        quality={100}
                        style={{ width: 'unset', aspectRatio: '1983 / 1600', height: '100%' }}
                    />
                </StaticImageWrapper>
            </OverflowImage>
        </TwoColumnLayout>
    )
}

export default GetStartedNoTimeSection
