import TwoColumnLayout, { ColumnWrapOrder } from 'components/TwoColumnLayout'
import SectionBody1 from 'components/SectionBody1'
import Box from '@mui/material/Box'
import Line from 'components/Line'
import React from 'react'
import SectionLink from 'components/SectionLink'
import H3 from 'components/H3'

import OverflowImage from 'components/OverflowImage'
import StaticImageWrapper from '../components/StaticImageWrapper'
import { StaticImage } from 'gatsby-plugin-image'

const EmpowerCustomersSection = () => {
    return (
        <TwoColumnLayout columnWrapOrderOnMobile={ColumnWrapOrder.REVERSE}>
            <Box>
                <H3>Empower your customers to make better decisions for the planet</H3>
                <SectionBody1>
                    Scope 3 emissions are most difficult to understand, but creating a
                    climate-friendly supply chain shouldn’t be painful. Integrate CO2 emissions data
                    into your platform to help your customers assess sustainability and reduce risk
                    across their vendors and suppliers.
                </SectionBody1>

                <Line />

                <SectionLink text={'Explore our product'} to={'/product'} />
            </Box>
            <OverflowImage shadow>
                <StaticImageWrapper
                    placeholder={
                        'https://assets.lune.co/lune-co/placeholders/procurement-customers-product.svg'
                    }
                >
                    <StaticImage
                        placeholder={'dominantColor'}
                        src={'../images/ProcurementDashboard.png'}
                        alt={'Procurement'}
                        layout="fixed"
                        objectPosition={'100% 0%'}
                        objectFit={'contain'}
                        quality={100}
                        style={{ width: 'unset', aspectRatio: '1714 / 1600', height: '100%' }}
                    />
                </StaticImageWrapper>
            </OverflowImage>
        </TwoColumnLayout>
    )
}

export default EmpowerCustomersSection
