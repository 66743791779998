import * as React from 'react'
import { graphql } from 'gatsby'
import { WindowLocation } from '@reach/router'
import Page, { PageBody } from '../components/Page'
import Stack from '@mui/material/Stack'
import XlContainer from 'components/XlContainer'
import Footer from 'views/Footer'
import Box from '@mui/material/Box'
import { SubscribeSection } from 'components/BlogSubscriptionForm'

const stackSpacing = {
    xs: 0,
    sm: 9,
    md: 9,
    lg: 9,
    xl: 9,
}

export const query = graphql`
    query ($slug: String!) {
        prismicLandingPage(data: { slug: { eq: $slug } }) {
            data {
                background_colour_hexdecimal
                button_icon
                button_text
                hubsport_form_id
                slug
                title
                form_submission_text
                request_marketing_consent
                subtitle {
                    text
                }
                image {
                    url
                }
            }
        }
    }
`
const PrismicLandingPage = ({
    location,
    data: {
        prismicLandingPage: {
            data: {
                form_submission_text: formSubmissionText,
                background_colour_hexdecimal: backgroundColour,
                button_icon: buttonIcon,
                button_text: buttonText,
                request_marketing_consent: requestMarketingConsent,
                hubsport_form_id: hubspotFormId,
                title,
                subtitle: { text: subtitle },
                image: { url: imageUrl },
            },
        },
    },
}: {
    location: WindowLocation
    data: {
        prismicLandingPage: {
            data: {
                form_submission_text: string
                background_colour_hexdecimal: string
                button_icon: string
                button_text: string
                request_marketing_consent: boolean
                hubsport_form_id: string
                slug: string
                title: string
                subtitle: {
                    text: string
                }
                image: {
                    url: string
                }
            }
        }
    }
}) => {
    const meta = {
        image: imageUrl,
        description: subtitle,
    }

    return (
        <Page location={location} title={title} meta={meta}>
            <PageBody>
                <Stack>
                    <Box sx={{ width: '100%' }}>
                        <XlContainer
                            sx={{
                                paddingTop: stackSpacing,
                                paddingBottom: { ...stackSpacing, xs: 6 },
                            }}
                        >
                            <SubscribeSection
                                title={title}
                                subtitle={subtitle}
                                backgroundColor={backgroundColour}
                                image={
                                    <img
                                        style={{
                                            objectFit: 'contain',
                                            maxWidth: '100%',
                                            maxHeight: '100%',
                                        }}
                                        src={imageUrl}
                                    />
                                }
                                hubspotFormId={hubspotFormId}
                                buttonText={buttonText}
                                buttonIconName={buttonIcon}
                                confirmationText={formSubmissionText}
                                requestFirstLastName
                                requestMarketingConsent={requestMarketingConsent}
                                implicitMarketingOptIn={!requestMarketingConsent}
                            />
                        </XlContainer>
                        <XlContainer>
                            <Footer />
                        </XlContainer>
                    </Box>
                </Stack>
            </PageBody>
        </Page>
    )
}

export default PrismicLandingPage
