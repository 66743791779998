import React from 'react'
import MuiButton from '@mui/material/Button'
import { styled, useTheme } from '@mui/material/styles'

const Button = (props: { children: React.ReactNode; sx: any }) => {
    const theme = useTheme()

    const StyledButton = styled(MuiButton)({
        width: 'max-content',
        height: '100%',
        backgroundColor: `${(theme.palette as any).Grey900}`,
        borderRadius: `40px`,
        color: `${(theme.palette as any).White} !important`,
        '&:hover': {
            backgroundColor: `${(theme.palette as any).Black} !important`,
        },
    })
    const sx = {
        '&:hover': {
            boxShadow: 4,
        },
        '&:active': {
            boxShadow: 6,
        },
        ...(props.sx ? props.sx : {}),
    }
    return (
        <StyledButton {...props} sx={sx}>
            {props.children}
        </StyledButton>
    )
}

export default Button
