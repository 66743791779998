import { graphql, useStaticQuery } from 'gatsby'

const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

export const truncateStringWithoutCutWords = (str: string | undefined, maxLen: number) => {
    const separator = ` `
    if (str === undefined || str?.length <= maxLen) return str
    const truncated = str.substr(0, str.lastIndexOf(separator, maxLen))
    return truncated.length < str.length ? `${truncated}...` : truncated
}

export const formatDate = (date: Date): string => {
    return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
}

export function findImage(directory: string, name: string): any {
    const data = useStaticQuery(graphql`
        query {
            allFile(filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" } }) {
                edges {
                    node {
                        base
                        dir
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    `)
    for (const file of data.allFile.edges) {
        if (
            file.node.dir.endsWith(`/images/${directory}`) &&
            file.node.base.startsWith(`${name}.`)
        ) {
            return file.node.childImageSharp.gatsbyImageData
        }
    }
    throw new Error(`Could not find image ${name} in directory ${directory}`)
}
