import TwoColumnLayout, { ColumnWrapOrder } from 'components/TwoColumnLayout'
import SectionBody1 from 'components/SectionBody1'
import H3 from 'components/H3'
import React from 'react'
import OverflowImage from 'components/OverflowImage'
import StaticImageWrapper from '../components/StaticImageWrapper'
import { StaticImage } from 'gatsby-plugin-image'

const NotAnExpertSection = () => {
    return (
        <TwoColumnLayout leftCol={5} rightCol={6} columnWrapOrderOnMobile={ColumnWrapOrder.NORMAL}>
            <OverflowImage float="right">
                <StaticImageWrapper
                    placeholder={
                        'https://assets.lune.co/lune-co/placeholders/projects-carbonmarkets.svg'
                    }
                >
                    <StaticImage
                        placeholder={'dominantColor'}
                        src={'../images/Bundles.png'}
                        alt={'Different bundles'}
                        layout="fixed"
                        objectPosition={'100% 0%'}
                        objectFit={'contain'}
                        quality={100}
                        style={{
                            position: 'absolute',
                            right: '0px',
                            width: 'unset',
                            aspectRatio: '1264 / 1600',
                            height: '100%',
                        }}
                    />
                </StaticImageWrapper>
            </OverflowImage>

            <>
                <H3>You do not need to be a carbon markets expert</H3>
                <SectionBody1>
                    Lune Project Bundles allow you to purchase trusted carbon offsets with
                    confidence even if you are not a carbon markets expert.{' '}
                    <strong>
                        We take care of understanding the jargon-filled carbon markets and make it
                        more consumable.
                    </strong>{' '}
                    Project Bundles also mitigate risks associated with choosing individual
                    projects.
                </SectionBody1>
            </>
        </TwoColumnLayout>
    )
}

export default NotAnExpertSection
