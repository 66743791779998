import projects from 'views/ProjectsCarousel/projectsData'
import useIsMobile from 'hooks/useIsMobile'
import { LuneTheme, ProjectResultsTile as ResultTile, UnSDG } from 'lune-ui-lib'
import TwoColumnLayout, { ColumnWrapOrder } from 'components/TwoColumnLayout'
import Stack from '@mui/material/Stack'
import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import ProjectSpecs from 'views/ProjectsCarousel/ProjectSpecs'
import React from 'react'
import { useTheme } from '@mui/material/styles'

const controlButtonStyles = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '24px',
}

const Project = (props: {
    project: (typeof projects)[0]
    handles: {
        handleNext: () => void
        handlePrev: () => void
    }
    controls: {
        prevBtn: React.ReactNode
        nextBtn: React.ReactNode
    }
}) => {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const {
        project,
        controls: { prevBtn, nextBtn },
        handles: { handleNext, handlePrev },
    } = props
    const { spacing } = LuneTheme
    return (
        <TwoColumnLayout
            columnWrapOrderOnMobile={ColumnWrapOrder.REVERSE}
            {...(isMobile ? {} : { spacing: 0 })}
        >
            <Stack
                spacing={{
                    xs: 4,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    xl: 4,
                }}
            >
                <Box>
                    <Typography variant={'h6'} color={'Grey900'}>
                        {project.bundles[0].name}
                    </Typography>
                    <Typography variant={'h4'}>{project.name}</Typography>
                </Box>

                <ProjectSpecs
                    registryName={project.registryName}
                    registryLink={project.registryLink}
                    offsetType={project.bundles[0].offsetType}
                    carbonPermanence={project.bundles[0].carbonPermanence}
                />

                <pre
                    style={{
                        whiteSpace: `pre-wrap`,
                    }}
                >
                    <Typography variant={'body2'}>{project.description}</Typography>
                </pre>
                <Box>
                    {project.resultsWithIcons.map(
                        (result: { icon: string; text: string }, i: number) => (
                            <ResultTile
                                iconSx={{
                                    color: `${(theme.palette as any).Grey900}`,
                                }}
                                iconName={result.icon}
                                text={result.text}
                                key={i}
                            />
                        ),
                    )}
                    <Box
                        mt={4}
                        sx={{
                            display: 'flex',
                            gap: spacing(1),
                            flexWrap: 'wrap',
                        }}
                    >
                        {project.unSdg?.map((num: any, i: number) => {
                            return <UnSDG n={num} sx={{ width: '40px' }} key={i} />
                        })}
                    </Box>
                </Box>
            </Stack>
            <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 0,
                }}
            >
                {isMobile ? project.img.m : project.img.d}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        justifyContent: 'space-between',
                    }}
                >
                    {isMobile && (
                        <Box
                            sx={{
                                ...controlButtonStyles,
                                paddingRight: `88px`,
                                borderRadius: `48px 0 0 48px`,
                            }}
                            onClick={handlePrev}
                        >
                            {prevBtn}
                        </Box>
                    )}
                    {isMobile && (
                        <Box
                            sx={{
                                ...controlButtonStyles,
                                paddingLeft: `88px`,
                                borderRadius: `0 48px 48px 0`,
                            }}
                            onClick={handleNext}
                        >
                            {nextBtn}
                        </Box>
                    )}
                </Box>
            </Box>
        </TwoColumnLayout>
    )
}

export default Project
