import QuoteCard from 'components/QuoteCard'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

const ChainioQuoteCard = () => {
    return (
        <QuoteCard
            text={
                'Emissions data cannot be an afterthought and must be built into all aspects of a shipment’s lifecycle. Environmental impact is driving shipper behaviour from purchasing through routing, and should be front-and-center for every logistics service provider.'
            }
            profileName={'Brian Glick'}
            profileTitle={'CEO at chain.io'}
            profileAvatar={
                <StaticImage
                    src="../../../../images/avatars/chain-io-brian-glick.png"
                    alt={`Brian Glick`}
                />
            }
            backgroundImageStaticQuery={useStaticQuery(graphql`
                query {
                    desktop: file(relativePath: { eq: "customerQuoteCards/chain-io.png" }) {
                        childImageSharp {
                            fluid(quality: 90, maxWidth: 500) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            `)}
        />
    )
}

export default ChainioQuoteCard
