import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const useLogBreakpoints = () => {
    const theme = useTheme()
    const matchedBreakpoints = theme.breakpoints.keys
        .map((k, index, keysArr) => {
            const bp = theme.breakpoints.values[k]
            const isMatched = useMediaQuery(`(min-width:${bp}px)`)
            if (isMatched) {
                return `${k} ${bp}-${theme.breakpoints.values[keysArr[index + 1]] || '∞'}`
            } else return false
        })
        .filter((b) => b)

    if (matchedBreakpoints.length) {
        console.log(
            `Breakpoint: %c${matchedBreakpoints[matchedBreakpoints.length - 1]}`,
            'color: lightblue; font-family:serif; font-size: 20px',
        )
    }
}

export default useLogBreakpoints
