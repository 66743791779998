import { Box, Stack } from '@mui/system'
import * as React from 'react'
import Page, { Meta, PageBody } from './Page'
import SocialShareButtons from 'components/SocialShareSection'
import XlContainer from './XlContainer'
import Footer from 'views/Footer'
import { WindowLocation } from '@reach/router'
import { PopularArticles } from './BlogPopularArticles'
import { SubscribeSection } from './BlogSubscriptionForm'
import { StaticImage } from 'gatsby-plugin-image'
import { RenderOnDesktop, RenderOnMobile } from 'components/DeviceUtils'
import { HUBSPOT_FORM_IDS } from '../constants'
import StaticImageWrapper from './StaticImageWrapper'

const stackSpacing = {
    xs: 5,
    sm: 10,
    md: 10,
    lg: 10,
    xl: 10,
}

export const BlogWrapper = ({
    children,
    location,
    title,
    meta,
}: {
    children: React.ReactChild
    location: WindowLocation
    title: string
    meta: Meta
}) => {
    return (
        <Page location={location} title={title} meta={meta}>
            <PageBody>
                <Stack spacing={stackSpacing} sx={{ overflow: 'hidden' }}>
                    {/* Blog content here */}
                    {children}

                    <SocialShareButtons url={`https://lune.co/${location.pathname}`} />
                    <PopularArticles />
                    <SubscribeSection
                        title="Subscribe to our monthly newsletter – and get a free copy of our Complete Guide to Offsetting"
                        backgroundColor="#f0f8e3"
                        image={
                            <>
                                <RenderOnDesktop>
                                    <StaticImageWrapper placeholder="https://assets.lune.co/lune-co/placeholders/projects-quality-over-quantity.svg">
                                        <StaticImage
                                            placeholder="dominantColor"
                                            alt="Offsetting guide"
                                            src="../images/GuideToOffsettingLarge.png"
                                        />
                                    </StaticImageWrapper>
                                </RenderOnDesktop>
                                <RenderOnMobile>
                                    <StaticImageWrapper placeholder="https://assets.lune.co/lune-co/placeholders/projects-quality-over-quantity.svg">
                                        <StaticImage
                                            placeholder="dominantColor"
                                            alt="Offsetting guide"
                                            src="../images/GuideToOffsettingSmall.png"
                                        />
                                    </StaticImageWrapper>
                                </RenderOnMobile>
                            </>
                        }
                        hubspotFormId={HUBSPOT_FORM_IDS.blog}
                        buttonText="Subscribe"
                        buttonIconName="ArrowCircleRight"
                        confirmationText="Thank you, you’ll get the guide in your inbox shortly."
                        implicitMarketingOptIn
                    />

                    <Box sx={{ width: '100%' }}>
                        <XlContainer>
                            <Footer />
                        </XlContainer>
                    </Box>
                </Stack>
            </PageBody>
        </Page>
    )
}
