import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import * as React from 'react'
import useIsMobile from 'hooks/useIsMobile'

const StyledRichText = ({ addMargins, style, ...props }: any) => {
    const theme = useTheme()
    const isMobile = useIsMobile()

    const embeddedVideoStyle = {
        position: 'relative',
        width: `100%`,
        display: `flex`,
        justifyContent: `center`,
        paddingBottom: `56.25%`,
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(8),
            marginBottom: theme.spacing(8),
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(15),
            marginBottom: theme.spacing(15),
        },
    }

    const embeddedVideoIframeStyle = {
        position: `absolute`,
        top: `0`,
        left: `0`,
        width: `100%`,
        height: `100%`,
    }

    const marginsStyle = {
        [theme.breakpoints.up('sm')]: addMargins
            ? {
                  marginLeft: '30px',
                  marginRight: '30px',
              }
            : {},
        [theme.breakpoints.up('md')]: addMargins
            ? {
                  marginLeft: '120px',
                  marginRight: '120px',
              }
            : {},
        [theme.breakpoints.up('lg')]: addMargins
            ? {
                  marginLeft: '20%',
                  marginRight: '20%',
              }
            : {},
        [theme.breakpoints.up('xl')]: addMargins
            ? {
                  marginLeft: '20%',
                  marginRight: '20%',
              }
            : {},
    }

    const blogBodyDesktop = {
        fontFamily: theme.typography.fontFamily,
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '160%',
        letterSpacing: '0em',
        textAlign: 'left',
    }
    const blogBodyMobile = {
        fontFamily: theme.typography.fontFamily,
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '160%',
        letterSpacing: '0em',
        textAlign: 'left',
    }

    const StyledBox = styled(Box)({
        color: `${(theme.palette as any).Grey900}`,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        fontWeightLight: theme.typography.fontWeightLight,
        fontWeightRegular: theme.typography.fontWeightRegular,
        fontWeightMedium: theme.typography.fontWeightMedium,
        p: isMobile ? blogBodyMobile : blogBodyDesktop,
        li: isMobile ? blogBodyMobile : blogBodyDesktop,
        h1: isMobile ? theme.typography.h2 : theme.typography.h1,
        h2: isMobile ? theme.typography.h3 : theme.typography.h2,
        h3: isMobile ? theme.typography.h4 : theme.typography.h3,
        h4: isMobile ? theme.typography.h5 : theme.typography.h4,
        h5: isMobile ? theme.typography.h6 : theme.typography.h5,
        h6: isMobile ? theme.typography.button : theme.typography.h6,
        a: {
            color: `${(theme.palette as any).Grey900}`,
        },
        pre: {
            whiteSpace: 'pre-wrap',
        },
        '.block-img': {
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
                marginBottom: theme.spacing(8),
            },
            [theme.breakpoints.up('sm')]: {
                marginBottom: theme.spacing(15),
            },
        },
        '.block-img:not(:first-child)': {
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(8),
            },
            [theme.breakpoints.up('sm')]: {
                marginTop: theme.spacing(15),
            },
        },
        '*:not(.block-img):not(p *):not(li *):not(ul *):not(ol *):not(strong):not(strong):not(code):not(em):not(pre):not(pre *):not(div[data-oembed-provider=YouTube]):not(div[data-oembed-provider=YouTube] iframe):not(div[data-oembed-provider=Vimeo]):not(div[data-oembed-provider=Vimeo] iframe)':
            marginsStyle,
        img: {
            maxWidth: '100%',
        },
        'div[data-oembed-provider=YouTube]': embeddedVideoStyle,
        'div[data-oembed-provider=YouTube] iframe': embeddedVideoIframeStyle,
        'div[data-oembed-provider=Vimeo]': embeddedVideoStyle,
        'div[data-oembed-provider=Vimeo] iframe': embeddedVideoIframeStyle,
        ...(style ?? {}),
    })
    return <StyledBox {...props} />
}

export default StyledRichText
