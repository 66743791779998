import useLogBreakpoints from '../hooks/useLogBreakpoints'
import Page from '../components/Page'
import Stack from '@mui/material/Stack'
import StandardLayout from '../components/StandardLayout'
import * as React from 'react'
import { WindowLocation } from '@reach/router'
import StyledRichText from 'components/StyledRichText'
import Box from '@mui/material/Box'
import XlContainer from '../components/XlContainer'
import Footer from './Footer'

const stackSpacing = {
    xs: 10,
    sm: 15,
    md: 15,
    lg: 15,
    xl: 15,
}

const RichTextPage = ({
    title,
    content,
    meta,
    location,
}: {
    title: string
    content: {
        html: string
    }
    meta: {
        description: string
        image: string
    }
    location: WindowLocation
}) => {
    useLogBreakpoints()

    return (
        <Page location={location} title={title} meta={meta}>
            <Stack spacing={stackSpacing} sx={{ overflow: 'hidden' }} mt={15}>
                <StandardLayout>
                    <StyledRichText addMargins dangerouslySetInnerHTML={{ __html: content.html }} />
                </StandardLayout>
                <Box sx={{ width: '100%' }}>
                    <XlContainer>
                        <Footer />
                    </XlContainer>
                </Box>
            </Stack>
        </Page>
    )
}

export default RichTextPage
