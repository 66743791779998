import { Stack, Typography } from '@mui/material'
import Profile from 'components/Profile'
import StandardLayout from 'components/StandardLayout'
import { GatsbyImage } from 'gatsby-plugin-image'
import useIsMobile from 'hooks/useIsMobile'
import * as React from 'react'

export const Quote = ({
    slice,
}: {
    slice: {
        primary: {
            quote: string
            author_name: string
            author_role: string
            author_avatar: any
            quote_date: string
        }
    }
}) => {
    const isMobile = useIsMobile()
    const { quote, author_name, author_role, author_avatar, quote_date } = slice.primary
    const avatar = author_avatar.gatsbyImageData
    return (
        <StandardLayout
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Stack
                direction="column"
                spacing={{
                    xs: 2,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    xl: 4,
                }}
                sx={{
                    my: {
                        xs: 5,
                        sm: 15,
                        md: 15,
                        lg: 15,
                        xl: 15,
                    },
                    paddingLeft: 3,
                    borderLeft: 'solid 1px',
                    maxWidth: '720px',
                }}
            >
                <Typography
                    variant={isMobile ? 'h5' : 'h4'}
                    component="div"
                    sx={{
                        margin: '0 !important',
                    }}
                >
                    {quote}
                </Typography>
                {author_name && (
                    <Profile
                        name={author_name}
                        title={author_role}
                        avatar={
                            avatar ? <GatsbyImage image={avatar} alt={author_name} /> : undefined
                        }
                        date={quote_date}
                    />
                )}
            </Stack>
        </StandardLayout>
    )
}
