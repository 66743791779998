import React from 'react'
import TwoColumnLayout, { ColumnWrapOrder } from 'components/TwoColumnLayout'
import Line from 'components/Line'
import SectionBody1 from 'components/SectionBody1'
import Shadow from 'components/Shadow'
import H3 from 'components/H3'
import SectionLink from 'components/SectionLink'
import StaticImageWrapper from '../components/StaticImageWrapper'
import { StaticImage } from 'gatsby-plugin-image'

const Image = () => (
    <Shadow>
        <StaticImageWrapper
            placeholder={
                'https://assets.lune.co/lune-co/placeholders/fintech-sustainability page-page.svg'
            }
        >
            <StaticImage
                placeholder={'dominantColor'}
                quality={100}
                src={'../images/Certificate.png'}
                alt={'ShareImpact'}
                layout="fullWidth"
            />
        </StaticImageWrapper>
    </Shadow>
)

const Copy = () => (
    <div>
        <H3>Make sharing climate impact a breeze</H3>
        <SectionBody1>
            <b>Help your customers introduce sustainability into their brands</b> by using a
            Lune-generated sustainability page to easily share an impact summary and carbon
            certificates.
        </SectionBody1>

        <Line />

        <SectionLink
            text={'View a Sustainability page'}
            to={'https://sustainability.lune.co/lune-example-impact'}
            newPage
        />
    </div>
)

const ShareImpactBreezeSection = ({ inverted }: { inverted?: boolean }) => {
    return (
        <TwoColumnLayout
            leftCol={inverted ? 6 : 5}
            rightCol={inverted ? 5 : 6}
            columnWrapOrderOnMobile={inverted ? ColumnWrapOrder.REVERSE : ColumnWrapOrder.NORMAL}
        >
            {inverted ? <Copy /> : <Image />}
            {!inverted ? <Copy /> : <Image />}
        </TwoColumnLayout>
    )
}

export default ShareImpactBreezeSection
