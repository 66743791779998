import * as React from 'react'
import { RenderOnDesktop, RenderOnMobile } from '../components/DeviceUtils'
import { Container } from '@mui/material'
import Box from '@mui/material/Box'
import { Media } from 'lune-ui-lib'

const HeroHeadingShared = ({
    children,
    imageUrl,
    placeholderImageUrl,
}: {
    imageUrl: string
    placeholderImageUrl: string
    children: React.ReactNode
}) => {
    return (
        <>
            <RenderOnDesktop>
                <Container
                    maxWidth="false"
                    sx={{
                        paddingLeft: '0px !important',
                        paddingRight: '0px !important',
                        maxWidth: '1640px',
                    }}
                >
                    <Media
                        sx={{
                            height: '1034px',
                            overflow: 'hidden',
                            '.placeholder-image, .main-media': {
                                height: '100%',
                                maxHeight: 'unset',
                                left: '-50%',
                                right: '-50%',
                                margin: 'auto',
                                maxWidth: 'unset',
                                width: 'auto',
                                position: 'absolute',
                                zIndex: 1,
                            },
                        }}
                        type={'image'}
                        src={imageUrl}
                        placeholderSrc={placeholderImageUrl}
                    />
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            right: '0',
                            margin: 'auto',
                            zIndex: 1,
                        }}
                    >
                        {children}
                    </Box>
                </Container>
            </RenderOnDesktop>
            <RenderOnMobile>{children}</RenderOnMobile>
        </>
    )
}

export default HeroHeadingShared
