import QuoteCard from 'components/QuoteCard'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

const PaidQuoteCard = () => {
    return (
        <QuoteCard
            text={
                'Integrating with Lune’s API has made it incredibly easy for us to bring a sustainable solution into our procurement platform – automating real-world climate impact as we automate procurement processes.'
            }
            profileName={'Graham Morley'}
            profileTitle={'Head of Product at Paid'}
            profileAvatar={
                <StaticImage
                    src="../../../../images/avatars/paid-graham-morley.png"
                    alt={`Graham Morley`}
                />
            }
            backgroundImageStaticQuery={useStaticQuery(graphql`
                query {
                    desktop: file(relativePath: { eq: "customerQuoteCards/paid.png" }) {
                        childImageSharp {
                            fluid(quality: 90, maxWidth: 500) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            `)}
        />
    )
}

export default PaidQuoteCard
