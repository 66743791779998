import React, { useCallback, useState } from 'react'
import Box from '@mui/material/Box'
import StandardLayout from '../components/StandardLayout'
import XlContainer from '../components/XlContainer'
import ContactForm from 'views/ContactForm'
import Footer from 'views/Footer'
import useLogBreakpoints from 'hooks/useLogBreakpoints'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { StaticImage } from 'gatsby-plugin-image'
import useIsMobile from 'hooks/useIsMobile'
import HeroHeadingShared from '../views/HeroHeadingShared'
import EmissionsCalcsSection from 'views/EmissionsCalcsSection'
import EmpowerSection from 'views/EmpowerSection'
import NavBar from '../views/NavBar/NavBar'
import CommunicationSection from 'views/CommunicationSection'
import ShareImpactBreezeSection from 'views/ShareImpactBreezeSection'
import { graphql, useStaticQuery } from 'gatsby'
import Testimonial from '../components/Testimonial'
import GreyGradient from 'components/GreyGradient'
import Page, { PageBodyWithoutNav } from '../components/Page'
import { WindowLocation } from '@reach/router'
import useMediaQuery from '@mui/material/useMediaQuery'
import LogisticsHero from '../images/LogisticsHero.png'

const stackSpacing = {
    xs: 10,
    sm: 15,
    md: 15,
    lg: 15,
    xl: 15,
}

const LogisticsPage = ({ location }: { location: WindowLocation }) => {
    useLogBreakpoints()
    const [navBackgroundColor, setNavBackgroundColor] = useState<string>('transparent')
    const { palette, breakpoints, spacing } = useTheme()
    const isMobile = useIsMobile()
    const isSmall = useMediaQuery(breakpoints.down('md'))

    const onNavBackgroundColorChange = useCallback(
        (color: string) => {
            setNavBackgroundColor(color)
        },
        [navBackgroundColor],
    )

    return (
        <Page location={location} title="Lune - Logistics">
            <PageBodyWithoutNav>
                <Box
                    sx={{
                        position: `sticky`,
                        top: `0px`,
                        zIndex: `9999`,
                        backgroundColor: `${navBackgroundColor}`,
                    }}
                >
                    <XlContainer maxWidth="xl">
                        <Box
                            px={{
                                xs: 1,
                                sm: 2,
                                md: 2,
                                lg: 2,
                                xl: 2,
                            }}
                        >
                            <StandardLayout>
                                <NavBar
                                    backgroundColor={'transparent'}
                                    onBackgroundChange={onNavBackgroundColorChange}
                                />
                            </StandardLayout>
                        </Box>
                    </XlContainer>
                </Box>

                <Box
                    sx={{
                        ...(!isMobile
                            ? {
                                  marginTop: `-${spacing(9)}`, // move up the height of NavBar
                              }
                            : {}),
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: isMobile ? palette.White : palette.Grey100,
                            zIndex: '0',
                            overflow: 'hidden',
                        }}
                        mb={{
                            xs: 10,
                            sm: 15,
                            md: 15,
                            lg: 15,
                            xl: 15,
                        }}
                    >
                        <HeroHeadingShared
                            imageUrl={LogisticsHero}
                            placeholderImageUrl={
                                'https://assets.lune.co/lune-co/placeholders/logistics-header.svg'
                            }
                        >
                            <Box
                                pt={{
                                    xs: 9,
                                    sm: 18,
                                    md: 18,
                                    lg: 18,
                                    xl: 18,
                                }}
                            >
                                <StandardLayout>
                                    <XlContainer maxWidth="xl">
                                        {isSmall ? (
                                            <Typography
                                                variant={`h2`}
                                                component="div"
                                                sx={{ textAlign: `left` }}
                                            >
                                                Grow your business as a sustainable logistics
                                                platform
                                            </Typography>
                                        ) : (
                                            <>
                                                <Typography
                                                    variant={`h2`}
                                                    component="div"
                                                    sx={{ textAlign: `center` }}
                                                >
                                                    Grow your business as a
                                                </Typography>
                                                <Typography
                                                    variant={`h2`}
                                                    component="div"
                                                    sx={{ textAlign: `center` }}
                                                >
                                                    sustainable logistics platform
                                                </Typography>
                                            </>
                                        )}
                                    </XlContainer>
                                </StandardLayout>
                            </Box>
                        </HeroHeadingShared>
                    </Box>

                    <Stack
                        spacing={stackSpacing}
                        sx={{
                            overflow: 'hidden',
                        }}
                    >
                        <Box sx={{ width: '100%' }}>
                            <XlContainer>
                                <Stack spacing={stackSpacing}>
                                    <GreyGradient hide={!isMobile}>
                                        <StandardLayout>
                                            <EmissionsCalcsSection />
                                        </StandardLayout>
                                    </GreyGradient>

                                    <GreyGradient hide={!isMobile}>
                                        <StandardLayout>
                                            <EmpowerSection />
                                        </StandardLayout>
                                    </GreyGradient>

                                    <GreyGradient hide={!isMobile}>
                                        <StandardLayout>
                                            <CommunicationSection />
                                        </StandardLayout>
                                    </GreyGradient>

                                    <GreyGradient hide={!isMobile}>
                                        <StandardLayout>
                                            <ShareImpactBreezeSection />
                                        </StandardLayout>
                                    </GreyGradient>

                                    <StandardLayout>
                                        <Testimonial
                                            text={`We partnered with Lune because of the level of granularity in their emissions calculations and the flexibility their API provides. We assessed several providers and Lune is truly challenging the status quo and revolutionising the carbon markets.`}
                                            profileName={'Nina Göntgen-Voss'}
                                            profileTitle={'Director of Sustainability at Forto'}
                                            profileAvatar={
                                                <StaticImage
                                                    src="../images/Nina.png"
                                                    alt={`Nina Göntgen-Voss`}
                                                />
                                            }
                                            backgroundImageStaticQuery={useStaticQuery(graphql`
                                                query {
                                                    desktop: file(
                                                        relativePath: {
                                                            eq: "TestimonialLogistics.png"
                                                        }
                                                    ) {
                                                        childImageSharp {
                                                            fluid(quality: 100, maxWidth: 1920) {
                                                                ...GatsbyImageSharpFluid_withWebp
                                                            }
                                                        }
                                                    }
                                                }
                                            `)}
                                        />
                                    </StandardLayout>

                                    <StandardLayout>
                                        <ContactForm
                                            hubspotFormName={'logistics'}
                                            headers={['Become a sustainable logistics provider']}
                                        />
                                    </StandardLayout>
                                    <Footer />
                                </Stack>
                            </XlContainer>
                        </Box>
                    </Stack>
                </Box>
            </PageBodyWithoutNav>
        </Page>
    )
}

export default LogisticsPage
