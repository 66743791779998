import IconButton from '@mui/material/IconButton'
import * as React from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const HideRevealToggleButton = ({ isOpen }: { isOpen: boolean }) => {
    return (
        <IconButton
            sx={{
                padding: `0px`,
                height: `5px`,
            }}
            disableRipple
        >
            <KeyboardArrowDownIcon
                color={`Grey900`}
                sx={{
                    color: `Black`,
                    transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform .2s cubic-bezier(0.4, 0, 1, 1)',
                }}
            />
        </IconButton>
    )
}

export default HideRevealToggleButton
