import React from 'react'
import TwoColumnLayout, { ColumnWrapOrder } from 'components/TwoColumnLayout'
import Line from 'components/Line'
import SectionBody1 from 'components/SectionBody1'
import Shadow from 'components/Shadow'
import H3 from 'components/H3'
import SectionLink from 'components/SectionLink'
import StaticImageWrapper from '../components/StaticImageWrapper'
import { StaticImage } from 'gatsby-plugin-image'

const BuyCreditsShareImpactSection = () => {
    return (
        <TwoColumnLayout leftCol={5} rightCol={6} columnWrapOrderOnMobile={ColumnWrapOrder.NORMAL}>
            <Shadow>
                <StaticImageWrapper
                    placeholder={
                        'https://assets.lune.co/lune-co/placeholders/directpurchase-sustainabilitypage-page.svg'
                    }
                >
                    <StaticImage
                        placeholder={'dominantColor'}
                        quality={100}
                        src={'../images/Certificate.png'}
                        alt={'ShareImpact'}
                        layout="fullWidth"
                    />
                </StaticImageWrapper>
            </Shadow>
            <div>
                <H3>Create and protect sustainable brand value</H3>
                <SectionBody1>
                    Openly communicate your impact and share carbon credit certificates using a
                    Lune-generated landing page. Leverage go-to-market guidance to meaningfully
                    engage employees, customers, and stakeholders alike.
                </SectionBody1>

                <Line />

                <SectionLink
                    text={'View a Sustainability page'}
                    to={'https://sustainability.lune.co/lune-example-impact'}
                    newPage
                />
            </div>
        </TwoColumnLayout>
    )
}

export default BuyCreditsShareImpactSection
