import React from 'react'
import TabTwoColumnLayout from '../components/TabTwoColumnLayout'
import { StaticImage } from 'gatsby-plugin-image'
import { Typography, Box } from '@mui/material'
import SectionButton from 'components/SectionButton'
import Shadow from 'components/Shadow'
import H3 from 'components/H3'
import ForYourCustomers from 'components/ForYourCustomers'
import { RenderOnDesktop, RenderOnMobile } from '../components/DeviceUtils'
import OverflowImage from 'components/OverflowImage'
import StaticImageWrapper from '../components/StaticImageWrapper'

const Copy = () => {
    return (
        <>
            <H3 component="span">The business case is clear: climate companies win.</H3>
            <>
                <RenderOnMobile>
                    <Typography color={'Grey900'} variant={'sectionMobile'} component="span">
                        {' '}
                        Stand out from the crowd by embedding climate impact into your product.
                    </Typography>
                </RenderOnMobile>
                <RenderOnDesktop>
                    <Typography color={'Grey900'} variant={'section'} component="span">
                        {' '}
                        Stand out from the crowd by embedding climate impact into your product.
                    </Typography>
                </RenderOnDesktop>
            </>
        </>
    )
}

const HomeUseCasesSection = () => {
    return (
        <TabTwoColumnLayout
            labels={['Logistics', 'Payments', 'Banking & Fintech', 'Procurement']}
            chips={[
                <ForYourCustomers key={0} />,
                <ForYourCustomers key={1} />,
                <ForYourCustomers key={2} />,
                <ForYourCustomers key={3} />,
            ]}
            left={[
                <Box key={0}>
                    <Copy />

                    <SectionButton text={'Embed into Logistics'} to={'/logistics'} />
                </Box>,
                <Box key={1}>
                    <Copy />

                    <SectionButton text={'Embed into Payments'} to={'/payments'} />
                </Box>,
                <Box key={2}>
                    <Copy />

                    <SectionButton
                        text={'Embed into Banking & Fintech'}
                        to={'/banking-and-fintech'}
                    />
                </Box>,
                <Box key={3}>
                    <Copy />

                    <SectionButton text={'Embed into Procurement'} to={'/procurement'} />
                </Box>,
            ]}
            right={[
                <OverflowImage shadow key={0}>
                    <StaticImageWrapper
                        placeholder={
                            'https://assets.lune.co/lune-co/placeholders/home-customers-logistics.svg'
                        }
                    >
                        <StaticImage
                            placeholder={'dominantColor'}
                            src={'../images/Logistics.png'}
                            alt={'Logistics'}
                            layout="fixed"
                            objectPosition={'0% 0%'}
                            objectFit={'contain'}
                            quality={100}
                            style={{
                                width: 'unset',
                                aspectRatio: '1172 / 868',
                                height: '100%',
                            }}
                        />
                    </StaticImageWrapper>
                </OverflowImage>,
                <Shadow key={1}>
                    <StaticImageWrapper
                        placeholder={
                            'https://assets.lune.co/lune-co/placeholders/home-customers-payment.svg'
                        }
                    >
                        <StaticImage
                            placeholder={'dominantColor'}
                            src={'../images/PaymentsEmissions.png'}
                            alt={'Standout'}
                            quality={100}
                            layout="constrained"
                            height={868}
                        />
                    </StaticImageWrapper>
                </Shadow>,
                <Shadow key={2}>
                    <StaticImageWrapper
                        placeholder={
                            'https://assets.lune.co/lune-co/placeholders/home-customers-banking.svg'
                        }
                    >
                        <StaticImage
                            placeholder={'dominantColor'}
                            src={'../images/YourBank.png'}
                            quality={100}
                            alt={'YourBank'}
                            layout="constrained"
                            height={868}
                        />
                    </StaticImageWrapper>
                </Shadow>,
                <OverflowImage shadow key={3}>
                    <StaticImageWrapper
                        placeholder={
                            'https://assets.lune.co/lune-co/placeholders/home-customers-procurement.svg'
                        }
                    >
                        <StaticImage
                            placeholder={'dominantColor'}
                            src={'../images/Procurement.png'}
                            alt={'Procurement'}
                            layout="fixed"
                            objectPosition={'0% 0%'}
                            objectFit={'contain'}
                            quality={100}
                            style={{ width: 'unset', aspectRatio: '930 / 868', height: '100%' }}
                        />
                    </StaticImageWrapper>
                </OverflowImage>,
            ]}
        />
    )
}

export default HomeUseCasesSection
