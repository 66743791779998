import QuoteCard from 'components/QuoteCard'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

const FortoQuoteCard = () => {
    return (
        <QuoteCard
            text={
                'We partnered with Lune because of the level of granularity in their emissions calculations and the flexibility their API provides. We assessed several providers and Lune is truly challenging the status quo and revolutionising the carbon markets.'
            }
            profileName={'Nina Göntgen-Voss'}
            profileTitle={'Director of Sustainability at Forto'}
            profileAvatar={
                <StaticImage
                    src="../../../../images/avatars/forto-nina-gontgen-voss.png"
                    alt={`Nina Göntgen-Voss`}
                />
            }
            backgroundImageStaticQuery={useStaticQuery(graphql`
                query {
                    desktop: file(relativePath: { eq: "customerQuoteCards/forto1.png" }) {
                        childImageSharp {
                            fluid(quality: 90, maxWidth: 500) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            `)}
        />
    )
}

export default FortoQuoteCard
