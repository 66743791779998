import React from 'react'
import { Chip } from 'lune-ui-lib'
import { SxProps } from '@mui/material'
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined'

const ForGTM = ({ sx }: { sx?: SxProps }) => {
    return (
        <Chip
            sx={{ ...sx, maxWidth: 'max-content' }}
            label={'Go-to-market guidance'}
            icon={<RocketLaunchOutlinedIcon />}
        />
    )
}

export default ForGTM
