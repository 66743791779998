import TwoColumnLayout, { ColumnWrapOrder } from 'components/TwoColumnLayout'
import SectionBody1 from 'components/SectionBody1'
import Line from 'components/Line'
import SectionLink from 'components/SectionLink'
import H3 from 'components/H3'
import React from 'react'
import OverflowImage from 'components/OverflowImage'
import { StaticImage } from 'gatsby-plugin-image'
import StaticImageWrapper from '../components/StaticImageWrapper'

const FintechExponentialImpactSection = () => {
    return (
        <TwoColumnLayout leftCol={5} rightCol={6} columnWrapOrderOnMobile={ColumnWrapOrder.NORMAL}>
            <OverflowImage float="right">
                <StaticImageWrapper
                    placeholder={
                        'https://assets.lune.co/lune-co/placeholders/fintech-business-pay.svg'
                    }
                >
                    <StaticImage
                        placeholder={'dominantColor'}
                        src={'../images/ChipsFintech.png'}
                        alt={'Best projects'}
                        layout="fixed"
                        objectPosition={'100% 0%'}
                        objectFit={'contain'}
                        quality={100}
                        style={{
                            position: 'absolute',
                            right: '0px',
                            width: 'unset',
                            aspectRatio: '2028 / 1600',
                            height: '100%',
                        }}
                    />
                </StaticImageWrapper>
            </OverflowImage>

            <>
                <H3>Create exponential impact beyond your own business</H3>

                <SectionBody1>
                    Turn your platform into a force for good by enabling thousands or millions of
                    customers to easily remove CO2 from the atmosphere and protect nature globally.
                </SectionBody1>

                <Line />

                <SectionLink
                    text={'Request access'}
                    to={'https://dashboard.lune.co/signup'}
                    onClick={() => gtag('event', 'request_access', {})}
                    newPage
                />
            </>
        </TwoColumnLayout>
    )
}

export default FintechExponentialImpactSection
