import React, { useMemo, useState } from 'react'
import BackgroundImage from 'gatsby-background-image'
import styled from '@emotion/styled'

export interface IGatsbyFluidImage {
    desktop: {
        childImageSharp: {
            fluid: {
                aspectRatio: number
                base64: string
                src: string
                srcSet: string
                srcSetWebp: string
                srcWebp: string
            }
        }
    }
}

interface BackgroundImageProps {
    placeholder: string
    backgroundImageStaticQuery: IGatsbyFluidImage
    children?: React.ReactNode
    style?: React.CSSProperties
}

/** An extension of Gatsby's native BackgroundImage component that uses a custom
 *  placeholder instead
 */
const BackgroundImageWithPlaceholder = (props: BackgroundImageProps): JSX.Element => {
    const [wasCached, setWasCached] = useState<boolean>(false)

    const StyledBackgroundImage = useMemo(
        () =>
            styled(BackgroundImage)({
                '&:before': {
                    ...(!wasCached
                        ? { backgroundImage: `url(${props.placeholder}) !important` }
                        : {}),
                },
            }),
        [props.placeholder, wasCached],
    )

    return (
        <StyledBackgroundImage
            onStartLoad={(e) => setWasCached(e.wasCached)}
            fluid={props.backgroundImageStaticQuery.desktop.childImageSharp.fluid}
            style={props.style}
        >
            {props.children}
        </StyledBackgroundImage>
    )
}

export default BackgroundImageWithPlaceholder
