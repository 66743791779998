import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import LogixboardQuoteTile from './QuoteTiles/LogixboardQuoteTile'
import ChainioQuoteTile from './QuoteTiles/ChainioQuoteTile'
import SevenBridgesQuoteTile from './QuoteTiles/SevenBridgesQuoteTile'
import StandardLayout from 'components/StandardLayout'
import { LogoChip } from 'views/Customers/Components/LogoChip'

const bigText = {
    fontSize: '112px',
    fontWeight: '300',
    lineHeight: '134px',
    color: 'Grey200',
}

const LogisticsAnimationMobile = () => {
    return (
        <>
            <img
                src={'https://assets.lune.co/lune-co/customers-page-photos/other/grid-mobile.png'}
                width="500px"
                style={{
                    position: 'absolute',
                }}
            />
            <Box sx={{ position: 'absolute', left: -16, top: 130 }}>
                <LogoChip
                    color={'#D3F1FF'}
                    logoSrc={'https://assets.lune.co/lune-co/client-logos/shiptheory.png'}
                />
            </Box>
            <Box sx={{ position: 'absolute', right: 80, top: 500 }}>
                <LogoChip
                    color={'#FFE4D1'}
                    logoSrc={'https://assets.lune.co/lune-co/client-logos/beacon-colour.png'}
                />
            </Box>
            <Box sx={{ position: 'absolute', left: 36, top: 860 }}>
                <LogoChip
                    color={'#D4E2FF'}
                    logoSrc={'https://assets.lune.co/lune-co/client-logos/gocomet-colour.png'}
                />
            </Box>
            <Stack spacing={1} sx={{ maxWidth: '500px' }}>
                <Typography sx={bigText}>Logistics</Typography>
                <StandardLayout>
                    <LogixboardQuoteTile />
                </StandardLayout>
                <Box
                    sx={{
                        width: '900px',
                        marginLeft: '-248px !important',
                    }}
                >
                    <Typography sx={bigText} noWrap>
                        Freight forwarding
                    </Typography>
                </Box>
                <StandardLayout>
                    <ChainioQuoteTile />
                </StandardLayout>
                <Box sx={{ marginLeft: '-32px !important' }}>
                    <Typography sx={bigText}>Shippers</Typography>
                </Box>
                <StandardLayout>
                    <SevenBridgesQuoteTile />
                </StandardLayout>
            </Stack>
        </>
    )
}

export default LogisticsAnimationMobile
