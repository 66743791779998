import TwoColumnLayout, { ColumnWrapOrder } from 'components/TwoColumnLayout'
import SectionBody1 from 'components/SectionBody1'
import Box from '@mui/material/Box'
import Line from 'components/Line'
import React from 'react'
import SectionLink from 'components/SectionLink'
import H3 from 'components/H3'
import ForDashboard from '../components/ForDashboard'
import OverflowImage from 'components/OverflowImage'
import StaticImageWrapper from '../components/StaticImageWrapper'
import { StaticImage } from 'gatsby-plugin-image'

const TakeControlOffsettingSection = () => {
    return (
        <TwoColumnLayout columnWrapOrderOnMobile={ColumnWrapOrder.REVERSE}>
            <Box>
                <ForDashboard
                    sx={{
                        mb: {
                            xs: 4,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                    }}
                />
                <H3>Take total control over your carbon credit purchases</H3>
                <SectionBody1>
                    Use Lune’s dashboard for the easiest way to access traditional offsets and
                    innovative carbon removal projects. Neutralise your company’s emissions with
                    just 2-3 clicks.
                </SectionBody1>

                <Line />

                <SectionLink
                    text={'Request access'}
                    to={'https://dashboard.lune.co/signup'}
                    onClick={() => gtag('event', 'request_access', {})}
                    newPage
                />
            </Box>
            <OverflowImage shadow>
                <StaticImageWrapper
                    placeholder={
                        'https://assets.lune.co/lune-co/placeholders/product-dashboard-dashboard.svg'
                    }
                >
                    <StaticImage
                        placeholder={'dominantColor'}
                        src={'../images/BuyOffsetsNew.png'}
                        alt={'Dashboard'}
                        layout="fixed"
                        objectPosition={'100% 0%'}
                        objectFit={'contain'}
                        quality={100}
                        style={{ width: 'unset', aspectRatio: '1880/ 1600', height: '100%' }}
                    />
                </StaticImageWrapper>
            </OverflowImage>
        </TwoColumnLayout>
    )
}

export default TakeControlOffsettingSection
