import React from 'react'
import TwoColumnLayout, { ColumnWrapOrder } from 'components/TwoColumnLayout'
import Line from 'components/Line'
import { StaticImage } from 'gatsby-plugin-image'
import SectionLink from 'components/SectionLink'
import Shadow from 'components/Shadow'
import H3 from 'components/H3'
import SectionBody1 from 'components/SectionBody1'
import StaticImageWrapper from '../components/StaticImageWrapper'

const BuildLoyaltySection = () => {
    return (
        <TwoColumnLayout leftCol={5} rightCol={6} columnWrapOrderOnMobile={ColumnWrapOrder.NORMAL}>
            <Shadow>
                <StaticImageWrapper
                    placeholder={
                        'https://assets.lune.co/lune-co/placeholders/fintech-customerloyalty-projects.svg'
                    }
                >
                    <StaticImage
                        placeholder={'dominantColor'}
                        quality={100}
                        src={'../images/FintechEmissions.png'}
                        alt={'Standout'}
                        layout="fullWidth"
                    />
                </StaticImageWrapper>
            </Shadow>

            <>
                <H3>Build loyalty by enabling customers to take action</H3>
                <SectionBody1>
                    Connect your customers to a highly selective list of quality carbon projects to
                    help your users easily contribute to systematic climate action and get to Net
                    Zero faster.
                </SectionBody1>

                <Line />

                <SectionLink text={'Explore our projects'} to={'/projects'} />
            </>
        </TwoColumnLayout>
    )
}

export default BuildLoyaltySection
