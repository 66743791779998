import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import QuoteCard from 'components/QuoteCard'

const MoominQuoteCard = () => {
    return (
        <QuoteCard
            text={`In parallel to our longer term emissions reduction efforts, we wanted to contribute to a best-in-class carbon project portfolio and support climate action globally.\nLune makes it easy for us to align with the Oxford Offsetting Principles to do so!`}
            profileName={'Roleff Kråkström'}
            profileTitle={'CEO at Moomin'}
            profileAvatar={
                <StaticImage
                    src="../../../../images/avatars/moomin-roleff-krakstrom.png"
                    alt={`Roleff Kråkström`}
                />
            }
            backgroundImageStaticQuery={useStaticQuery(graphql`
                query {
                    desktop: file(relativePath: { eq: "customerQuoteCards/moomin.png" }) {
                        childImageSharp {
                            fluid(quality: 90, maxWidth: 500) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            `)}
        />
    )
}

export default MoominQuoteCard
