import React from 'react'
import QuoteCardSimple from 'components/QuoteCardSimple'

const VestreQuoteCardSimple = () => {
    return (
        <QuoteCardSimple
            text={
                'Maximising impact with a contribution-led approach aligned with the Oxford Offsetting Principles.'
            }
            logo={'https://assets.lune.co/lune-co/client-logos/moomin.png'}
            backgroundColor={'#CBF4D3'}
        />
    )
}

export default VestreQuoteCardSimple
