import React from 'react'
import TextButton from 'components/TextButton'
import ArrowIcon from 'components/ArrowIcon'

const SectionLink = ({
    text,
    to,
    newPage,
    onClick,
}: {
    text: string
    to: string
    newPage?: boolean
    onClick?: () => void
}) => {
    return (
        <TextButton text={text} icon={<ArrowIcon />} to={to} newPage={newPage} onClick={onClick} />
    )
}

export default SectionLink
