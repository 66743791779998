import React, { useState, useEffect } from 'react'
import * as MUIcon from '@mui/icons-material'
import { Box, Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import useIsMobile from 'hooks/useIsMobile'
import { Checkbox, Input, Button } from 'lune-ui-lib'
import StandardLayout from './StandardLayout'
import { useDebounce } from 'use-debounce'
import { useNewsletter } from 'hooks/useNewsletter'
import { HUBSPOT_FORM_IDS } from '../constants'
import Bugsnag from '@bugsnag/js'

const spacing = {
    xs: 3,
    sm: 3,
    md: 6,
    lg: 8,
    xl: 10,
}

type SubscribeSectionProps = {
    title: string
    subtitle?: string
    image: React.ReactNode
    hubspotFormId: string
    backgroundColor: string
    buttonIconName?: string
    buttonText: string
    confirmationText: string
    requestFirstLastName?: boolean
    requestMarketingConsent?: boolean
    implicitMarketingOptIn?: boolean
}

export const SubscribeSection = ({
    title,
    subtitle,
    buttonText,
    buttonIconName,
    backgroundColor,
    confirmationText,
    hubspotFormId,
    image,
    requestFirstLastName,
    requestMarketingConsent,
    implicitMarketingOptIn,
}: SubscribeSectionProps) => {
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { isValidEmail, toHubspot } = useNewsletter(hubspotFormId, [
        title,
        ...(subtitle ? [subtitle] : []),
        ...(requestMarketingConsent
            ? ['I am happy to receive marketing communications from Lune']
            : []),
    ])

    const [name, setName] = useState<string>('')
    const [firstname, setFirstName] = useState<string>('')
    const [lastname, setLastName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [company, setCompany] = useState<string>('')
    const [marketingConsent, setMarketingConsent] = useState<boolean>(false)
    const [nameError, setNameError] = useState<boolean>(false)
    const [firstnameError, setFirstNameError] = useState<boolean>(false)
    const [emailError, setEmailError] = useState<boolean>(false)
    const [companyError, setCompanyError] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>()
    const [errorText, setErrorText] = useState<boolean>()
    const [debouncedEmail] = useDebounce(email, 1000)

    const Icon = buttonIconName ? MUIcon[buttonIconName] : null

    useEffect(() => {
        if (isValidEmail(email) || email === '') {
            setEmailError(false)
        } else {
            setEmailError(true)
        }
    }, [debouncedEmail])

    useEffect(() => {
        setNameError(false)
        setFirstNameError(false)
    }, [name])

    const onSubmit = async () => {
        const validName = name.length
        const validFirstName = firstname.length
        const validEmail = isValidEmail(email)
        const validCompany = company.length

        if (requestFirstLastName) {
            if (!validFirstName || !validEmail || !validCompany) {
                !validFirstName && setFirstNameError(true)
                !validEmail && setEmailError(true)
                !validCompany && setCompanyError(true)
                return
            }
        } else {
            if (!validName || !validEmail || !validCompany) {
                !validName && setNameError(true)
                !validEmail && setEmailError(true)
                !validCompany && setCompanyError(true)
                return
            }
        }

        const data = await toHubspot({
            ...(requestFirstLastName ? { firstname, lastname } : { name }),
            company,
            email,
            legalConsent: implicitMarketingOptIn || marketingConsent ? 'marketing' : 'gatedContent',
        })
        if (data.status === 'error') {
            setSuccess(false)
            setErrorText('❌ Sorry, an error occurred 😿')
            Bugsnag.notify(new Error(`Blog Subscription error: ${hubspotFormId}`))
        } else {
            setSuccess(true)
            setName('')
            setFirstName('')
            setLastName('')
            setEmail('')
            setCompany('')

            switch (hubspotFormId) {
                case HUBSPOT_FORM_IDS.blog:
                    gtag('event', 'newsletter_signup', {})
                    break
                case HUBSPOT_FORM_IDS.projects:
                    gtag('event', 'download_guide', {})
                    break
            }
        }
    }

    return (
        <StandardLayout>
            <Stack
                direction={isMobile ? 'column' : 'row'}
                display="flex"
                sx={{
                    borderRadius: '32px',
                    backgroundColor: backgroundColor,
                    overflow: 'hidden',
                    ...(success && { display: 'flex', alignItems: 'center' }),
                }}
            >
                <Stack
                    spacing={spacing}
                    sx={{
                        flex: 1,
                        padding: spacing,
                        height: '100%',
                        ...(isMobile && { paddingTop: '40px !important' }),
                    }}
                >
                    {success || errorText ? (
                        <>
                            <Typography
                                variant={useMediaQuery(theme.breakpoints.down('lg')) ? 'h5' : 'h4'}
                            >
                                {success === true ? confirmationText : errorText}
                            </Typography>
                            <Typography variant="body1">
                                In the mean time,{' '}
                                <Link
                                    variant="body1"
                                    color={'Grey900'}
                                    href="https://dashboard.lune.co/signup"
                                    onClick={() => gtag('event', 'request_access', {})}
                                    sx={{
                                        display: 'inline-block',
                                    }}
                                >
                                    request access to Lune
                                </Link>{' '}
                                to make your first carbon purchase and explore the product further.
                            </Typography>
                        </>
                    ) : (
                        <Stack spacing={4}>
                            <Typography
                                variant={useMediaQuery(theme.breakpoints.down('lg')) ? 'h5' : 'h4'}
                            >
                                {title}
                            </Typography>
                            {subtitle && <Typography variant="body3">{subtitle}</Typography>}
                            <Stack spacing={1}>
                                {requestFirstLastName ? (
                                    <Stack
                                        spacing={1}
                                        direction={isMobile ? 'column' : 'row'}
                                        sx={{ display: 'flex' }}
                                    >
                                        <Input
                                            sx={{
                                                height: '48px',
                                                flex: 1,
                                                backgroundColor: firstnameError
                                                    ? theme.palette.Red50
                                                    : theme.palette.White,
                                            }}
                                            name="firstname"
                                            placeholder="First name"
                                            value={firstname}
                                            onChange={(e) => setFirstName(e.target.value)}
                                        />
                                        <Input
                                            sx={{
                                                height: '48px',
                                                flex: 1,
                                                backgroundColor: theme.palette.White,
                                            }}
                                            name="lastname"
                                            placeholder="Last name"
                                            value={lastname}
                                            onChange={(e) => setLastName(e.target.value)}
                                        />
                                    </Stack>
                                ) : (
                                    <Input
                                        sx={{
                                            height: '48px',
                                            backgroundColor: nameError
                                                ? theme.palette.Red50
                                                : theme.palette.White,
                                        }}
                                        name="name"
                                        placeholder="Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                )}
                                <Input
                                    sx={{
                                        height: '48px',
                                        backgroundColor: emailError
                                            ? theme.palette.Red50
                                            : theme.palette.White,
                                    }}
                                    name="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />

                                <Stack
                                    spacing={1}
                                    direction={isMobile ? 'column' : 'row'}
                                    sx={{ display: 'flex' }}
                                >
                                    <Input
                                        sx={{
                                            flex: 1,
                                            height: '48px',
                                            backgroundColor: companyError
                                                ? theme.palette.Red50
                                                : theme.palette.White,
                                        }}
                                        name="company"
                                        placeholder="Company"
                                        value={company}
                                        onChange={(e) => setCompany(e.target.value)}
                                    />
                                </Stack>
                                {implicitMarketingOptIn !== true && requestMarketingConsent && (
                                    <Box pt={4}>
                                        <Checkbox
                                            name="marketingConsent"
                                            sx={{ marginRight: '12px' }}
                                            checked={marketingConsent}
                                            onChange={(e) => setMarketingConsent(e.target.checked)}
                                        />

                                        <Typography variant="body3">
                                            I am happy to receive marketing communications from Lune
                                        </Typography>
                                    </Box>
                                )}
                            </Stack>
                            <Button
                                sx={{
                                    width: '100%',
                                    textTransform: 'none',
                                }}
                                type="submit"
                                onClick={onSubmit}
                                leftIcon={
                                    <Icon
                                        sx={{
                                            width: '20px',
                                            height: '20px',
                                            backgroundColor: `Grey900`,
                                            color: `White`,
                                        }}
                                    />
                                }
                            >
                                {buttonText}
                            </Button>
                        </Stack>
                    )}
                </Stack>
                <Box
                    sx={{
                        flex: 1,
                        marginTop: spacing,
                        marginRight: spacing,
                        ...(isMobile && { marginLeft: '24px' }),
                    }}
                >
                    {image}
                </Box>
            </Stack>
        </StandardLayout>
    )
}
