import useIsMobile from 'hooks/useIsMobile'
import TwoColumnLayout, { ColumnWrapOrder } from 'components/TwoColumnLayout'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import SectionButton from 'components/SectionButton'
import H3 from 'components/H3'
import React from 'react'
import ForYourBusiness from 'components/ForYourBusiness'
import 'mapbox-gl/dist/mapbox-gl.css'
import MapOverflow from 'components/MapOverflow'

const FundCarbonProjects = () => {
    const isMobile = useIsMobile()
    return (
        <TwoColumnLayout leftCol={5} rightCol={6} columnWrapOrderOnMobile={ColumnWrapOrder.NORMAL}>
            <MapOverflow />
            <>
                <Box
                    mb={{
                        xs: 4,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    }}
                >
                    <ForYourBusiness />
                </Box>

                <H3 component="span">Fund carbon projects the world needed yesterday</H3>

                <Typography
                    color={'Grey900'}
                    variant={isMobile ? 'sectionMobile' : 'section'}
                    component="span"
                >
                    {' '}
                    to generate real climate impact at scale.
                </Typography>

                <SectionButton text={'Buy carbon credits'} to={'/buy-carbon-credits'} />
            </>
        </TwoColumnLayout>
    )
}

export default FundCarbonProjects
