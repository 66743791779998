import React from 'react'
import { Chip } from 'lune-ui-lib'
import { SxProps } from '@mui/material'
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined'

const ForAPI = ({ sx }: { sx?: SxProps }) => {
    return (
        <Chip
            sx={{ ...sx, maxWidth: 'max-content' }}
            label={'Dashboard'}
            icon={<DevicesOutlinedIcon />}
        />
    )
}

export default ForAPI
