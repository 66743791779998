import React from 'react'
import TwoColumnLayout, { ColumnWrapOrder } from 'components/TwoColumnLayout'
import { StaticImage } from 'gatsby-plugin-image'
import H3 from 'components/H3'
import SectionBody1 from 'components/SectionBody1'
import useIsMobile from '../hooks/useIsMobile'
import Line from 'components/Line'
import SectionLink from 'components/SectionLink'
import Box from '@mui/material/Box'
import OverflowImage from 'components/OverflowImage'
import StaticImageWrapper from '../components/StaticImageWrapper'

const EmissionsCalcsSection = () => {
    const isMobile = useIsMobile()
    return (
        <TwoColumnLayout columnWrapOrderOnMobile={ColumnWrapOrder.REVERSE}>
            <>
                <H3>Stand out by helping shippers make greener decisions</H3>
                <SectionBody1>
                    Get ahead of mandatory industry regulation and bring emissions transparency into
                    your customer experience – with full control over calculation granularity.
                    Methodology audited & accredited by Smart Freight Centre, following the GLEC
                    Framework, and compliant with ISO 14083.
                </SectionBody1>

                <Line />

                <SectionLink
                    text={'Request access'}
                    to={'https://dashboard.lune.co/signup'}
                    onClick={() => gtag('event', 'request_access', {})}
                    newPage
                />

                <Box
                    mt={{
                        xs: 3,
                        sm: 4,
                        md: 4,
                        lg: 4,
                        xl: 4,
                    }}
                >
                    <StaticImage
                        src={'../images/GLEC.png'}
                        alt={'Smart Freight Centre GLEC accreditation'}
                        layout="fixed"
                        height={61}
                        objectPosition={'0% 0%'}
                        objectFit={'none'}
                        imgStyle={{
                            mixBlendMode: isMobile ? 'multiply' : 'normal',
                        }}
                    />
                </Box>
            </>
            <OverflowImage shadow>
                <StaticImageWrapper
                    placeholder={
                        'https://assets.lune.co/lune-co/placeholders/logistics-dashboard-logistics.svg'
                    }
                >
                    <StaticImage
                        placeholder={'dominantColor'}
                        src={'../images/LogisticsDashboard.png'}
                        alt={'Logistics Dashboard'}
                        layout="fixed"
                        objectPosition={'100% 0%'}
                        objectFit={'contain'}
                        quality={100}
                        style={{ width: 'unset', aspectRatio: '2160 / 1600', height: '100%' }}
                    />
                </StaticImageWrapper>
            </OverflowImage>
        </TwoColumnLayout>
    )
}

export default EmissionsCalcsSection
