import { Stack, Typography, useTheme } from '@mui/material'
import StandardLayout from 'components/StandardLayout'
import useIsMobile from 'hooks/useIsMobile'
import { useNewsletter } from 'hooks/useNewsletter'
import { Button, Input } from 'lune-ui-lib'
import React, { useState, useEffect } from 'react'
import { useDebounce } from 'use-debounce'
import { HUBSPOT_FORM_IDS } from '../constants'
import Bugsnag from '@bugsnag/js'

export const SubscribeToNewsletter = () => {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const hubspotFormId = HUBSPOT_FORM_IDS.blog
    const { isValidEmail, toHubspot } = useNewsletter(hubspotFormId)

    const [name, setName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [nameError, setNameError] = useState<boolean>(false)
    const [emailError, setEmailError] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const [debouncedEmail] = useDebounce(email, 1000)

    useEffect(() => {
        if (isValidEmail(email) || email === '') {
            setEmailError(false)
        } else {
            setEmailError(true)
        }
    }, [debouncedEmail])

    useEffect(() => {
        setNameError(false)
    }, [name])

    const onSubmit = async () => {
        const validName = name.length
        const validEmail = isValidEmail(email)
        if (!validName || !validEmail) {
            !validName && setNameError(true)
            !validEmail && setEmailError(true)
            return
        }

        const data = await toHubspot({
            name,
            email,
            legalConsent: 'marketing',
        })
        if (data.status === 'error') {
            setSuccess(false)
            Bugsnag.notify(new Error(`Newsletter subscription error: ${hubspotFormId}`))
        } else {
            gtag('event', 'newsletter_signup', {})
            setSuccess(true)
            setName('')
            setEmail('')
        }
    }

    return (
        <StandardLayout
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Stack
                direction="column"
                alignItems="center"
                spacing={isMobile ? 2 : 4}
                sx={{
                    width: '100%',
                    maxWidth: '720px',
                    py: isMobile ? 4 : 6,
                    my: isMobile ? 5 : 15,
                    borderTop: `solid 1px ${theme.palette.Grey300}`,
                    borderBottom: `solid 1px ${theme.palette.Grey300}`,
                }}
            >
                {success ? (
                    <Typography variant={'h6'}>
                        Thank you for subscribing to our newsletter!
                    </Typography>
                ) : (
                    <>
                        <Typography variant={'h6'}>Subscribe to our monthly newsletter</Typography>
                        <Stack
                            direction={isMobile ? 'column' : 'row'}
                            sx={{ width: '100%' }}
                            gap={1}
                        >
                            <Input
                                sx={{
                                    backgroundColor: nameError
                                        ? theme.palette.Red50
                                        : theme.palette.Grey100,
                                }}
                                name="name"
                                type="text"
                                placeholder="Name"
                                wide
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <Input
                                sx={{
                                    backgroundColor: emailError
                                        ? theme.palette.Red50
                                        : theme.palette.Grey100,
                                }}
                                name="email"
                                type="text"
                                placeholder="Email"
                                wide
                                value={email}
                                onChange={(e) => setEmail(e.target.value?.toLowerCase())}
                            />
                        </Stack>
                        <Button
                            type="submit"
                            variant="outlined"
                            wide
                            sx={{ textTransform: 'capitalize' }}
                            onClick={onSubmit}
                        >
                            Subscribe
                        </Button>
                    </>
                )}
            </Stack>
        </StandardLayout>
    )
}
