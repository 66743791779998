import TwoColumnLayout, { ColumnWrapOrder } from 'components/TwoColumnLayout'
import Box from '@mui/material/Box'
import { StaticImage } from 'gatsby-plugin-image'
import SectionBody1 from 'components/SectionBody1'
import Line from 'components/Line'
import SectionLink from 'components/SectionLink'
import H3 from 'components/H3'
import React from 'react'
import OverflowImage from 'components/OverflowImage'
import StaticImageWrapper from '../components/StaticImageWrapper'

const PickBestProjects = () => {
    return (
        <TwoColumnLayout leftCol={5} rightCol={6} columnWrapOrderOnMobile={ColumnWrapOrder.NORMAL}>
            <Box
                sx={{
                    padding: 0,
                    float: `right`,
                }}
            >
                <OverflowImage float="right">
                    <StaticImageWrapper
                        placeholder={
                            'https://assets.lune.co/lune-co/placeholders/directpurchase-business-projects.svg'
                        }
                    >
                        <StaticImage
                            placeholder={'dominantColor'}
                            src={'../images/Bundles.png'}
                            alt={'Best projects'}
                            layout="fixed"
                            objectPosition={'100% 0%'}
                            objectFit={'contain'}
                            quality={100}
                            style={{
                                position: 'absolute',
                                right: '0px',
                                width: 'unset',
                                aspectRatio: '1264 / 1600',
                                height: '100%',
                            }}
                        />
                    </StaticImageWrapper>
                </OverflowImage>
            </Box>

            <>
                <H3>Pick the best projects for your business</H3>

                <SectionBody1>
                    Every project offered by Lune plays a critical role in removing or reducing
                    carbon emissions — from reforestation and conserving forests, to cutting edge
                    carbon removal technologies such as direct air capture, bio-oil sequestration
                    and ocean carbon removal.
                </SectionBody1>

                <Line />

                <SectionLink text={'Explore our projects'} to={'/projects'} />
            </>
        </TwoColumnLayout>
    )
}

export default PickBestProjects
